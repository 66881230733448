<div
  fxLayout="column"
  [fxLayoutGap]="1 | dsSpacing"
  class="sidebar-content scroll-container-vertical hidden-scollbar full-height"
  [class.mini]="!isExpanded && !media.isActive('xs')"
>
  <a
    class="inherit-color"
    [href]="'https://paldesk.palfinger.com/'"
    [style.padding-left]="0.5 | dsSpacing"
  >
    <h2
      id="logo"
      class="palfinger-font uppercase left-text"
      [class.mini]="!isExpanded && !media.isActive('xs')"
    >
      P<span>aldesk</span>
    </h2>
  </a>
  <div></div>

  <div class="menu-header">
    <div class="no-padding full-width">
      <div class="icon-holder">
        <mat-icon> {{ menu.titleIcon }} </mat-icon>
      </div>
      <div class="text-holder">
        <h4>{{ menu.title | translate }}</h4>
      </div>
    </div>
  </div>

  <div class="menu">
    <div *ngFor="let menuItem of menu.navigation">
      <div *ngIf="!menuItem.children?.length; else subMenu">
        <ng-container
          *ngTemplateOutlet="link; context: { $implicit: menuItem }"
        ></ng-container>
      </div>

      <ng-template #subMenu>
        <div
          class="relative-position"
          [routerLink]="[menuItem.routerLink]"
          [dsDropdown]="menuItem.children"
          [dsDropdownTitle]="menuItem.title"
          [dsDropdownEnabled]="!isExpanded && !media.isActive('xs')"
        >
          <button
            mat-button
            class="no-padding full-width"
            routerLinkActive="active"
            (click)="opened = undefined; dispatchSelect()"
            #rla="routerLinkActive"
          >
            <ng-container
              *ngTemplateOutlet="btn; context: { $implicit: menuItem }"
            ></ng-container>
          </button>
          <button
            class="expand-button"
            mat-icon-button
            *ngIf="
              (menuItem.children?.length && isExpanded) || media.isActive('xs')
            "
            (click)="$event.stopPropagation(); toggleOpened(menuItem)"
          >
            <mat-icon>{{
              router.isActive(menuItem.routerLink || '', false) ||
              isOpened(menuItem)
                ? 'expand_more'
                : 'chevron_right'
            }}</mat-icon>
          </button>
        </div>
        <div
          class="expanding-panel"
          [@slideUpDown]
          *ngIf="
            (rla.isActive || isOpened(menuItem)) &&
            (isExpanded || media.isActive('xs'))
          "
        >
          <div
            class="child"
            *ngFor="let child of menuItem.children"
            [ngClass]="{
              'has-children': child.children?.length,
              active: rla.isActive
            }"
          >
            <ng-container
              *ngTemplateOutlet="childTemplate; context: { $implicit: child }"
            >
            </ng-container>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<ng-template #childTemplate let-child>
  <a
    class="full-width"
    *ngIf="child.externalLink; else routerLnk"
    [href]="child.externalLink"
    [matBadge]="child.badge"
    [matBadgeHidden]="!child.badge"
    matBadgeColor="primary"
    matBadgeSize="small"
    matBadgePosition="after"
    [matBadgeOverlap]="false"
  >
    {{ child.title | translate }}</a
  >
  <ng-template #routerLnk>
    <span fxLayout="row" fxLayoutAlign="space-between center">
      <a
        matRipple
        class="full-width"
        [routerLink]="child.routerLink"
        (click)="dispatchSelect()"
        routerLinkActive="active"
        #rla="routerLinkActive"
        fxLayout="row"
      >
        <span fxFlex="0 0 auto" class="content cut-text">
          {{ child.title | translate }}</span
        >
        <span
          fxFlex="0 1 auto"
          class="badge-placeholder"
          [matBadge]="child.badge"
          [matBadgeHidden]="!child.badge"
          matBadgeColor="primary"
          matBadgeSize="small"
        ></span>
      </a>
      <button
        mat-icon-button
        *ngIf="child.children?.length"
        (click)="toggleOpened(child)"
      >
        <mat-icon>{{
          rla.isActive || isOpened(child) ? 'expand_more' : 'chevron_right'
        }}</mat-icon>
      </button></span
    >

    <div
      *ngIf="rla.isActive || opened === child"
      class="expanding-panel"
      [@slideUpDown]
    >
      <div class="child" *ngFor="let nextChild of child?.children">
        <ng-container
          *ngTemplateOutlet="childTemplate; context: { $implicit: nextChild }"
        >
        </ng-container>
      </div>
    </div>
  </ng-template>
</ng-template>

<!-- Show <a> or <button> depending on if this is routerLink or externalLink-->
<ng-template #link let-item>
  <button
    mat-button
    class="no-padding full-width"
    [routerLink]="[item.routerLink]"
    (click)="dispatchSelect()"
    routerLinkActive="active"
    #rla="routerLinkActive"
    *ngIf="item.routerLink; else external"
  >
    <ng-container
      *ngTemplateOutlet="btn; context: { $implicit: item }"
    ></ng-container>
  </button>
  <ng-template #external>
    <a mat-button [href]="item.externalLink" target="_blank">
      <ng-container
        *ngTemplateOutlet="btn; context: { $implicit: item }"
      ></ng-container>
    </a>
  </ng-template>
</ng-template>

<ng-template #btn let-menuItem>
  <div fxLayout="row">
    <div
      [fxFlex]="2 | dsSpacing"
      class="icon-holder"
      *ngIf="menuItem.icon as icon"
    >
      <mat-icon
        [matTooltip]="menuItem.title | translate"
        [matTooltipDisabled]="isExpanded || menuItem.children?.length"
        [matTooltipPosition]="'right'"
        [matBadge]="menuItem.badge"
        [matBadgeHidden]="!menuItem.badge"
        matBadgeColor="primary"
        matBadgeSize="small"
      >
        {{ icon }}</mat-icon
      >
    </div>
    <div
      class="text-holder"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      [ngClass]="{ 'has-children': menuItem.children?.length }"
    >
      <span>{{ menuItem.title | translate }}</span>
    </div>
  </div>
</ng-template>
