/**
 * v2 - Business Partner Management
 * Business Partner Management API
 *
 * The version of the OpenAPI document: 2.0
 * Contact: a.gell@palfinger.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { BusinessPartnerResponse } from '../model/models';
import { CreateBusinessPartnerRequest } from '../model/models';
import { CreateBusinessPartnerResponse } from '../model/models';
import { CrmAccountResponse } from '../model/models';
import { CustomerAddressResponse } from '../model/models';
import { SearchAccountsResponse } from '../model/models';
import { SearchBusinessPartnersResponse } from '../model/models';
import { UpsertAccountRequest } from '../model/models';
import { UpsertAccountResponse } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class CustomerService {

    protected basePath = 'https://ms-bpm-test.azurewebsites.net';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }



    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Create new Business Partner in SAP
     * @param apiVersion The requested API version
     * @param createBusinessPartnerRequest Business Partner data to be created
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bpmCreateBusinessPartner(apiVersion: '2.0', createBusinessPartnerRequest?: CreateBusinessPartnerRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<CreateBusinessPartnerResponse>;
    public bpmCreateBusinessPartner(apiVersion: '2.0', createBusinessPartnerRequest?: CreateBusinessPartnerRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<CreateBusinessPartnerResponse>>;
    public bpmCreateBusinessPartner(apiVersion: '2.0', createBusinessPartnerRequest?: CreateBusinessPartnerRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<CreateBusinessPartnerResponse>>;
    public bpmCreateBusinessPartner(apiVersion: '2.0', createBusinessPartnerRequest?: CreateBusinessPartnerRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling bpmCreateBusinessPartner.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (apiVersion !== undefined && apiVersion !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>apiVersion, 'api-version');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2SecurityScheme) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<CreateBusinessPartnerResponse>(`${this.configuration.basePath}/Customer`,
            createBusinessPartnerRequest,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get detail of the Business Partner from SAP
     * @param sapNumber Number of the Business partner in SAP
     * @param apiVersion The requested API version
     * @param supplier sapNumber will be treated as Supplier number when value is true. sapNumber will be treated as Customer number otherwise.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bpmGetBusinessPartnerDetail(sapNumber: string, apiVersion: '2.0', supplier?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<BusinessPartnerResponse>;
    public bpmGetBusinessPartnerDetail(sapNumber: string, apiVersion: '2.0', supplier?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<BusinessPartnerResponse>>;
    public bpmGetBusinessPartnerDetail(sapNumber: string, apiVersion: '2.0', supplier?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<BusinessPartnerResponse>>;
    public bpmGetBusinessPartnerDetail(sapNumber: string, apiVersion: '2.0', supplier?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (sapNumber === null || sapNumber === undefined) {
            throw new Error('Required parameter sapNumber was null or undefined when calling bpmGetBusinessPartnerDetail.');
        }
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling bpmGetBusinessPartnerDetail.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (supplier !== undefined && supplier !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>supplier, 'supplier');
        }
        if (apiVersion !== undefined && apiVersion !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>apiVersion, 'api-version');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2SecurityScheme) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<BusinessPartnerResponse>(`${this.configuration.basePath}/Customer/${encodeURIComponent(String(sapNumber))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get detail of the Business Partner from SAP
     * @param businessPartnerNumber Number of the Business partner in SAP
     * @param apiVersion The requested API version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bpmGetBusinessPartnerDetailByBPNumber(businessPartnerNumber: string, apiVersion: '2.0', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<BusinessPartnerResponse>;
    public bpmGetBusinessPartnerDetailByBPNumber(businessPartnerNumber: string, apiVersion: '2.0', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<BusinessPartnerResponse>>;
    public bpmGetBusinessPartnerDetailByBPNumber(businessPartnerNumber: string, apiVersion: '2.0', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<BusinessPartnerResponse>>;
    public bpmGetBusinessPartnerDetailByBPNumber(businessPartnerNumber: string, apiVersion: '2.0', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (businessPartnerNumber === null || businessPartnerNumber === undefined) {
            throw new Error('Required parameter businessPartnerNumber was null or undefined when calling bpmGetBusinessPartnerDetailByBPNumber.');
        }
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling bpmGetBusinessPartnerDetailByBPNumber.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (apiVersion !== undefined && apiVersion !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>apiVersion, 'api-version');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2SecurityScheme) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<BusinessPartnerResponse>(`${this.configuration.basePath}/Customer/BusinessPartnerDetail/${encodeURIComponent(String(businessPartnerNumber))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get detail of an Account from CRM based on CRM Account number
     * @param accountId CRM Account ID to get detail for
     * @param apiVersion The requested API version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bpmGetCrmAccountDetail(accountId: string, apiVersion: '2.0', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<CrmAccountResponse>;
    public bpmGetCrmAccountDetail(accountId: string, apiVersion: '2.0', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<CrmAccountResponse>>;
    public bpmGetCrmAccountDetail(accountId: string, apiVersion: '2.0', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<CrmAccountResponse>>;
    public bpmGetCrmAccountDetail(accountId: string, apiVersion: '2.0', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling bpmGetCrmAccountDetail.');
        }
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling bpmGetCrmAccountDetail.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (apiVersion !== undefined && apiVersion !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>apiVersion, 'api-version');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2SecurityScheme) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<CrmAccountResponse>(`${this.configuration.basePath}/Customer/${encodeURIComponent(String(accountId))}/Crm`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param language 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bpmGetMyCompanyAddress(language?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<CustomerAddressResponse>;
    public bpmGetMyCompanyAddress(language?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<CustomerAddressResponse>>;
    public bpmGetMyCompanyAddress(language?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<CustomerAddressResponse>>;
    public bpmGetMyCompanyAddress(language?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (language !== undefined && language !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>language, 'language');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2SecurityScheme) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<CustomerAddressResponse>(`${this.configuration.basePath}/Customer/MyCompanyAddress`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search Accounts in CRM
     * @param companyName 
     * @param countryId 
     * @param zip 
     * @param city 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bpmSearchAccounts(companyName: string, countryId?: string, zip?: string, city?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<SearchAccountsResponse>;
    public bpmSearchAccounts(companyName: string, countryId?: string, zip?: string, city?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<SearchAccountsResponse>>;
    public bpmSearchAccounts(companyName: string, countryId?: string, zip?: string, city?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<SearchAccountsResponse>>;
    public bpmSearchAccounts(companyName: string, countryId?: string, zip?: string, city?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (companyName === null || companyName === undefined) {
            throw new Error('Required parameter companyName was null or undefined when calling bpmSearchAccounts.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (countryId !== undefined && countryId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>countryId, 'country_id');
        }
        if (zip !== undefined && zip !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>zip, 'zip');
        }
        if (city !== undefined && city !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>city, 'city');
        }
        if (companyName !== undefined && companyName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>companyName, 'company_name');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2SecurityScheme) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<SearchAccountsResponse>(`${this.configuration.basePath}/Customer/Search/Crm`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update existing Account in CRM when Account ID is filled or create new Account otherwise.
     * @param apiVersion The requested API version
     * @param upsertAccountRequest Account data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bpmUpsertCrmAccount(apiVersion: '2.0', upsertAccountRequest?: UpsertAccountRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<UpsertAccountResponse>;
    public bpmUpsertCrmAccount(apiVersion: '2.0', upsertAccountRequest?: UpsertAccountRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<UpsertAccountResponse>>;
    public bpmUpsertCrmAccount(apiVersion: '2.0', upsertAccountRequest?: UpsertAccountRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<UpsertAccountResponse>>;
    public bpmUpsertCrmAccount(apiVersion: '2.0', upsertAccountRequest?: UpsertAccountRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling bpmUpsertCrmAccount.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (apiVersion !== undefined && apiVersion !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>apiVersion, 'api-version');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2SecurityScheme) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<UpsertAccountResponse>(`${this.configuration.basePath}/Customer/Crm`,
            upsertAccountRequest,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
