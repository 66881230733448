import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DsFilterComponent } from './filter.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    MatExpansionModule,
    MatTooltipModule,
    TranslateModule,
    DsSpacingModule,
  ],
  exports: [DsFilterComponent],
  declarations: [DsFilterComponent],
})
export class DsFilterModule {}
