import { Component, forwardRef, Input } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
} from '@angular/forms';
import { PalfingerValidators } from '@utils/palfinger-validators';

@Component({
  selector: 'ui-kit-confirm-password',
  templateUrl: './confirm-password.component.html',
  styleUrls: ['./confirm-password.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ConfirmPasswordComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ConfirmPasswordComponent),
      multi: true,
    },
  ],
})
export class ConfirmPasswordComponent
  implements ControlValueAccessor, Validator
{
  showPassword = false;
  passwordForm: FormGroup;
  passwordErrorMatcher = {
    isErrorState(control: FormControl | null): boolean {
      const invalidCtrl = !!(control && control.invalid && control.touched);
      const invalidParent = !!(
        control &&
        control.parent &&
        control.touched &&
        control.parent.errors &&
        control.parent.errors.notEquivalent
      );

      return invalidCtrl || invalidParent;
    },
  };

  _minLenght = 8;
  @Input()
  get minLength(): number {
    return this._minLenght;
  }
  set minLength(val: number) {
    this._minLenght = val;
  }

  constructor(private fb: FormBuilder) {
    this.passwordForm = this.fb.group(
      {
        password: [
          '',
          {
            validators: [
              this.regexValidator(new RegExp('^(?=.*[0-9])'), {
                digit: 'digit',
              }),
              this.regexValidator(new RegExp('^(?=.*[A-Z])'), {
                uppercase: 'uppercase',
              }),
              this.regexValidator(new RegExp('^(?=.*[a-z])'), {
                lowercase: 'lowercase',
              }),
            ],
            updateOn: 'blur',
          },
        ],
        confirm_password: [],
      },
      {
        validator: PalfingerValidators.matchingEqual(
          'password',
          'confirm_password',
        ),
      },
    );
    this.passwordForm.valueChanges.subscribe((val) => this.onChange());
  }

  propagateChange = (_: any) => {
    return;
  };

  writeValue(obj: any): void {
    this.passwordForm.controls.password.setValue(obj);
    this.passwordForm.controls.confirm_password.setValue(obj);
  }

  registerOnTouched(fn: any): void {
    return;
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  onChange() {
    this.propagateChange(this.passwordForm.controls.password.value);
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.passwordForm.valid
      ? null
      : {
          confirmPassword: true,
        };
  }

  regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }
}
