import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StandardValue } from '../../../../classes';
import { DataCollectionService } from '../../data-collection.service';

@Component({
  selector: 'bi-data-input',
  templateUrl: './data-input.component.html',
  styleUrls: ['./data-input.component.scss'],
})
export class DataInputComponent implements OnInit {
  standardValues: Map<string, StandardValue>;
  workHoursIsYearly = true;

  constructor(
    private router: Router,
    private dataCollectionService: DataCollectionService,
  ) {
    // Prevent access if standardValues are not set
    if (this.dataCollectionService.standardValues.size === 0) {
      this.router.navigate(['/data-collection']);
    }
  }

  ngOnInit() {
    this.standardValues = this.dataCollectionService.standardValues;
    this.dataCollectionService.storeStandardValues(this.standardValues);

    // Prefill working hours per day
    const workingHoursPerYear = this.standardValues.get(
      'working_hours_per_year',
    );
    const workingDaysPerYear = this.standardValues.get('working_days_per_year');
    if (workingHoursPerYear && workingDaysPerYear) {
      const workhingHoursPerDay = new StandardValue(
        'working_hours_per_day',
        'Arbeitsstunden pro Tag',
        workingHoursPerYear.amount / workingDaysPerYear.amount,
        'h/d',
      );
      this.standardValues.set('working_hours_per_day', workhingHoursPerDay);
      this.dataCollectionService.storeStandardValues(this.standardValues);
    }
  }

  public workChange() {
    // Update working hours per year
    const workingHoursPerYear = this.standardValues.get(
      'working_hours_per_year',
    );
    const workingHoursPerDay = this.standardValues.get('working_hours_per_day');
    const workingDaysPerYear = this.standardValues.get('working_days_per_year');
    if (workingHoursPerYear && workingHoursPerDay && workingDaysPerYear) {
      workingHoursPerYear.amount =
        workingHoursPerDay.amount * workingDaysPerYear.amount;
      this.standardValues.set('working_hours_per_year', workingHoursPerYear);
      this.dataCollectionService.storeStandardValues(this.standardValues);
    }
  }

  public getTotal(): number {
    const activityIdle = this.standardValues.get('activity_idle');
    const activitySetup = this.standardValues.get('activity_setup');
    const activityLift = this.standardValues.get('activity_lift');
    if (activityIdle && activitySetup && activityLift) {
      return activityIdle.amount + activitySetup.amount + activityLift.amount;
    } else {
      return 0;
    }
  }

  public standardValueChanged() {
    this.dataCollectionService.storeStandardValues(this.standardValues);
  }
}
