<mat-radio-group
  fxLayout="column"
  [fxLayoutGap]="0.5 | dsSpacing"
  [(ngModel)]="selected"
  [disabled]="!crane"
  (change)="onSelectChange()"
>
  <mat-radio-button
    *ngFor="let technology of crane?.crane_technologies"
    [value]="technology"
  >
    {{ technology.definition }}
  </mat-radio-button>
</mat-radio-group>
