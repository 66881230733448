/* eslint-disable @typescript-eslint/naming-convention */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PARTNERTYPES } from '@config';
import { UserService as BpmUserService } from '@data-access/bpm-generated';
import { DsSnackbar, DsSnackbarType } from '@design-system/feature/snackbar';
import { UserService } from '@features/auth';
import { DpaComponent, DpaData } from '@features/dpa';
import { TranslateService } from '@ngx-translate/core';
import {
  UpdateUserProfile,
  UserService as IdentityServerUserService,
} from '@paldesk/shared-lib/data-access/identity-service-generated';
import { Language, LanguageService } from '@shared-lib/language-selection';
import { filterTruthy } from '@shared-lib/rxjs';
import { finalize, map, mergeMap, take } from 'rxjs/operators';
import { DeleteAccountPromptComponent } from './delete-account-prompt-component/delete-account-prompt.component';

@Component({
  selector: 'ds-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileComponent {
  form: FormGroup;
  loadingBar = false;
  userName: string;
  userInterfaceLanguages: Language[];
  show_non_admin_error = false;
  isCompanyAdmin: boolean;
  localEmailAddress: string;

  constructor(
    private fb: FormBuilder,
    private languageService: LanguageService,
    private identityServerUserService: IdentityServerUserService,
    private translateService: TranslateService,
    private snackbar: DsSnackbar,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private bpmUserService: BpmUserService,
    public userService: UserService,
  ) {
    this.userInterfaceLanguages =
      this.languageService.getPaldeskUserInterfaceLanguages();
    this.userService.currentUser
      .pipe(filterTruthy(), take(1))
      .subscribe((user) => {
        this.form = fb.group({
          first_name: [user?.firstname],
          last_name: [user?.lastname],
          mobile_phone_number: [user?.mobilephonenumber],
          landline_phone_number: [user?.phonenumber],
          language: [user?.lang],
          current_password: [''],
          password: [''],
          email: [user?.email, Validators.email],
        });
        this.userName = user?.username || '';
        this.isCompanyAdmin = Boolean(
          user?.partnertype === PARTNERTYPES.END_CUSTOMER &&
            user?.company_admin,
        );
        this.localEmailAddress = user?.email;
        if (this.localEmailAddress) {
          const emailControl = this.form.controls['email'];
          emailControl.setValidators([Validators.email, Validators.required]);
          emailControl.updateValueAndValidity();
        }
      });
  }

  isEmailChanged = () => {
    if (!this.form.controls['email'].value && !this.localEmailAddress) {
      return false;
    }

    return this.form.controls['email'].value !== this.localEmailAddress;
  };

  public get showCurrentPasswordField(): boolean {
    if (this.form?.controls.password.value || this.isEmailChanged()) {
      this.form.addControl(
        'current_password',
        new FormControl('', Validators.required),
      );
      return true;
    } else {
      this.form.removeControl('current_password');
      return false;
    }
  }
  onSubmit(): void {
    this.loadingBar = true;
    const request = this.createUpdateProfileFromControls();
    this.identityServerUserService
      .updateUserProfile(request)
      .pipe(
        finalize(() => {
          this.loadingBar = false;
          this.cd.detectChanges();
        }),
      )
      .subscribe(
        () => {
          this.userService.tryRefreshSession();
          this.form.controls['password'].patchValue(undefined);
          this.snackbar.queue(
            this.translateService.instant('user_profile_success_relog'),
            {
              type: DsSnackbarType.Success,
            },
          );
          if (this.isEmailChanged()) {
            this.snackbar.queue(
              this.translateService.instant('user_profile_confirmation_email', {
                mail: this.form.controls['email'].value,
              }),
              {
                type: DsSnackbarType.Success,
              },
            );
          }
          this.localEmailAddress = this.form.controls['email'].value;
        },
        (error) => this.handleError(error),
      );
  }

  showDPA() {
    const dpaData: DpaData = {
      accept_needed: false,
      first_name: this.form.controls['first_name'].value,
      last_name: this.form.controls['last_name'].value,
      company_name: this.userService.userContext.company_name,
      responsible_person: this.userService.userContext.dpa_responsible_person,
    };

    if (this.userService.userContext.dpa_date) {
      dpaData.date = new Date(this.userService.userContext.dpa_date);
    }

    this.dialog.open(DpaComponent, {
      data: dpaData,
      width: '1000px',
      autoFocus: false,
    });
  }

  deleteAccountPrompt() {
    const promptRef$ = this.bpmUserService.bpmCanDeleteSelf().pipe(
      map((response) =>
        this.dialog.open(DeleteAccountPromptComponent, {
          data: {
            isEndCustomerAdmin:
              response.some_unadministered_end_customer_users ||
              response.some_unadministered_invited_end_customer_users,
            responsibilities: response.responsibilities,
            comissions: response.commissions,
            adminRoles: response.unmanageable_roles,
          },
        }),
      ),
    );

    promptRef$
      .pipe(
        mergeMap((prompt) => prompt.afterClosed()),
        take(1),
      )
      .subscribe((result) => {
        if (result) {
          this.deleteAccount();
        }
      });
  }

  private deleteAccount() {
    this.bpmUserService.bpmDeleteSelf().subscribe(
      (_) => this.userService.logout(),
      (error) => {
        if (error.error.code === 'user_delete_not_possible') {
          this.show_non_admin_error = true;
          this.cd.detectChanges();
        }
        this.snackbar.queue(
          error.error.code === 'user_delete_not_possible'
            ? this.translateService.instant('user_profile_non_admin_error')
            : error.error.message ||
                this.translateService.instant('general.error_code.error'),
          {
            type: DsSnackbarType.Error,
          },
        );
      },
    );
  }

  private handleError(error: any) {
    let toastMessage = this.translateService.instant(
      'general.error_code.error',
    );
    if (error.error?.code) {
      const translatedMessage = this.translateService.instant(
        'identity_server.' + error.error.code,
      );
      if (error.error?.code !== translatedMessage) {
        toastMessage = translatedMessage;
      }
    }
    this.snackbar.queue(toastMessage, {
      type: DsSnackbarType.Error,
    });
  }

  private createUpdateProfileFromControls(): UpdateUserProfile {
    const userProf = {
      firstName: this.form.controls['first_name'].value,
      lastName: this.form.controls['last_name'].value,
      language: this.form.controls['language'].value,
      landlinePhoneNumber: this.form.controls['landline_phone_number'].value,
      mobilePhoneNumber: this.form.controls['mobile_phone_number'].value,
      emailAddress: this.form.controls['email'].value,
    } as UpdateUserProfile;

    if (this.form.controls['current_password']?.value) {
      userProf.oldPassword = this.form.controls['current_password'].value;
      userProf.newPassword = this.form.controls['password'].value;
    }

    return userProf;
  }
}
