import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IFeature, IFeatureEdit } from '../admin/features/features.component';
import { BEST_INVEST_API_BASE_PATH } from '../core/base-path-token';

@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  constructor(
    private http: HttpClient,
    @Inject(BEST_INVEST_API_BASE_PATH) private basePath: string,
  ) {}

  // index
  public loadAllFeatures(): Promise<IFeature[]> {
    return new Promise((resolve) => {
      const url = '/features.json';
      this.sendGet(url, resolve);
    });
  }

  // show
  public loadFeature(featureId: number): Promise<IFeature> {
    return new Promise((resolve) => {
      // eslint-disable-next-line sonarjs/no-duplicate-string
      const url = '/features/' + featureId;
      this.sendGet(url, resolve);
    });
  }

  // edit
  public editFeature(featureId: number): Promise<IFeatureEdit> {
    return new Promise((resolve) => {
      const url = '/features/' + featureId + '/edit';
      this.sendGet(url, resolve);
    });
  }

  // update
  public updateFeature(form: any, featureId: number): Promise<IFeature> {
    return new Promise((resolve) => {
      const url = '/features/' + featureId;
      this.sendPost(url, resolve, { feature: form });
    });
  }

  // GET
  private sendGet(url: string, resolve: (value?: any) => void) {
    url = this.basePath + url;

    this.http.get(url).subscribe((data: any) => {
      resolve(data);
    });
  }

  // POST
  private sendPost(
    url: string,
    resolve: (value?: any) => void,
    params:
      | HttpParams
      | {
          [param: string]: string | string[];
        }
      | undefined,
  ) {
    url = this.basePath + url;

    this.http.post(url, params).subscribe((data: any) => {
      resolve(data);
    });
  }
}
