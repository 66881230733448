<h4 class="uppercase">
  <small>{{ 'header.search.result_products' | translate }}</small>
</h4>
<mat-action-list dense *ngIf="products">
  <ds-product-category
    *ngFor="
      let category of viewAll
        ? products.product_categories
        : products.product_categories.slice(0, 3)
    "
    [category]="category"
    [expanded]="category.id === expandedCategoryId"
    [paldeskBasePath]="paldeskBasePath"
    (categoryClick)="toggleCategory($event)"
    (productClick)="itemClick.emit($event)"
  ></ds-product-category>
  <ds-view-toggle
    *ngIf="products.product_categories.length > 3"
    (click)="toggleViewAll()"
    [allVisible]="viewAll"
  >
  </ds-view-toggle>
</mat-action-list>
