<div fxLayout="column" [fxLayoutGap]="1 | dsSpacing" class="full-height">
  <div fxFlex="0 0 auto">
    <div
      fxLayout
      fxLayoutAlign="space-between baseline"
      *ngIf="category === 'economics'"
    >
      <h1 translate>FEATURE_SELECTION.SUB_NAV.ECONOMICS</h1>
      <div
        fxLayout
        fxLayoutAlign="start baseline"
        [fxLayoutGap]="0.5 | dsSpacing"
        *ngIf="features?.length"
      >
        <strong>{{ 'FEATURE_SELECTION.SAVINGS.TOTAL' | translate }}:</strong>

        <p translate>FEATURE_SELECTION.SAVINGS.YEARLY</p>
        <div class="sum success mb-5">
          €
          {{
            sumOf('economics', 'annual_savings')
              | number: '1.0-0':this.translate.currentLang
          }}
        </div>
        <p translate>FEATURE_SELECTION.SAVINGS.PALFINGER_USP</p>
        <div class="sum bg-yellow">
          €
          {{
            sumOf('economics', 'annual_savings', true)
              | number: '1.0-0':this.translate.currentLang
          }}
        </div>
      </div>
    </div>
    <div
      fxLayout
      fxLayoutAlign="space-between baseline"
      *ngIf="category === 'benefits'"
    >
      <h1 translate>FEATURE_SELECTION.SUB_NAV.BENEFITS</h1>
      <div
        fxLayout
        fxLayoutAlign="start baseline"
        [fxLayoutGap]="0.5 | dsSpacing"
        *ngIf="features?.length"
      >
        <div fxLayout fxLayoutAlign="start baseline">
          <mat-icon [class.success-text]="isSuccess('has_safety')"
            >verified_user</mat-icon
          >
          <span translate>FEATURE_SELECTION.BENEFITS.SAFETY</span>
        </div>

        <div fxLayout fxLayoutAlign="start baseline">
          <mat-icon [class.success-text]="isSuccess('has_resale_value')"
            >attach_money</mat-icon
          >
          <p translate>FEATURE_SELECTION.BENEFITS.RESALE_VALUE</p>
        </div>

        <div fxLayout fxLayoutAlign="start baseline">
          <mat-icon [class.success-text]="isSuccess('has_area_of_use')"
            >directions_car</mat-icon
          >
          <p translate>FEATURE_SELECTION.BENEFITS.AREA_OF_USE</p>
        </div>

        <div fxLayout fxLayoutAlign="start baseline">
          <mat-icon
            [class.success-text]="isSuccess('has_optimized_performance')"
            >speed</mat-icon
          >
          <p translate>FEATURE_SELECTION.BENEFITS.OPTIMIZED_PERFORMANCE</p>
        </div>
      </div>
    </div>
    <div
      fxLayout
      fxLayoutAlign="space-between center"
      *ngIf="category === 'environment'"
    >
      <h1 translate>FEATURE_SELECTION.SUB_NAV.ENVIRONMENT</h1>
      <div
        fxLayout
        fxLayoutAlign="start center"
        [fxLayoutGap]="1 | dsSpacing"
        *ngIf="features?.length"
      >
        <div fxLayoutAlign="start center" [fxLayoutGap]="0.5 | dsSpacing">
          <mat-icon>local_gas_station</mat-icon>
          <span translate>
            FEATURE_SELECTION.ECONOMICS.DIESEL.SAVINGS<br /><small translate
              >FEATURE_SELECTION.ECONOMICS.DIESEL.UNIT</small
            >
          </span>
          <div class="sum success mb-5">
            {{
              sumOf('environment', 'diesel_savings')
                | number: '1.0-0':this.translate.currentLang
            }}
          </div>
        </div>

        <div fxLayoutAlign="start center" [fxLayoutGap]="0.5 | dsSpacing">
          <mat-icon class="stacked">cloud</mat-icon>
          <span translate>FEATURE_SELECTION.SAVINGS.GHG</span>
          <span translate>
            FEATURE_SELECTION.ECONOMICS.THG.SAVINGS<br /><small translate
              >FEATURE_SELECTION.ECONOMICS.THG.UNIT</small
            >
          </span>

          <div class="sum success">
            {{
              sumOf('environment', 'thg_emission_savings')
                | number: '1.0-0':this.translate.currentLang
            }}
          </div>
        </div>
      </div>
    </div>
    <div
      fxLayout
      fxLayoutAlign="space-between center"
      *ngIf="category === 'health'"
    >
      <h1 translate>FEATURE_SELECTION.SUB_NAV.HEALTH</h1>
      <div
        fxLayout
        fxLayoutAlign="start center"
        [fxLayoutGap]="1 | dsSpacing"
        *ngIf="features?.length"
      >
        <div fxLayoutAlign="start center" [fxLayoutGap]="0.5 | dsSpacing">
          <mat-icon class="stacked">cloud</mat-icon>
          <span translate>FEATURE_SELECTION.SAVINGS.NO</span>
          <span translate>
            FEATURE_SELECTION.ECONOMICS.NITROGEN_OXIDE.SAVINGS<br /><small
              translate
              >FEATURE_SELECTION.ECONOMICS.NITROGEN_OXIDE.UNIT</small
            >
          </span>

          <div class="sum success mb-5">
            {{
              sumOf('health', 'nitrogen_oxide_savings')
                | number: '1.0-0':this.translate.currentLang
            }}
          </div>
        </div>

        <div fxLayoutAlign="start center" [fxLayoutGap]="0.5 | dsSpacing">
          <mat-icon class="stacked">cloud</mat-icon>
          <span translate>FEATURE_SELECTION.SAVINGS.PM</span>
          <span translate>
            FEATURE_SELECTION.ECONOMICS.PARTICULATE_MATTER.SAVINGS<br /><small
              translate
              >FEATURE_SELECTION.ECONOMICS.PARTICULATE_MATTER.UNIT</small
            >
          </span>
          <div class="sum success">
            {{
              sumOf('health', 'particulate_matter_savings')
                | number: '1.0-0':this.translate.currentLang
            }}
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="category === 'summary'"
      ><h1 translate>FEATURE_SELECTION.SUB_NAV.SUMMARY</h1></ng-container
    >

    <div fxLayout="column" [fxLayoutGap]="1 | dsSpacing">
      <ds-filter>
        <mat-form-field class="search text-right">
          <input type="search" matInput placeholder="" [(ngModel)]="searchQ" />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </ds-filter>
    </div>
  </div>
  <ng-container *ngIf="features?.length; else loading">
    <div
      fxFlex="0 1 auto"
      class="no-min-height"
      *ngIf="category !== 'summary'; else summary"
    >
      <ds-table class="full-height full-width">
        <table class="pd-table">
          <thead>
            <tr>
              <th class="mat-header-cell mat-table-sticky"></th>
              <th colspan="3" class="mat-header-cell mat-table-sticky">
                Feature
              </th>
              <th
                *ngFor="let v of categoryValues"
                [ngClass]="
                  category === 'benefits' ? 'text-center' : 'text-right'
                "
                class="mat-header-cell mat-table-sticky"
              >
                <div fxLayout [fxLayoutGap]="0.25 | dsSpacing">
                  <mat-icon *ngIf="iconMap[v]">{{ iconMap[v] }}</mat-icon>
                  <span *ngIf="textMap[v]" translate>{{ textMap[v] }}</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let feature of filterFeatures(); index as i"
              [ngClass]="{ 'text-muted': feature.status === 'not_available' }"
              [hidden]="
                feature.isInsideGroup &&
                !searchQ.length &&
                !groupIsOpen[feature.group]
              "
            >
              <td>
                <!-- toggle sub-features of a group -->
                <button
                  mat-icon-button
                  (click)="
                    groupIsOpen[feature.group] = !groupIsOpen[feature.group]
                  "
                  *ngIf="feature.is_palfinger_overall_concept"
                >
                  <mat-icon
                    [@indicatorRotate]="
                      groupIsOpen[feature.group] ? 'expanded' : 'collapsed'
                    "
                    >expand_more</mat-icon
                  >
                </button>
              </td>
              <td>
                <strong [hidden]="feature.isInsideGroup && !searchQ.length">{{
                  feature.group
                }}</strong>
              </td>

              <ng-container
                *ngIf="
                  !feature.isInsideGroup;
                  then unIndentFeature;
                  else indentFeature
                "
              ></ng-container>
              <ng-template #unIndentFeature>
                <td>
                  <mat-checkbox
                    [(ngModel)]="feature.selected"
                    *ngIf="feature.status === 'add_on'"
                    (change)="changeOthersIfOverall(feature)"
                  ></mat-checkbox>
                  <mat-checkbox
                    [disabled]="true"
                    [checked]="true"
                    *ngIf="feature.status === 'serial'"
                  ></mat-checkbox>
                </td>
                <td>
                  <div
                    fxLayout
                    fxLayoutAlign="start center"
                    [fxLayoutGap]="0.5 | dsSpacing"
                  >
                    <span>{{ feature.name }}</span>
                    <mat-icon
                      *ngIf="feature.description"
                      [matTooltip]="feature.description"
                      matTooltipClass="tooltip-description"
                      class="info-text"
                      >info_outline</mat-icon
                    >
                    <img
                      *ngIf="feature.is_palfinger"
                      src="/assets/logo.png"
                      class="palfinger-icon"
                      matTooltip="{{
                        'FEATURE_SELECTION.PALFINGER_USP' | translate
                      }}"
                      matTooltipClass="tooltip-description"
                    />
                  </div>
                </td>
              </ng-template>
              <ng-template #indentFeature>
                <td></td>
                <td>
                  <div
                    fxLayout
                    fxLayoutAlign="start center"
                    [fxLayoutGap]="1 | dsSpacing"
                  >
                    <mat-checkbox
                      [(ngModel)]="feature.selected"
                      *ngIf="feature.status === 'add_on'"
                      (change)="changeOthersIfOverall(feature)"
                    ></mat-checkbox>
                    <mat-checkbox
                      [disabled]="true"
                      [checked]="true"
                      *ngIf="feature.status === 'serial'"
                    ></mat-checkbox>
                    <div
                      class="mat-checkbox-inner-container mat-checkbox-inner-container-no-side-margin"
                      *ngIf="
                        feature.status !== 'serial' &&
                        feature.status !== 'add_on'
                      "
                    ></div>

                    <div
                      fxLayout
                      fxLayoutAlign="start center"
                      [fxLayoutGap]="0.5 | dsSpacing"
                    >
                      <span>
                        {{ feature.name }}
                      </span>
                      <mat-icon
                        *ngIf="feature.description"
                        [matTooltip]="feature.description"
                        matTooltipClass="tooltip-description"
                        class="info-text"
                        >info_outline</mat-icon
                      >
                      <img
                        *ngIf="feature.is_palfinger"
                        src="/assets/logo.png"
                        class="palfinger-icon"
                        matTooltip="{{
                          'FEATURE_SELECTION.PALFINGER_USP' | translate
                        }}"
                        matTooltipClass="tooltip-description"
                      />
                    </div>
                  </div>
                </td>
              </ng-template>

              <td
                *ngFor="let v of categoryValues"
                [ngClass]="
                  category === 'benefits' ? 'text-center' : 'text-right'
                "
              >
                <span
                  *ngIf="
                    category !== 'benefits' &&
                    calculateGroupSavings(feature, category, v) > 0
                  "
                >
                  <span *ngIf="category === 'economics'">€</span>
                  {{
                    calculateGroupSavings(feature, category, v)
                      | number: '1.0-0':this.translate.currentLang
                  }}
                </span>

                <mat-icon
                  *ngIf="category === 'benefits' && feature[category][v]"
                  >check</mat-icon
                >
              </td>
            </tr>
          </tbody>
        </table>
      </ds-table>
    </div>
  </ng-container>
</div>

<ng-template #summary>
  <div fxLayout="row wrap" [fxLayoutGap]="(1 | dsSpacing) + ' grid'">
    <div fxFlex="100%" fxFlex.gt-xs="50%" fxFlex.gt-md="25%">
      <mat-card class="h-100 full-height text-center">
        <h3 translate>FEATURE_SELECTION.SAVINGS.TOTAL</h3>

        <p translate>FEATURE_SELECTION.SAVINGS.YEARLY</p>
        <div class="sum success mb-5">
          €
          {{
            sumOf('economics', 'annual_savings')
              | number: '1.0-0':this.translate.currentLang
          }}
        </div>

        <br /><br />
        <p translate>FEATURE_SELECTION.SAVINGS.PALFINGER_USP</p>
        <div class="sum bg-yellow">
          €
          {{
            sumOf('economics', 'annual_savings', true)
              | number: '1.0-0':this.translate.currentLang
          }}
        </div>
      </mat-card>
    </div>

    <div fxFlex="100%" fxFlex.gt-xs="50%" fxFlex.gt-md="25%">
      <mat-card class="h-100 full-height text-center benefits">
        <h3 translate>FEATURE_SELECTION.SUB_NAV.BENEFITS</h3>

        <div>
          <mat-icon [class.success-text]="isSuccess('has_safety')"
            >verified_user</mat-icon
          >
          <p translate>FEATURE_SELECTION.BENEFITS.SAFETY</p>
        </div>

        <div>
          <mat-icon [class.success-text]="isSuccess('has_resale_value')"
            >attach_money</mat-icon
          >
          <p translate>FEATURE_SELECTION.BENEFITS.RESALE_VALUE</p>
        </div>

        <div>
          <mat-icon [class.success-text]="isSuccess('has_area_of_use')"
            >directions_car</mat-icon
          >
          <p translate>FEATURE_SELECTION.BENEFITS.AREA_OF_USE</p>
        </div>

        <div>
          <mat-icon
            [class.success-text]="isSuccess('has_optimized_performance')"
            >speed</mat-icon
          >
          <p translate>FEATURE_SELECTION.BENEFITS.OPTIMIZED_PERFORMANCE</p>
        </div>
        <!-- speed  av_timer -->
      </mat-card>
    </div>

    <div
      fxFlex="100%"
      fxFlex.gt-xs="50%"
      fxFlex.gt-md="25%"
      *ngIf="category === 'environment' || category === 'summary'"
    >
      <mat-card class="h-100 full-height text-center">
        <h3 translate>FEATURE_SELECTION.SUB_NAV.ENVIRONMENT</h3>

        <p translate>
          FEATURE_SELECTION.ECONOMICS.DIESEL.SAVINGS<br /><small translate
            >FEATURE_SELECTION.ECONOMICS.DIESEL.UNIT</small
          >
        </p>
        <mat-icon>local_gas_station</mat-icon>
        <div class="sum success mb-5">
          {{
            sumOf('environment', 'diesel_savings')
              | number: '1.0-0':this.translate.currentLang
          }}
        </div>

        <br /><br />
        <p translate>
          FEATURE_SELECTION.ECONOMICS.THG.SAVINGS<br /><small translate
            >FEATURE_SELECTION.ECONOMICS.THG.UNIT</small
          >
        </p>
        <div
          fxLayout
          fxLayoutAlign="center center"
          [fxLayoutGap]="0.25 | dsSpacing"
        >
          <mat-icon class="stacked">cloud</mat-icon>
          <span translate>FEATURE_SELECTION.SAVINGS.GHG</span>
        </div>
        <div class="sum success">
          {{
            sumOf('environment', 'thg_emission_savings')
              | number: '1.0-0':this.translate.currentLang
          }}
        </div>
      </mat-card>
    </div>

    <div
      fxFlex="100%"
      fxFlex.gt-xs="50%"
      fxFlex.gt-md="25%"
      *ngIf="category === 'health' || category === 'summary'"
    >
      <mat-card class="h-100 full-height text-center">
        <h3 translate>FEATURE_SELECTION.SUB_NAV.HEALTH</h3>

        <p translate>
          FEATURE_SELECTION.ECONOMICS.NITROGEN_OXIDE.SAVINGS<br /><small
            translate
            >FEATURE_SELECTION.ECONOMICS.NITROGEN_OXIDE.UNIT</small
          >
        </p>
        <div
          fxLayout
          fxLayoutAlign="center center"
          [fxLayoutGap]="0.25 | dsSpacing"
        >
          <mat-icon class="stacked">cloud</mat-icon>
          <span translate>FEATURE_SELECTION.SAVINGS.NO</span>
        </div>
        <div class="sum success mb-5">
          {{
            sumOf('health', 'nitrogen_oxide_savings')
              | number: '1.0-0':this.translate.currentLang
          }}
        </div>
        <br /><br />
        <p translate>
          FEATURE_SELECTION.ECONOMICS.PARTICULATE_MATTER.SAVINGS<br /><small
            translate
            >FEATURE_SELECTION.ECONOMICS.PARTICULATE_MATTER.UNIT</small
          >
        </p>

        <div
          fxLayout
          fxLayoutAlign="center center"
          [fxLayoutGap]="0.25 | dsSpacing"
        >
          <mat-icon class="stacked">cloud</mat-icon>
          <span translate>FEATURE_SELECTION.SAVINGS.PM</span>
        </div>
        <div class="sum success">
          {{
            sumOf('health', 'particulate_matter_savings')
              | number: '1.0-0':this.translate.currentLang
          }}
        </div>
      </mat-card>
    </div>
  </div>
</ng-template>

<ng-template #loading>
  <mat-card>
    <ds-table-loading
      [numberOfHeaders]="3"
      [numberOfRows]="10"
    ></ds-table-loading>
  </mat-card>
</ng-template>
