import { InjectionToken } from '@angular/core';

export const PALDESK_BASEPATH = new InjectionToken<string>('paldeskBasePath');
export const PALDESK_SUPPORT_PATH = new InjectionToken<string>(
  'paldeskSupportPath',
);
export const NEW_USER_PROFILE_ENABLED = new InjectionToken<boolean>(
  'newUserProfileEnabled',
);
export const TELEMATIC_SUPPORT_ENABLED = new InjectionToken<boolean>(
  'hasTelematicSupport',
);
