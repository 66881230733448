<div fxLayout="column" [fxLayoutGap]="1 | dsSpacing" class="full-height">
  <h1 translate fxFlex="0 0 auto">ADMIN.SUB_NAV.FEATURE_GROUPS</h1>
  <ds-table fxFlex="0 1 auto">
    <table cdkDropList class="pd-table" (cdkDropListDropped)="drop($event)">
      <thead>
        <tr class="mat-header-row">
          <th class="mat-header-cell mat-table-sticky sort-cell"></th>
          <th class="mat-header-cell mat-table-sticky" translate>
            ADMIN.FEATURE_GROUPS.FEATURE_GROUP
          </th>
          <th
            class="mat-header-cell mat-action-cell mat-table-sticky"
            translate
          >
            ADMIN.OPTIONS
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          cdkDrag
          cdkDragLockAxis="y"
          class="list-item"
          *ngFor="let group of feature_groups"
        >
          <td cdkDragHandle class="mat-cell mat-table-sticky sort-cell">
            <button mat-icon-button>
              <mat-icon>reorder</mat-icon>
            </button>
          </td>
          <td>
            {{ group.name }}
          </td>

          <td class="mat-cell mat-action-cell mat-table-sticky">
            <button mat-button (click)="openDialog(group)">
              {{ 'G.EDIT' | translate }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </ds-table>
</div>
