<form #form="ngForm" (ngSubmit)="update(form, editData.id)">
  <h1 mat-dialog-title translate>ADMIN.MODALS.FEATURE_EDIT.TITLE</h1>

  <mat-dialog-content *ngIf="editData">
    <mat-tab-group>
      <mat-tab *ngFor="let locale of ['de', 'en']">
        <ng-template mat-tab-label>
          <img class="flag" src="../../../assets/locales/{{ locale }}.svg" />
          <span>{{ locale | uppercase }}</span>
        </ng-template>

        <div fxLayout="column">
          <br />
          <mat-form-field>
            <mat-label>Name</mat-label>
            <input
              matInput
              *ngIf="editData['name_' + locale] !== null"
              [(ngModel)]="editData['name_' + locale]"
              name="{{ 'name_' + locale }}"
            />
            <input
              matInput
              *ngIf="editData['name_' + locale] === null"
              [(ngModel)]="editData['name_' + locale]"
              name="{{ 'name_' + locale }}"
              placeholder="{{ 'Translation missing' }}"
            />
          </mat-form-field>

          <mat-form-field>
            <mat-label>Description</mat-label>
            <textarea
              matInput
              cdkTextareaAutosize
              *ngIf="editData['description_' + locale] !== null"
              [(ngModel)]="editData['description_' + locale]"
              name="{{ 'description_' + locale }}"
            ></textarea>
            <textarea
              matInput
              cdkTextareaAutosize
              *ngIf="editData['description_' + locale] === null"
              [(ngModel)]="editData['description_' + locale]"
              name="{{ 'description_' + locale }}"
              placeholder="{{ 'Translation missing' }}"
            ></textarea>
          </mat-form-field>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button mat-dialog-close>{{ 'G.CANCEL' | translate }}</button>
    <button
      mat-raised-button
      color="accent"
      type="button"
      (click)="form.ngSubmit.emit()"
    >
      {{ 'G.SAVE' | translate }}
    </button>
  </mat-dialog-actions>
</form>
