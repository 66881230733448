import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Feature } from '../../../../classes';
import { LocalStorageService } from '../../../../services/local-storage.service';
@Component({
  selector: 'bi-compact-feature-selection',
  templateUrl: './compact-feature-selection.component.html',
  styleUrls: ['./compact-feature-selection.component.scss'],
})
export class CompactFeatureSelectionComponent implements OnInit {
  public features: Array<any>;
  public selectedCompactFeatures: Array<number>;
  public lastGroupName = '';

  constructor(
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
  ) {
    // Get data from resolver
    this.features = this.route.snapshot.data.data;
  }

  ngOnInit() {
    // Load stored selection
    this.selectedCompactFeatures =
      <Array<number>>(
        this.localStorageService.deserialize('selectedCompactFeatures')
      ) || [];
  }

  public updateSelection(id: number): void {
    const storedFeatures =
      <Array<number>>(
        this.localStorageService.deserialize('selectedCompactFeatures')
      ) || [];
    const index = storedFeatures.indexOf(id);
    if (index !== -1) {
      storedFeatures.splice(index, 1);
    } else {
      storedFeatures.push(id);
    }
    this.localStorageService.serialize(
      'selectedCompactFeatures',
      storedFeatures,
    );
  }

  public filterFeatures() {
    return this.features.filter(
      (f) =>
        f.name.toLowerCase().includes('gesamtkonzept') === false &&
        f.name.toLowerCase().includes('total concept') === false,
    );
  }

  public displayGroupName(feature: Feature) {
    const check = this.lastGroupName !== feature.group;
    this.lastGroupName = feature.group;
    return check;
  }
}
