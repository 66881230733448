<h1 translate>HOME.GREETING</h1>

<p [innerHTML]="'HOME.INTRO' | translate"></p>
<p [innerHTML]="'HOME.INTRO_2' | translate"></p>

<br />
<div
  fxLayout="row wrap"
  fxLayoutAlign="start center"
  [fxLayoutGap]="(1 | dsSpacing) + ' grid'"
>
  <div fxFlex="300px">
    <canvas #pieChart id="pieChart" height="300"></canvas>
  </div>
  <div
    #pieChartLegend
    id="pieChartLegend"
    class="chart-legend d-flex justify-content-center justify-content-md-start"
  ></div>
</div>
<br />

<p>
  <small translate>HOME.CAPTION</small><br />
  <small translate>HOME.CAPTION_2</small>
</p>

<button mat-raised-button class="mt-3 mr-3" [routerLink]="['/data-collection']">
  <mat-icon color="primary">play_arrow</mat-icon> {{ 'NAV.HOME' | translate }}
</button>

<button
  mat-raised-button
  class="mt-3 mr-3"
  *ngFor="let locale of ['de', 'en']"
  (click)="useLanguage(locale)"
>
  <img class="flag" src="../../../assets/locales/{{ locale }}.svg" />
  {{ locale | uppercase }}
</button>
