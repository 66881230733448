import { formatNumber } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'chart.js';
import { ChartDataObject } from '../../../../../classes';
import { DataCollectionService } from '../../../../data-collection/data-collection.service';
// Stores shared data between chart and card.
export interface IChartSettings {
  label: string;
  value: number;
  color: string;
}

@Component({
  selector: 'bi-costs-savings-duration-of-use-chart',
  templateUrl: './costs-savings-duration-of-use-chart.component.html',
  styleUrls: ['./costs-savings-duration-of-use-chart.component.scss'],
})
export class CostsSavingsDurationOfUseChartComponent
  implements AfterViewInit, OnInit {
  @ViewChild('barChart') private chartRef: any;

  @Input()
  set values(values: ChartDataObject) {
    this._values = values;
    this.setChartSettings();
  }

  get values(): ChartDataObject {
    return this._values;
  }

  public chartSettings: IChartSettings[];
  public selectedUsageTime: number;
  private _values: ChartDataObject;
  private barChart: any;
  private chartOptions: any = {
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          display: true,
          stacked: true,
          ticks: {},
        },
      ],
      yAxes: [
        {
          display: false,
          stacked: true,
          id: 'y-axis-1',
          categoryPercentage: 1,
          barPercentage: 1,
        },
        {
          display: false,
          stacked: true,
          id: 'y-axis-2',
          // these are needed because the bar controller defaults set only the first x axis properties
          type: 'category',
          categoryPercentage: 1,
          barPercentage: 0.2,
          gridLines: {
            display: false,
          },
          offset: true,
        },
      ],
    },
    tooltips: {
      mode: 'single',
      bodyFontSize: 15,
      xPadding: 20,
      yPadding: 10,
      custom: (tooltip: any) => {
        if (tooltip && tooltip.opacity === 0) {
          this.resetDefaultColors();
        }
      },
    },
  };

  constructor(
    public translate: TranslateService,
    private dataCollectionService: DataCollectionService,
  ) {
    // Needs initialization in constructor for 'translate' to work.
    this.chartOptions.tooltips.callbacks = {
      label: function (tooltipItem: any, data: any) {
        // Format label
        const originalNumber: any =
          data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
        const formattedNumber =
          formatNumber(originalNumber, translate.currentLang || 'en', '1.0-0') +
          ' €';

        return (
          '  ' +
          data.datasets[tooltipItem.datasetIndex].label +
          ': ' +
          formattedNumber
        );
      },
      title: function () {
        /* Hide title */
      },
    };
    // Needs initialization in constructor for 'translate' to work.
    this.chartOptions.scales.xAxes[0].ticks.callback = (
      label: any,
      index: any,
      labels: any,
    ) =>
      formatNumber(label, this.translate.currentLang || 'en', '1.0-0') + ' €';
  }

  ngOnInit() {
    this.selectedUsageTime = this.dataCollectionService.selectedUsageTime;
  }

  ngAfterViewInit() {
    if (this.chartSettings) {
      this.barChart = new Chart(this.chartRef.nativeElement, {
        type: 'horizontalBar',
        data: {
          datasets: [
            {
              data: [this.chartSettings[0].value],
              backgroundColor: this.chartSettings[0].color,
              label: this.chartSettings[0].label,
              stack: 'Stack 0',
            },
            {
              data: [this.chartSettings[1].value],
              backgroundColor: this.chartSettings[1].color,
              label: this.chartSettings[1].label,
              stack: 'Stack 0',
            },
            {
              data: [this.chartSettings[2].value],
              backgroundColor: this.chartSettings[2].color,
              label: this.chartSettings[2].label,
              stack: 'Stack 0',
            },
            {
              data: [this.chartSettings[3].value],
              backgroundColor: this.chartSettings[3].color,
              label: this.chartSettings[3].label,
              stack: 'Stack 1',
              yAxisID: 'y-axis-2',
            },
          ],
        },
        options: this.chartOptions,
      });
    }
  }

  // Prefills settings for usage in chart creation and for cards.
  setChartSettings() {
    // NOTE: we use translate.instant here since the charts library would cut off the labels on rendering.
    // This way we have the translations ready before they get cut off.
    this.chartSettings = [
      {
        label: this.translate.instant('STATISTICS.CHARTS.SHARED.COSTS'),
        value: this._values.sum * this.dataCollectionService.selectedUsageTime,
        color: '#ffbf6b',
      },
      {
        label: this.translate.instant(
          'STATISTICS.CHARTS.SHARED.SAVINGS_OLD_CRANE',
        ),
        value:
          this._values.techSavings *
          this.dataCollectionService.selectedUsageTime,
        color: '#d1675a',
      },
      {
        label: this.translate.instant(
          'STATISTICS.CHARTS.SHARED.SAVINGS_PALFINGER_USP',
        ),
        value:
          this._values.palfingerSavings *
          this.dataCollectionService.selectedUsageTime,
        color: '#96312e',
      },
      {
        label: this.translate.instant(
          'STATISTICS.CHARTS.SHARED.ORIGINAL_COSTS',
        ),
        value:
          (this._values.sum +
            this._values.techSavings +
            this._values.palfingerSavings) *
          this.dataCollectionService.selectedUsageTime,
        color: '#aaaaaa',
      },
    ];
  }

  // Shows a tooltip when clicking on a given element.
  showTooltip(index: number) {
    const segment = this.barChart.getDatasetMeta(index).data[0];
    // Reset colors and assign hover color to active element.
    // @see Charts, core.helpers.js for color usage.
    this.resetDefaultColors();
    segment._model.backgroundColor = Chart.helpers
      .color(this.chartSettings[index].color)
      .saturate(0.5)
      .darken(0.1)
      .rgbString();
    // Show tooltip for active segment.
    this.barChart.tooltip._active = [segment];
    this.barChart.tooltip.update();
    this.barChart.draw();
  }

  // Reset each segment to its original backgroundColor.
  resetDefaultColors() {
    for (let i = 0; i < this.barChart.data.datasets.length; i++) {
      this.barChart
        .getDatasetMeta(i)
        .data.forEach(function (ele: any, idx: number) {
          // eslint-disable-next-line no-self-assign
          ele._model.backgroundColor = ele._model.backgroundColor;
        });
    }

    this.barChart.update();
  }
}
