<form
  [formGroup]="passwordForm"
  fxLayout="row wrap"
  fxLayoutGap="1rem grid"
  autocomplete="off"
>
  <mat-form-field fxFlex class="full-width">
    <mat-icon
      (click)="showPassword = !showPassword"
      matTooltip="{{ 'confirm_password.show_password' | translate }}"
      matSuffix
      >{{ showPassword ? 'visibility_off' : 'visibility' }}</mat-icon
    >
    <mat-label>{{ 'confirm_password.password' | translate }}</mat-label>
    <input
      [type]="showPassword ? 'text' : 'password'"
      matInput
      formControlName="password"
      [minlength]="minLength"
      #passInput
      data-cy="cy_password"
      autocomplete="none"
      id="no-autocomplete"
    />
    <mat-error
      *ngIf="passwordForm.get('password')?.hasError('digit')"
      align="'end'"
      >{{ 'confirm_password.should_contain_digit' | translate }}</mat-error
    >
    <mat-error *ngIf="passwordForm.get('password')?.hasError('uppercase')">{{
      'confirm_password.should_contain_uppercase_letter' | translate
    }}</mat-error>
    <mat-error *ngIf="passwordForm.get('password')?.hasError('lowercase')">{{
      'confirm_password.should_contain_lowercase_letter' | translate
    }}</mat-error>
    <mat-error *ngIf="passwordForm.get('password')?.hasError('minlength')"
      >{{
        'confirm_password.should_be_at_least_x_chars'
          | translate: { minLen: minLength }
      }}
    </mat-error>
  </mat-form-field>
  <mat-form-field fxFlex class="full-width">
    <mat-icon
      (click)="showPassword = !showPassword"
      matTooltip="{{ 'confirm_password.show_password' | translate }}"
      matSuffix
      >{{ showPassword ? 'visibility_off' : 'visibility' }}</mat-icon
    >
    <mat-label>{{ 'confirm_password.confirm_password' | translate }}</mat-label>
    <input
      matInput
      #passConfirmInput
      [type]="showPassword ? 'text' : 'password'"
      formControlName="confirm_password"
      [required]="passInput.value.length > 0"
      [errorStateMatcher]="passwordErrorMatcher"
      data-cy="cy_confirm_password"
      autocomplete="none"
    />
    <mat-error *ngIf="passConfirmInput.value.length">{{
      'confirm_password.passwords_should_match' | translate
    }}</mat-error>
  </mat-form-field>
</form>
