import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  IFeatureGroup,
  IFeatureGroupEdit,
} from '../admin/feature-groups/feature-groups.component';
import { BEST_INVEST_API_BASE_PATH } from '../core/base-path-token';

@Injectable({
  providedIn: 'root',
})
export class FeatureGroupService {
  constructor(
    private http: HttpClient,
    @Inject(BEST_INVEST_API_BASE_PATH) private basePath: string,
  ) {}

  // index
  public loadAllFeatureGroups(): Promise<IFeatureGroup[]> {
    return new Promise((resolve) => {
      const url = '/feature_groups.json';
      this.sendGet(url, resolve);
    });
  }

  // show
  public loadFeatureGroup(featureGroupId: number): Promise<IFeatureGroup> {
    return new Promise((resolve) => {
      // eslint-disable-next-line sonarjs/no-duplicate-string
      const url = '/feature_groups/' + featureGroupId;
      this.sendGet(url, resolve);
    });
  }

  // edit
  public editFeatureGroup(featureGroupId: number): Promise<IFeatureGroupEdit> {
    return new Promise((resolve) => {
      const url = '/feature_groups/' + featureGroupId + '/edit';
      this.sendGet(url, resolve);
    });
  }

  // update
  public updateFeatureGroup(
    form: any,
    featureGroupId: number,
  ): Promise<IFeatureGroup> {
    return new Promise((resolve) => {
      const url = '/feature_groups/' + featureGroupId;
      this.sendPost(url, resolve, { feature_group: form });
    });
  }

  // sort update
  public updateFeatureGroupOrdering(
    feature_groups: IFeatureGroup[],
  ): Promise<any> {
    return new Promise((resolve) => {
      const url = '/feature_groups/sort.json';
      this.sendPost(url, resolve, { feature_groups: feature_groups });
    });
  }

  // GET
  private sendGet(url: string, resolve: (value?: any) => void) {
    url = this.basePath + url;

    this.http.get(url).subscribe((data: any) => {
      resolve(data);
    });
  }

  // POST
  private sendPost(url: string, resolve: (value?: any) => void, params: any) {
    url = this.basePath + url;

    this.http.post(url, params).subscribe((data: any) => {
      resolve(data);
    });
  }
}
