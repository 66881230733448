<div class="row no-gutters">
  <div class="col-12 mb-3 col-lg-6 mb-lg-0">
    <canvas #pieChart id="pieChart" height="300"></canvas>
  </div>

  <div class="col-12 col-lg-6 my-lg-auto">
    <div
      #pieChartLegend
      id="pieChartLegend"
      class="compact-chart-legend d-flex justify-content-center justify-content-md-start"
    ></div>
  </div>
</div>
