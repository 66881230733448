import { Component, Inject, Optional } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DsSpotlight } from '@design-system/feature/spotlight';
import { UserService } from '@features/auth';
import { AppWrapperService } from '../app-wrapper.service';
import {
  NEW_USER_PROFILE_ENABLED,
  PALDESK_BASEPATH,
  PALDESK_SUPPORT_PATH,
  TELEMATIC_SUPPORT_ENABLED,
} from '../environment';
import { UserProfileComponent } from './user-profile/user-profile.component';

@Component({
  selector: 'ds-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent {
  paldeskBasePath = 'https://paldesk.palfinger.com';
  paldeskSupportPath = 'https://status-paldesk.palfinger.com';
  newUserProfileEnabled = false;
  hasTelematicSupport = false;
  copyrightYear = new Date().getFullYear();

  constructor(
    public userService: UserService,
    @Optional() @Inject(PALDESK_BASEPATH) paldeskBasePath: string,
    @Optional() @Inject(PALDESK_SUPPORT_PATH) paldeskSupportPath: string,
    @Optional()
    @Inject(NEW_USER_PROFILE_ENABLED)
    newUserProfileEnabled: boolean,
    @Optional() @Inject(TELEMATIC_SUPPORT_ENABLED) hasTelematicSupport: boolean,
    private dialog: MatDialog,
    private _dsSpotlight: DsSpotlight,
    private wrapperService: AppWrapperService,
  ) {
    if (paldeskBasePath) {
      this.paldeskBasePath = paldeskBasePath;
    }
    if (newUserProfileEnabled) {
      this.newUserProfileEnabled = newUserProfileEnabled;
    }
    if (paldeskSupportPath) {
      this.paldeskSupportPath = paldeskSupportPath;
    }
    if (hasTelematicSupport) {
      this.hasTelematicSupport = hasTelematicSupport;
    }
  }

  logout() {
    this.userService.logout();
  }

  login() {
    this.userService.login();
  }

  openUserProfile(): void {
    this.dialog.open(UserProfileComponent);
  }

  resetSpotlight() {
    this._dsSpotlight.resetWholeSpotlight();
    this.wrapperService.drawerClose();
  }
}
