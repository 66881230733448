import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DsTableLoadingModule } from '@design-system/components/table';
import { DsPageModule } from '@design-system/feature/page';
import { TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from '../../app-routing.module';
import { MaterialModule } from '../../material.module';
import { GeneralModule } from '../general/general.module';
import { ChartsComponent } from './components/charts/charts.component';
import { CostsPerYearChartComponent } from './components/charts/costs-per-year-chart/costs-per-year-chart.component';
import { CostsPerYearGreenhouseGasEmissionChartComponent } from './components/charts/costs-per-year-greenhouse-gas-emission-chart/costs-per-year-greenhouse-gas-emission-chart.component';
import { CostsSavingsDurationOfUseChartComponent } from './components/charts/costs-savings-duration-of-use-chart/costs-savings-duration-of-use-chart.component';
import { DieselPerYearChartComponent } from './components/charts/diesel-per-year-chart/diesel-per-year-chart.component';
import { YearlyGreenhouseGasEmissionChartComponent } from './components/charts/yearly-greenhouse-gas-emission-chart/yearly-greenhouse-gas-emission-chart.component';
import { YearlyNitrogenOxideEmissionChartComponent } from './components/charts/yearly-nitrogen-oxide-emission-chart/yearly-nitrogen-oxide-emission-chart.component';
import { YearlyParticulateMatterEmissionChartComponent } from './components/charts/yearly-particulate-matter-emission-chart/yearly-particulate-matter-emission-chart.component';
import { YearlySavingsPerFeatureChartComponent } from './components/charts/yearly-savings-per-feature-chart/yearly-savings-per-feature-chart.component';
import { YearlyUsageCostsCraneChartComponent } from './components/charts/yearly-usage-costs-crane-chart/yearly-usage-costs-crane-chart.component';
import { CostCalculationComponent } from './components/cost-calculation/cost-calculation.component';
import { StatisticsSidebarComponent } from './components/statistics/statistics-sidebar/statistics-sidebar.component';
import { StatisticsComponent } from './components/statistics/statistics.component';

const components = [
  StatisticsComponent,
  CostCalculationComponent,
  ChartsComponent,
  // All charts
  CostsPerYearChartComponent,
  CostsPerYearGreenhouseGasEmissionChartComponent,
  CostsSavingsDurationOfUseChartComponent,
  DieselPerYearChartComponent,
  YearlyGreenhouseGasEmissionChartComponent,
  YearlyNitrogenOxideEmissionChartComponent,
  YearlyParticulateMatterEmissionChartComponent,
  YearlySavingsPerFeatureChartComponent,
  YearlyUsageCostsCraneChartComponent,
  StatisticsSidebarComponent,
];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    MaterialModule,
    GeneralModule,
    TranslateModule,
    AppRoutingModule,
    FlexLayoutModule,
    // Design System
    DsPageModule,
    DsTableLoadingModule,
  ],
  exports: components,
})
export class StatisticsModule {}
