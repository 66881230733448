import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  public store(key: string, data: string): string {
    localStorage.setItem(key, data);
    return key;
  }

  public load(key: string): string | null {
    return localStorage.getItem(key);
  }

  public serialize(key: string, data: any): string {
    localStorage.setItem(key, JSON.stringify(data, this.replacer));
    return key;
  }

  public deserialize(key: string): any | null {
    const json = localStorage.getItem(key);
    if (json === null) {
      return null;
    }
    try {
      return JSON.parse(json, this.reviver);
    } catch (error) {
      return null;
    }
  }

  private replacer(key: any, value: any) {
    const originalObject = this[key];
    if (originalObject instanceof Map) {
      return {
        dataType: 'Map',
        value: Array.from(originalObject.entries()),
      };
    } else {
      return value;
    }
  }

  private reviver(key: any, value: any) {
    if (
      typeof value === 'object' &&
      value !== null &&
      value.dataType === 'Map'
    ) {
      return new Map(value.value);
    }
    return value;
  }
}
