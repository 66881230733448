<h3 *ngIf="data.title" class="title" [innerHTML]="data.title"></h3>

<div
  *ngIf="data.description"
  class="description"
  [innerHTML]="data.description"
></div>

<mat-dialog-actions>
  <button mat-button color="primary" type="button" mat-dialog-close>
    {{ 'G.CLOSE' | translate }}
  </button>
</mat-dialog-actions>
