<div
  fxLayout="column"
  [fxLayoutGap]="1 | dsSpacing"
  class="sidebar-content scroll-container-vertical hidden-scollbar full-height"
  [class.mini]="!isExpanded && !media.isActive('xs')"
>
  <a *ngIf="backUrl; else paldeskLogo" (click)="navigateBack()">
    <ds-page-sidebar-item>
      <mat-icon ds-page-sidebar-item-icon>arrow_back</mat-icon>
      {{ 'general.back' | translate }}
    </ds-page-sidebar-item>
  </a>
  <div #componentOutlet></div>
</div>

<ng-template #paldeskLogo>
  <a
    class="inherit-color"
    [href]="paldeskUrl"
    [style.padding-left]="0.5 | dsSpacing"
  >
    <h2
      id="logo"
      class="palfinger-font uppercase left-text"
      [class.mini]="!isExpanded && !media.isActive('xs')"
    >
      P<span>aldesk</span>
    </h2>
  </a>
</ng-template>
