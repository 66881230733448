<div>
  <h5 class="no-margin">
    {{ title | translate }}
  </h5>
  <ng-container *ngFor="let child of children">
    <ng-container *ngTemplateOutlet="link; context: { $implicit: child }">
    </ng-container>
    <div class="level" *ngFor="let innerChild of child.children">
      <ng-container
        *ngTemplateOutlet="link; context: { $implicit: innerChild }"
      >
      </ng-container>
    </div>
  </ng-container>
</div>
<ng-template #link let-item>
  <a
    *ngIf="item.routerLink; else external"
    [routerLink]="item.routerLink"
    routerLinkActive="active"
    class="full-width"
    fxLayout="row"
  >
    <span fxFlex="0 0 auto" class="content cut-text">{{
      item.title | translate
    }}</span>
    <span
      fxFlex="0 1 auto"
      class="badge-placeholder"
      [matBadge]="item.badge"
      [matBadgeHidden]="!item.badge"
      matBadgeColor="primary"
      matBadgeSize="small"
    ></span>
  </a>
  <ng-template #external>
    <a
      mat-button
      [href]="item.externalLink"
      target="_blank"
      class="full-width"
      fxLayout="row"
    >
      <span fxFlex="0 0 auto" class="content cut-text">
        {{ item.title | translate }}</span
      >
      <span
        fxFlex="0 1 auto"
        class="badge-placeholder"
        [matBadge]="item.badge"
        [matBadgeHidden]="!item.badge"
        matBadgeColor="primary"
        matBadgeSize="small"
      ></span>
    </a>
  </ng-template>
</ng-template>
