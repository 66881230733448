// third party
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
// project
import { UserContext } from './user-context';

@Injectable()
export class UserContextService {
  private _userContext: UserContext;
  private _currentUser = new ReplaySubject<UserContext | undefined>(1);

  /**
   * Set User data
   * @param userContext data from userinfo endpoint
   */
  public setUser(userContext: UserContext | null): void {
    if (userContext) {
      this._userContext = userContext;
      this._currentUser.next(userContext);
    }
  }

  get userContext(): UserContext {
    return this._userContext;
  }

  get currentUser(): Observable<UserContext | undefined> {
    return this._currentUser.asObservable();
  }
}
