import { Injectable } from '@angular/core';
import { Adapter } from '../core/adapter';

export class CraneTechnology {
  constructor(
    public id: number,
    public name: string,
    public definition: string,
  ) {}
}

@Injectable({
  providedIn: 'root',
})
export class CraneTechnologyAdapter implements Adapter<CraneTechnology> {
  adapt(item: any): CraneTechnology {
    return new CraneTechnology(item.id, item.name, item.definition);
  }
}
