import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EditFeatureGroupTranslationModalComponent } from '../../modals/edit-feature-group-translation-modal/edit-feature-group-translation-modal.component';
import { FeatureGroupService } from '../../services/feature-group.service';
import { AdminComponent } from '../admin.component';

export interface IFeatureGroup {
  id: number;
  name: string;
  description: string;
}

export interface IFeatureGroupEdit {
  id: number;
  name_de: string;
  name_en: string;
  description_de: string;
  description_en: string;
}

@Component({
  selector: 'bi-feature-groups',
  templateUrl: './feature-groups.component.html',
  styleUrls: ['./feature-groups.component.scss'],
})
export class FeatureGroupsComponent extends AdminComponent implements OnInit {
  feature_groups: IFeatureGroup[];

  constructor(
    public router: Router,
    public translate: TranslateService,
    private featureGroupService: FeatureGroupService,
    private dialog: MatDialog,
  ) {
    super();
  }

  async ngOnInit() {
    this.getFeatureGroups();
  }

  public async drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.feature_groups,
      event.previousIndex,
      event.currentIndex,
    );

    await this.featureGroupService.updateFeatureGroupOrdering(
      this.feature_groups,
    );
  }

  public async openDialog(featureGroup: IFeatureGroup) {
    const dialogRef = this.dialog.open(
      EditFeatureGroupTranslationModalComponent,
      {
        width: '50%',
        data: featureGroup.id,
      },
    );

    dialogRef.afterClosed().subscribe(async () => {
      const updatedGroup = await this.featureGroupService.loadFeatureGroup(
        featureGroup.id,
      );

      for (let i = 0; i < this.feature_groups.length; i++) {
        const element = this.feature_groups[i];

        if (element.id === updatedGroup.id) {
          this.feature_groups[i] = updatedGroup;
          return;
        }
      }
    });
  }

  private async getFeatureGroups() {
    const data = await this.featureGroupService.loadAllFeatureGroups();

    this.feature_groups = data['feature_groups'];
  }
}
