import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'bi-alert-missing-data-select-modal',
  templateUrl: './alert-missing-data-select-modal.component.html',
  styleUrls: ['./alert-missing-data-select-modal.component.scss'],
})
export class AlertMissingDataSelectModalComponent {
  constructor(
    public dialogRef: MatDialogRef<AlertMissingDataSelectModalComponent>,
  ) {}
}
