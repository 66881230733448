<ds-page-sidebar>
  <ds-page-sidebar-header>
    <mat-icon ds-page-sidebar-item-icon>
      graphic_eq
    </mat-icon>
    <h4 translate>NAV.STATISTICS</h4>
  </ds-page-sidebar-header>

  <ds-page-sidebar-body>
    <ds-page-sidebar-item
      [routerLink]="['/statistics/cost-calculation']"
      routerLinkActive="active"
    >
      <mat-icon ds-page-sidebar-item-icon>euro_symbol</mat-icon>
      <span translate>STATISTICS.SUB_NAV.COSTS</span>
    </ds-page-sidebar-item>

    <ds-page-sidebar-item
      [routerLink]="['/statistics/charts']"
      routerLinkActive="active"
    >
      <mat-icon ds-page-sidebar-item-icon>bar_chart</mat-icon>
      <span translate>STATISTICS.SUB_NAV.CHARTS</span>
    </ds-page-sidebar-item>
  </ds-page-sidebar-body>
</ds-page-sidebar>
