import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsPageModule } from '@design-system/feature/page';
import { TranslateModule } from '@ngx-translate/core';
import { GeneralModule } from '../general/general.module';
import { CraneSelectComponent } from './components/crane-select/crane-select.component';
import { CraneTechnologyComponent } from './components/crane-technology/crane-technology.component';
import { CraneTypeComponent } from './components/crane-type/crane-type.component';
import { DataCollectionSidebarComponent } from './components/data-collection-sidebar/data-collection-sidebar.component';
import { DataCollectionComponent } from './components/data-collection/data-collection.component';
import { DataInputComponent } from './components/data-input/data-input.component';
import { ExtendedInputComponent } from './components/extended-input/extended-input.component';

@NgModule({
  declarations: [
    DataCollectionComponent,
    CraneTypeComponent,
    CraneTechnologyComponent,
    CraneSelectComponent,
    DataInputComponent,
    ExtendedInputComponent,
    DataCollectionSidebarComponent,
  ],
  imports: [
    GeneralModule,
    TranslateModule,
    // Design System
    DsPageModule,
    FlexLayoutModule,
    DsSpacingModule,
  ],
  exports: [DataCollectionComponent, DataCollectionSidebarComponent],
})
export class DataCollectionModule {}
