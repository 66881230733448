import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { DsTextIndicatorModule } from '@design-system/components/text-indicator';
import { DsBannerComponent } from './banner.component';

@NgModule({
  imports: [CommonModule, MatCardModule, DsTextIndicatorModule],
  declarations: [DsBannerComponent],
  exports: [DsBannerComponent],
})
export class DsBannerModule {}
