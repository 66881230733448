import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CraneSize,
  CraneSizeAdapter,
  CraneTechnology,
  CraneTechnologyAdapter,
  StandardValue,
  StandardValueAdapter,
} from '../../../../classes';
import { BEST_INVEST_API_BASE_PATH } from '../../../../core/base-path-token';
import {
  OptionsResponse,
  StandardValuesResponse,
} from '../../../../core/jsonResponses';
import { LocalStorageService } from '../../../../services/local-storage.service';
import { DataCollectionService } from '../../data-collection.service';

@Component({
  selector: 'bi-crane-select',
  templateUrl: './crane-select.component.html',
  styleUrls: ['./crane-select.component.scss'],
})
export class CraneSelectComponent implements OnInit {
  cranes: CraneSize[];
  standardValues: Map<string, StandardValue> = new Map();
  selectedCraneSize: CraneSize;
  selectedCraneTechnology: CraneTechnology;

  constructor(
    private http: HttpClient,
    private craneSizeAdapter: CraneSizeAdapter,
    private craneTechnologyAdapter: CraneTechnologyAdapter,
    private standardValueAdapter: StandardValueAdapter,
    @Inject(BEST_INVEST_API_BASE_PATH) private basePath: string,
    private dataCollectionService: DataCollectionService,
    private localStorageService: LocalStorageService,
  ) {}

  ngOnInit() {
    const storedSize = <CraneSize>(
      this.localStorageService.deserialize('craneSelect-size')
    );
    const storedTechnology = <CraneTechnology>(
      this.localStorageService.deserialize('craneSelect-technology')
    );
    // Use stored or default values
    this.selectedCraneSize =
      storedSize || this.dataCollectionService.selectedCraneSize;
    this.selectedCraneTechnology =
      storedTechnology || this.dataCollectionService.selectedCraneTechnology;

    this.getOptions().subscribe((res: OptionsResponse) => {
      this.cranes = res.crane_sizes.map((craneSize) =>
        this.prepareCrane(craneSize),
      );

      this.preselectValues();
    });
  }

  onSelectCraneSize(selectedCraneSize: CraneSize): void {
    this.selectedCraneSize = selectedCraneSize;
    this.dataCollectionService.storeSelectedCraneSize(selectedCraneSize);
  }

  onSelectCraneTechnology(selectCraneTechnology: CraneTechnology): void {
    let technologyAlreadySelected = false;
    if (this.selectedCraneTechnology && selectCraneTechnology) {
      technologyAlreadySelected =
        this.selectedCraneTechnology.id === selectCraneTechnology.id;
    }

    this.selectedCraneTechnology = selectCraneTechnology;
    this.dataCollectionService.storeSelectedCraneTechnology(
      selectCraneTechnology,
    );

    if (!technologyAlreadySelected) {
      this.getStandardValues().subscribe(
        (res: StandardValuesResponse) => {
          res.standard_values.forEach((item) => {
            const standardValue = this.standardValueAdapter.adapt(item);
            this.standardValues.set(standardValue.key, standardValue);
          });
          this.dataCollectionService.storeStandardValues(this.standardValues);
        },
        (err) => console.warn(err),
      );
    }
  }

  private prepareCrane(craneSize): CraneSize {
    const crane: CraneSize = this.craneSizeAdapter.adapt(craneSize);
    craneSize['crane_technologies'].forEach((craneTechnology) => {
      const craneTech = this.craneTechnologyAdapter.adapt(craneTechnology);

      // Skip "SH" technology level in small cranes
      if (crane.uid === 'KK' && craneTech.name === 'SH') {
        return;
      }

      crane.crane_technologies.push(craneTech);
    });
    return crane;
  }

  // Prefills the radio button menu
  private preselectValues() {
    if (!this.selectedCraneSize) {
      this.onSelectCraneSize(this.cranes[0]);
    } else {
      const crane = this.cranes.filter(
        (cs) => cs.id === this.selectedCraneSize.id,
      )[0];
      this.onSelectCraneSize(crane);
    }
    if (!this.selectedCraneTechnology) {
      this.onSelectCraneTechnology(this.cranes[0].crane_technologies[0]);
    } else {
      const crane = this.cranes.filter(
        (cs) => cs.id === this.selectedCraneSize.id,
      )[0];
      const tech = crane.crane_technologies.filter(
        (ct) => ct.id === this.selectedCraneTechnology.id,
      )[0];
      this.onSelectCraneTechnology(tech);
    }
  }

  private getOptions(): Observable<OptionsResponse> {
    const route = '/calculations/options';
    return this.http.get<OptionsResponse>(this.basePath + route);
  }

  private getStandardValues(): Observable<StandardValuesResponse> {
    const route = `/calculations/standard_values/${this.selectedCraneSize.id}`;
    return this.http.get<StandardValuesResponse>(this.basePath + route);
  }
}
