import { Inject, Injectable, Optional } from '@angular/core';
import { UserService } from '@features/auth';
import { TranslateService } from '@ngx-translate/core';
import {
  FreshdeskWebwidgetConfig,
  FreshdeskWebwidgetConfigToken,
} from './freshdesk-widget-config';
@Injectable({
  providedIn: 'root',
})
export class FreshdeskWidgetService {
  constructor(
    private translateService: TranslateService,
    @Optional()
    @Inject(FreshdeskWebwidgetConfigToken)
    private config: FreshdeskWebwidgetConfig,
    @Optional() private userService: UserService,
  ) {
    if (config) {
      const lang =
        this.config?.locale || this.userService?.userContext?.lang || 'en';
      const window = this.getWindow();

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = `https://euc-widget.freshworks.com/widgets/${this.config.widgetId}.js`;

      window.fwSettings = {
        widget_id: this.config.widgetId,
        //https://developers.freshdesk.com/widget-api/#multilingual-support - only lowercases are working
        locale: lang.toLowerCase(),
      };

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      window.FreshworksWidget ||
        (function () {
          if ('function' != typeof window.FreshworksWidget) {
            const n: any = function () {
              // eslint-disable-next-line prefer-rest-params
              n['q'].push(arguments);
            };
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            (n['q'] = []), (window.FreshworksWidget = n);
          }
        })();

      this.translateLabels(lang);
      this.userService.currentUser.subscribe((user) => {
        this.FreshworksWidget('prefill', 'ticketForm', {
          name: `${user?.firstname} ${user?.lastname}`,
          email: user?.email,
        });
      });

      document.body.append(script);
    }
  }

  showTicketWidgetIconLauncher() {
    this.FreshworksWidget('show', 'launcher');
  }

  /* Hides freshdesk widget for ticket creation on page */
  hideTicketWidgetIconLauncher() {
    if (this.FreshworksWidget) {
      this.FreshworksWidget('hide', 'launcher');
    }
  }

  openTheWidget() {
    this.FreshworksWidget('open');
  }

  hideTheWidget() {
    this.FreshworksWidget('close');
  }

  destroyTheWidget() {
    this.FreshworksWidget('destroy');
  }

  private translateLabels(lang: string) {
    this.translateService
      .get([
        'freshdesk_widget.banner',
        'freshdesk_widget.title',
        'freshdesk_widget.submit',
        'freshdesk_widget.confirmation',
        'freshdesk_widget.launcher',
      ])
      .subscribe((data) => {
        const labels = {} as any;
        labels[lang] = {
          banner: data['freshdesk_widget.banner'],
          launcher: data['freshdesk_widget.launcher'],
          contact_form: {
            title: data['freshdesk_widget.title'],
            submit: data['freshdesk_widget.submit'],
            confirmation: data['freshdesk_widget.confirmation'],
          },
        };
        this.FreshworksWidget('setLabels', labels);
      });
  }

  getWindow(): any {
    return window;
  }

  get FreshworksWidget(): any {
    const window = this.getWindow();
    return window.FreshworksWidget;
  }
}
