import {
  Component,
  ComponentFactoryResolver,
  Injector,
  OnInit,
} from '@angular/core';
import { DsSidebarService } from '@design-system/feature/page';
import { TranslateService } from '@ngx-translate/core';
import { TooltipService } from './services/tooltip.service';

@Component({
  selector: 'bi-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private sidebarService: DsSidebarService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private tooltipService: TooltipService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.sidebarService.setContext(
      this.componentFactoryResolver,
      this.injector,
    );

    // This is needed if account is set to a language that is not
    // de or en (-> onLangChange is not triggered)
    this.translate.onDefaultLangChange.subscribe(() => {
      this.tooltipService.loadAll();
    });

    this.translate.onLangChange.subscribe(() => {
      this.tooltipService.loadAll();
    });
  }
}
