import { CommonModule } from '@angular/common';
import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedLibFeatDpaModule } from '@features/dpa';
import { OAuthModule, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { AuthGuard } from './guard/guard';
import { AuthTokenHelperService, OAuthCustomStorage } from './services';
import { UserService } from './user/user.service';
import { UserContextService } from './user/usercontext.service';
/**
 * Provides all necessary stuff for using clean authentication.
 * Should be only imported in the ROOT MODULE
 */
@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    SharedLibFeatDpaModule,
    OAuthModule.forRoot(),
  ],
})
export class SharedFeatAuthModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: SharedFeatAuthModule,
  ) {
    if (parentModule) {
      throw new Error(
        'PaldeskAuthModule is already loaded. Import it in the AppModule only',
      );
    }
  }

  static forRoot(): ModuleWithProviders<SharedFeatAuthModule> {
    return {
      ngModule: SharedFeatAuthModule,
      providers: [
        UserContextService,
        UserService,
        AuthGuard,
        OAuthService,
        AuthTokenHelperService,
        { provide: OAuthStorage, useClass: OAuthCustomStorage },
      ],
    };
  }
}
