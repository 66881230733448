import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';

interface Breadcrumb {
  label$?: Observable<string>;
  url: string;
}

@Component({
  selector: 'ds-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class DsBreadcrumbsComponent implements OnInit, OnDestroy {
  @Input() baseUrl: string;
  @Input() baseLabel: string;

  breadcrumbs: Breadcrumb[];

  private readonly destroy$ = new Subject<void>();

  constructor(
    public store: Store<any>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.updateBreadcrumbs();
      });
  }

  ngOnInit() {
    this.updateBreadcrumbs();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  updateBreadcrumbs() {
    this.breadcrumbs = this.buildBreadCrumb(
      this.store,
      this.activatedRoute.root,
      this.baseUrl,
      this.baseLabel ? [{ url: this.baseUrl, label$: of(this.baseLabel) }] : [],
    );
  }

  private buildBreadCrumb(
    store: Store<any>,
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: Array<Breadcrumb> = [],
  ): Array<Breadcrumb> {
    let newBreadcrumbs = breadcrumbs;
    let breadcrumb: Breadcrumb | null = null;

    // if route is not configured we don't add a breadcrumb
    if (route?.routeConfig?.data && route.routeConfig.data['breadcrumb']) {
      const bc = route.routeConfig.data['breadcrumb'];
      breadcrumb = {
        label$:
          typeof bc === 'string'
            ? of(bc)
            : this.store.select(bc).pipe(takeUntil(this.destroy$)),
        url: url + '/' + route.snapshot.url.join('/'),
      };
      newBreadcrumbs = [...breadcrumbs, breadcrumb];
    }

    // If we are not on our current path yet,
    // there will be more children to look after, to build our breadcumb
    if (route.firstChild) {
      return this.buildBreadCrumb(
        store,
        route.firstChild,
        breadcrumb ? breadcrumb.url : url,
        newBreadcrumbs,
      );
    }

    return newBreadcrumbs;
  }
}
