import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataCollectionService } from '../../../data-collection/data-collection.service';

@Component({
  selector: 'bi-cost-calculation',
  templateUrl: './cost-calculation.component.html',
  styleUrls: ['./cost-calculation.component.scss'],
})
export class CostCalculationComponent implements OnInit {
  @Input() showCosts;

  initial_costs: number;
  sum: number;
  techSavings: number;
  palfingerSavings: number;

  savings_complete: number;
  savings_complete_by_years: number;

  sliderVal: number;
  // lastSliderVal: number;

  // Slider options
  thumbLabel = true;
  autoTicks = false;
  disabled = false;
  invert = false;
  showTicks = true;
  max = 15;
  min = 1;
  step = 1;
  value: number;
  vertical = false;

  constructor(
    public translate: TranslateService,
    private dataCollectionService: DataCollectionService,
  ) {}

  ngOnInit() {
    this.sliderVal = this.dataCollectionService.selectedUsageTime;

    this.value = this.sliderVal;

    this.sum = this.dataCollectionService.selectedChartValues.value.costs.sum;
    this.techSavings = this.dataCollectionService.selectedChartValues.value.costs.techSavings;
    this.palfingerSavings = this.dataCollectionService.selectedChartValues.value.costs.palfingerSavings;

    this.initial_costs =
      this.dataCollectionService.selectedChartValues.value.costs.sum +
      this.dataCollectionService.selectedChartValues.value.costs.techSavings +
      this.dataCollectionService.selectedChartValues.value.costs
        .palfingerSavings;

    this.savings_complete =
      this.dataCollectionService.selectedChartValues.value.costs.techSavings +
      this.dataCollectionService.selectedChartValues.value.costs
        .palfingerSavings;

    this.savings_complete_by_years =
      this.dataCollectionService.selectedChartValues.value.costs.techSavings *
        this.sliderVal +
      this.dataCollectionService.selectedChartValues.value.costs
        .palfingerSavings *
        this.sliderVal;
  }

  onSliderChange(event: any) {
    this.sliderVal = event.value;
    this.dataCollectionService.storeSelectedUsageTime(this.sliderVal);

    this.savings_complete_by_years =
      this.dataCollectionService.selectedChartValues.value.costs.techSavings *
        this.sliderVal +
      this.dataCollectionService.selectedChartValues.value.costs
        .palfingerSavings *
        this.sliderVal;
  }
}
