<h1 fxLayout="row" fxLayoutAlign="space-between baseline">
  <span translate>DATA_COLLECTION.EXTENDED_INPUT.TITLE</span>
  <ds-page-actions>
    <button
      mat-button
      *dsPageAction
      type="button"
      (click)="resetExtendedValues()"
    >
      <mat-icon button-icon>restart_alt</mat-icon>
      <span translate>DATA_COLLECTION.EXTENDED_INPUT.RESET_TO_STANDARD</span>
    </button>
  </ds-page-actions>
</h1>

<br />
<div fxLayout="row wrap" [fxLayoutGap]="(1 | dsSpacing) + ' grid'">
  <div fxFlex="100%" fxFlex.gt-xs="50%" fxFlex.gt-md="25%">
    <mat-card class="h-100 full-height">
      <h4 translate>DATA_COLLECTION.EXTENDED_INPUT.COSTS.TITLE</h4>

      <mat-form-field
        class="full-width"
        *ngIf="
          standardValues.get('internal_working_hour') as internalWorkingHour
        "
      >
        <mat-label>{{
          'DATA_COLLECTION.EXTENDED_INPUT.COSTS.INTERNAL_WORKING_HOUR'
            | translate
        }}</mat-label>
        <input
          matInput
          type="number"
          [(ngModel)]="internalWorkingHour.amount"
          (ngModelChange)="standardValueChanged()"
        />
        <span matSuffix>{{ internalWorkingHour.unit }}</span>
      </mat-form-field>

      <mat-form-field
        class="full-width"
        *ngIf="
          standardValues.get('external_service_hour') as externalServiceHour
        "
      >
        <mat-label>{{
          'DATA_COLLECTION.EXTENDED_INPUT.COSTS.EXTERNAL_WORKING_HOUR'
            | translate
        }}</mat-label>
        <input
          matInput
          type="number"
          [(ngModel)]="externalServiceHour.amount"
          (ngModelChange)="standardValueChanged()"
        />
        <span matSuffix>{{ externalServiceHour.unit }}</span>
      </mat-form-field>

      <mat-form-field
        class="full-width"
        *ngIf="standardValues.get('price_diesel') as priceDiesel"
      >
        <mat-label>{{
          'DATA_COLLECTION.EXTENDED_INPUT.COSTS.PRICE_FOR_DIESEL' | translate
        }}</mat-label>
        <input
          matInput
          type="number"
          [(ngModel)]="priceDiesel.amount"
          (ngModelChange)="standardValueChanged()"
        />
        <span matSuffix>{{ priceDiesel.unit }}</span>
      </mat-form-field>

      <mat-form-field
        class="full-width"
        *ngIf="standardValues.get('price_hydraulic') as priceHydraulic"
      >
        <mat-label>{{
          'DATA_COLLECTION.EXTENDED_INPUT.COSTS.PRICE_FOR_HYDRAULIC_OIL'
            | translate
        }}</mat-label>
        <input
          matInput
          type="number"
          [(ngModel)]="priceHydraulic.amount"
          (ngModelChange)="standardValueChanged()"
        />
        <span matSuffix>{{ priceHydraulic.unit }}</span>
      </mat-form-field>

      <mat-form-field
        class="full-width"
        *ngIf="standardValues.get('price_lubricant') as priceLubricant"
      >
        <mat-label>{{
          'DATA_COLLECTION.EXTENDED_INPUT.COSTS.PRICE_FOR_LUBRICANT' | translate
        }}</mat-label>
        <input
          matInput
          type="number"
          [(ngModel)]="priceLubricant.amount"
          (ngModelChange)="standardValueChanged()"
        />
        <span matSuffix>{{ priceLubricant.unit }}</span>
      </mat-form-field>

      <mat-form-field
        class="full-width"
        *ngIf="standardValues.get('cO2_per_ton') as co2PerTon"
      >
        <mat-label>{{
          'DATA_COLLECTION.EXTENDED_INPUT.COSTS.PRICE_PER_TON_CO2' | translate
        }}</mat-label>
        <input
          matInput
          type="number"
          [(ngModel)]="co2PerTon.amount"
          (ngModelChange)="standardValueChanged()"
        />
        <span matSuffix class="suffix-with-tooltip"
          >{{ co2PerTon.unit }} <bi-tooltip [id]="10"></bi-tooltip
        ></span>
      </mat-form-field>
    </mat-card>
  </div>

  <div fxFlex="100%" fxFlex.gt-xs="50%" fxFlex.gt-md="25%">
    <mat-card class="h-100 full-height">
      <h4 translate>DATA_COLLECTION.EXTENDED_INPUT.FUEL_CONSUMPTION.TITLE</h4>

      <mat-form-field
        class="full-width"
        *ngIf="standardValues.get('fuel_ride') as fuelRide"
      >
        <mat-label>{{
          'DATA_COLLECTION.EXTENDED_INPUT.FUEL_CONSUMPTION.RIDE' | translate
        }}</mat-label>
        <input
          matInput
          type="number"
          [(ngModel)]="fuelRide.amount"
          (ngModelChange)="standardValueChanged()"
        />
        <span matSuffix class="suffix-with-tooltip"
          >{{ fuelRide.unit }}<bi-tooltip [id]="11"></bi-tooltip
        ></span>
      </mat-form-field>

      <mat-form-field
        class="full-width"
        *ngIf="standardValues.get('fuel_idle') as fuelIdle"
      >
        <mat-label>{{
          'DATA_COLLECTION.EXTENDED_INPUT.FUEL_CONSUMPTION.IDLE' | translate
        }}</mat-label>
        <input
          matInput
          type="number"
          placeholder="{{
            'DATA_COLLECTION.EXTENDED_INPUT.FUEL_CONSUMPTION.IDLE' | translate
          }}"
          [(ngModel)]="fuelIdle.amount"
          (ngModelChange)="standardValueChanged()"
        />
        <span matSuffix>{{ fuelIdle.unit }}</span>
      </mat-form-field>

      <mat-form-field
        class="full-width"
        *ngIf="standardValues.get('fuel_setup') as fuelSetup"
      >
        <mat-label>{{
          'DATA_COLLECTION.EXTENDED_INPUT.FUEL_CONSUMPTION.ACTIVITY_SETUP'
            | translate
        }}</mat-label>
        <input
          matInput
          type="number"
          placeholder="{{
            'DATA_COLLECTION.EXTENDED_INPUT.FUEL_CONSUMPTION.ACTIVITY_SETUP'
              | translate
          }}"
          [(ngModel)]="fuelSetup.amount"
          (ngModelChange)="standardValueChanged()"
        />
        <span matSuffix>{{ fuelSetup.unit }}</span>
      </mat-form-field>

      <mat-form-field
        class="full-width"
        *ngIf="standardValues.get('fuel_lift') as fuelLift"
      >
        <mat-label>{{
          'DATA_COLLECTION.EXTENDED_INPUT.FUEL_CONSUMPTION.ACTIVITY_LIFT'
            | translate
        }}</mat-label>
        <input
          matInput
          type="number"
          placeholder="{{
            'DATA_COLLECTION.EXTENDED_INPUT.FUEL_CONSUMPTION.ACTIVITY_LIFT'
              | translate
          }}"
          [(ngModel)]="fuelLift.amount"
          (ngModelChange)="standardValueChanged()"
        />
        <span matSuffix>{{ fuelLift.unit }}</span>
      </mat-form-field>
    </mat-card>
  </div>

  <div fxFlex="100%" fxFlex.gt-xs="50%" fxFlex.gt-md="25%">
    <mat-card class="h-100 full-height">
      <h4 translate>DATA_COLLECTION.EXTENDED_INPUT.RESOURCES.TITLE</h4>

      <mat-form-field
        class="full-width"
        *ngIf="standardValues.get('resources_hydraulic') as resourcesHydraulic"
      >
        <mat-label>{{
          'DATA_COLLECTION.EXTENDED_INPUT.RESOURCES.DEMAND_OF_HYDRAULIC_OIL'
            | translate
        }}</mat-label>
        <input
          matInput
          type="number"
          [(ngModel)]="resourcesHydraulic.amount"
          (ngModelChange)="standardValueChanged()"
        />
        <span matSuffix>{{ resourcesHydraulic.unit }}</span>
      </mat-form-field>

      <mat-form-field
        class="full-width"
        *ngIf="standardValues.get('resources_lubricant') as resourcesLubricant"
      >
        <mat-label>{{
          'DATA_COLLECTION.EXTENDED_INPUT.RESOURCES.DEMAND_OF_LUBRICANT'
            | translate
        }}</mat-label>
        <input
          matInput
          type="number"
          [(ngModel)]="resourcesLubricant.amount"
          (ngModelChange)="standardValueChanged()"
        />
        <span matSuffix>{{ resourcesLubricant.unit }}</span>
      </mat-form-field>
    </mat-card>
  </div>

  <div fxFlex="100%" fxFlex.gt-xs="50%" fxFlex.gt-md="25%">
    <mat-card class="h-100 full-height">
      <h4 translate>DATA_COLLECTION.EXTENDED_INPUT.MAINTENANCE.TITLE</h4>

      <mat-form-field
        class="full-width"
        *ngIf="standardValues.get('loss_of_earnings') as lossOfEarnings"
      >
        <mat-label>{{
          'DATA_COLLECTION.EXTENDED_INPUT.MAINTENANCE.LOSS_OF_EARNINGS'
            | translate
        }}</mat-label>
        <input
          matInput
          type="number"
          [(ngModel)]="lossOfEarnings.amount"
          (ngModelChange)="standardValueChanged()"
        />
        <span matSuffix>{{ lossOfEarnings.unit }}</span>
      </mat-form-field>

      <mat-form-field
        class="full-width"
        *ngIf="standardValues.get('maintenance_time') as maintenanceTime"
      >
        <mat-label>{{
          'DATA_COLLECTION.EXTENDED_INPUT.MAINTENANCE.MAINTENANCE_TIME'
            | translate
        }}</mat-label>
        <input
          matInput
          type="number"
          [(ngModel)]="maintenanceTime.amount"
          (ngModelChange)="standardValueChanged()"
        />
        <span matSuffix>{{ maintenanceTime.unit }}</span>
      </mat-form-field>

      <mat-form-field
        class="full-width"
        *ngIf="standardValues.get('service_time') as serviceTime"
      >
        <mat-label>{{
          'DATA_COLLECTION.EXTENDED_INPUT.MAINTENANCE.SERVICE_TIME' | translate
        }}</mat-label>
        <input
          matInput
          type="number"
          [(ngModel)]="serviceTime.amount"
          (ngModelChange)="standardValueChanged()"
        />
        <span matSuffix>{{ serviceTime.unit }}</span>
      </mat-form-field>
    </mat-card>
  </div>
</div>
