<ds-app-wrapper>
  <ds-breadcrumbs
    fxFlex="0 0 auto"
    baseUrl="/"
    baseLabel="Best Invest"
    class="print-hidden"
  >
  </ds-breadcrumbs>
  <div fxFlex="1 1 auto" class="flex-page-container" #routerContainer>
    <router-outlet></router-outlet>
  </div>
</ds-app-wrapper>
