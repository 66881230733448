import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SearchResult, SearchResultLink } from '@features/search';
import { SearchResultComponent } from '../shared/search-result/search-result.component';

@Component({
  selector: 'ds-spareparts',
  templateUrl: './spareparts.component.html',
  styleUrls: ['./spareparts.component.scss'],
})
export class SparepartsComponent extends SearchResultComponent {
  @Input() spareparts: SearchResult.Sparepart[];
  @Input() set viewAllByDefault(value: boolean) {
    this.viewAll = value;
  }
  @Input() paldeskBasePath: string;
  @Output() itemClick = new EventEmitter<SearchResultLink>();
}
