import {
  CollectionViewer,
  DataSource,
  isDataSource,
} from '@angular/cdk/collections';
import { CdkColumnDef } from '@angular/cdk/table';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { BehaviorSubject, isObservable, Observable, of, Subject } from 'rxjs';

@Component({
  selector: 'ds-alternative-table-renderer',
  templateUrl: './alternative-table-renderer.component.html',
  styleUrls: ['./alternative-table-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AlternativeTableRendererComponent
  implements CollectionViewer, OnDestroy, AfterContentInit
{
  /**
   * Stream containing the latest information on what rows are being displayed on screen.
   * Can be used by the data source to as a heuristic of what data should be provided.
   *
   */
  viewChange: BehaviorSubject<{
    start: number;
    end: number;
  }> = new BehaviorSubject<{ start: number; end: number }>({
    start: 0,
    end: Number.MAX_VALUE,
  });

  dataStream$?: Observable<any[] | ReadonlyArray<any>>;

  headerColumns: CdkColumnDef[];
  bodyColumns: CdkColumnDef[];
  footerColumns: CdkColumnDef[];

  @Input() set table(value: MatTable<any>) {
    if (value) {
      this._parseTable(value);
    }
  }
  @Input() sort: MatSort;

  private _destroy$ = new Subject<void>();

  ngAfterContentInit() {
    // table parsing might not have been possible on input
    // since columns not have been set
    if (!this.dataStream$ && this.table) {
      this._parseTable(this.table);
    }
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  private _parseTable(value: MatTable<any>) {
    const allColumns: CdkColumnDef[] = Array.from(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      value._columnDefsByName.values(),
    );

    if (allColumns) {
      const displayColumnNames = Array.from(
        value._contentRowDefs.first.columns,
      );
      const displayColumns = displayColumnNames
        .map((name) => allColumns.find((x) => x.name === name))
        .filter((x) => x !== undefined) as CdkColumnDef[];
      this._mapTemplates(displayColumns, value.dataSource);
    }
  }

  private _mapTemplates(
    columns: CdkColumnDef[],
    dataSource: readonly any[] | DataSource<any> | Observable<readonly any[]>,
  ) {
    if (columns.length > 0) {
      // sticky columns will be displayed in header
      this.headerColumns = columns.filter((x) => x.sticky === true);

      // if none was set to sticky we take the first
      if (this.headerColumns.length === 0) {
        this.headerColumns.push(columns[0]);
      }

      // stickyEnd columns will be display in footer
      this.footerColumns = columns.filter((x) => x.stickyEnd === true);

      // all other will be in body
      this.bodyColumns = columns.filter(
        (x) =>
          !this.headerColumns.includes(x) && !this.footerColumns.includes(x),
      );

      // parse data
      if (isDataSource(dataSource)) {
        this.dataStream$ = dataSource.connect(this);
      } else if (isObservable(dataSource)) {
        this.dataStream$ = dataSource;
      } else if (Array.isArray(dataSource)) {
        this.dataStream$ = of(dataSource);
      }
    }
  }
}
