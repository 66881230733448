<h4 class="uppercase">
  <small>{{ 'header.search.result_spareparts' | translate }}</small>
</h4>
<mat-action-list dense *ngIf="spareparts">
  <a
    mat-list-item
    [href]="paldeskBasePath + '/sparepart/' + sparepart.code"
    target="_blank"
    (click)="
      itemClick.emit({
        name: sparepart.code + ' | ' + sparepart.name,
        description: '',
        url: '/sparepart/' + sparepart.code
      })
    "
    *ngFor="let sparepart of viewAll ? spareparts : spareparts.slice(0, 3)"
  >
    {{ sparepart.code }} | {{ sparepart.name }}
  </a>
  <ds-view-toggle
    *ngIf="spareparts.length > 3"
    (click)="toggleViewAll()"
    [allVisible]="viewAll"
  ></ds-view-toggle>
</mat-action-list>
