import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SearchResult } from '../search.model';

export const SEARCH_SERVICE_BASE_PATH = new InjectionToken<string>(
  'SEARCH_SERVICE_BASE_PATH',
);

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(
    private http: HttpClient,
    @Inject(SEARCH_SERVICE_BASE_PATH) private basePath: string,
  ) {}
  /**
      * Retrieves search result
      * @param searchTerm
        @param scope equipment, product, document, sparepart, application
      */

  search(
    searchTerm: string,
    scope?: string,
  ): Observable<SearchResult.Container> {
    let params = new HttpParams().set('search_term', searchTerm.trim());
    if (scope) {
      params = params.set('scope', scope);
    }
    return this.http
      .get<SearchResult.Container>(this.basePath, { params })
      .pipe(tap((result) => this.applyEmptyFlag(result)));
  }

  /**
   *
   * Retrieves facette search result
   * @param searchTerm
   * @param document_class
   */
  getDocumentResult(
    searchTerm: string,
    document_class?: string,
    pageSize = 20,
    pageNumber = 0,
  ): Observable<SearchResult.DocumentResult> {
    let params = new HttpParams()
      .set('search_term', searchTerm.trim())
      .set('page_size', pageSize.toString())
      .set('page_number', pageNumber.toString());
    let url = this.basePath;
    if (document_class && document_class !== '') {
      url += '/documents';
      params = params.set('document_class', document_class);
    }
    return this.http.get<SearchResult.DocumentResult>(url, { params });
  }

  private applyEmptyFlag(data: SearchResult.Container) {
    if (
      (data.products && data.products.amount > 0) ||
      (data.equipments && data.equipments.length > 0) ||
      (data.spareparts && data.spareparts.length > 0) ||
      (data.applications && data.applications.length > 0) ||
      (data.documents && data.documents.amount > 0)
    ) {
      data.is_empty = false;
    } else {
      data.is_empty = true;
    }
  }
}
