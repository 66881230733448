<h1 mat-dialog-title translate>
  <mat-icon>warning</mat-icon> MODALS.ALERT_MISSING_DATA_SELECT.TITLE
</h1>

<p translate>MODALS.ALERT_MISSING_DATA_SELECT.TEXT</p>

<mat-dialog-actions>
  <button mat-button type="button" mat-dialog-close>
    {{ 'G.CLOSE' | translate }}
  </button>
</mat-dialog-actions>
