import { Injectable } from '@angular/core';
import { Adapter } from '../core/adapter';

export class StandardValue {
  constructor(
    public key: string,
    public name: string,
    public amount: number,
    public unit: string,
  ) {}
}

@Injectable({
  providedIn: 'root',
})
export class StandardValuesAdapter implements Object {
  mapToObject(map: Map<string, StandardValue>) {
    const object = Object.create(null);
    map.forEach((item, key) => {
      // Don't add the following keys
      if (
        ['working_hours_per_day', 'working_days_per_year'].includes(item.key)
      ) {
        return;
      }

      object[key] = {};
      const tempItem = { ...item };

      // Convert Percent to back to decimal values
      if (tempItem.unit === '%') {
        tempItem.amount /= 100;
      }

      Object.entries(tempItem).forEach(([itemKey, itemValue]) => {
        // Skip 'key' entry
        if (itemKey === 'key') {
          return;
        }
        // Add attribute to object
        object[key][itemKey] = itemValue;
      });
    });
    return object;
  }
}

@Injectable({
  providedIn: 'root',
})
export class StandardValueAdapter implements Adapter<StandardValue> {
  adapt(item: any): StandardValue {
    const itemKey = Object.keys(item)[0];

    // Ensure amount is a number
    item[itemKey].amount = parseFloat(item[itemKey].amount);

    // Convert Percent to readable values
    if (item[itemKey].unit === '%') {
      item[itemKey].amount *= 100;
    }

    return new StandardValue(
      itemKey,
      item[itemKey].name,
      item[itemKey].amount,
      item[itemKey].unit,
    );
  }
}
