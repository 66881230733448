import { InjectionToken } from '@angular/core';

export interface FreshdeskWebwidgetConfig {
  widgetId: number;
  locale?: string;
}

export const FreshdeskWebwidgetConfigToken = new InjectionToken<FreshdeskWebwidgetConfig>(
  'FreshdeskWebwidgetConfig',
);
