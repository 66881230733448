import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from '../../app-routing.module';
import { MaterialModule } from '../../material.module';
import { TooltipComponent } from '../../tooltip/tooltip.component';
@NgModule({
  declarations: [TooltipComponent],
  imports: [CommonModule, MaterialModule, AppRoutingModule, TranslateModule],
  exports: [
    CommonModule,
    MaterialModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipComponent,
  ],
})
export class GeneralModule {}
