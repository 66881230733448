import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { APPS } from '@config';
import { AuthGuard, AuthPermissions } from '@features/auth';
import { AdminSidebarComponent } from './admin/admin-sidebar/admin-sidebar.component';
import { AdminTooltipComponent } from './admin/admin-tooltip/admin-tooltip.component';
import { AdminComponent } from './admin/admin.component';
import { FeatureGroupsComponent } from './admin/feature-groups/feature-groups.component';
import { FeaturesComponent } from './admin/features/features.component';
import { HomeComponent } from './home/home.component';
import { CompactFeatureSelectionComponent } from './modules/compact/components/compact-feature-selection/compact-feature-selection.component';
import { EnvironmentalComponent } from './modules/compact/components/environmental/environmental.component';
import { ResultComponent } from './modules/compact/components/result/result.component';
import { CraneSelectComponent } from './modules/data-collection/components/crane-select/crane-select.component';
import { DataCollectionSidebarComponent } from './modules/data-collection/components/data-collection-sidebar/data-collection-sidebar.component';
import { DataCollectionComponent } from './modules/data-collection/components/data-collection/data-collection.component';
import { DataCollectionResolver } from './modules/data-collection/components/data-collection/data-collection.resolver';
import { DataInputComponent } from './modules/data-collection/components/data-input/data-input.component';
import { ExtendedInputComponent } from './modules/data-collection/components/extended-input/extended-input.component';
import { AvailableFeaturesResolver } from './modules/feature-selection/available-features.resovler';
import { FeatureSelectionComponent } from './modules/feature-selection/components/feature-selection/feature-selection.component';
import { FeatureSelectionSidebarComponent } from './modules/feature-selection/feature-selection-sidebar/feature-selection-sidebar.component';
import { FeaturesResolver } from './modules/feature-selection/features.resolver';
import { StoredFeaturesResolver } from './modules/feature-selection/storedFeatures.resolver';
import { ChartsComponent } from './modules/statistics/components/charts/charts.component';
import { CostsPerYearChartComponent } from './modules/statistics/components/charts/costs-per-year-chart/costs-per-year-chart.component';
import { CostsPerYearGreenhouseGasEmissionChartComponent } from './modules/statistics/components/charts/costs-per-year-greenhouse-gas-emission-chart/costs-per-year-greenhouse-gas-emission-chart.component';
import { CostsSavingsDurationOfUseChartComponent } from './modules/statistics/components/charts/costs-savings-duration-of-use-chart/costs-savings-duration-of-use-chart.component';
import { DieselPerYearChartComponent } from './modules/statistics/components/charts/diesel-per-year-chart/diesel-per-year-chart.component';
import { YearlyGreenhouseGasEmissionChartComponent } from './modules/statistics/components/charts/yearly-greenhouse-gas-emission-chart/yearly-greenhouse-gas-emission-chart.component';
import { YearlyNitrogenOxideEmissionChartComponent } from './modules/statistics/components/charts/yearly-nitrogen-oxide-emission-chart/yearly-nitrogen-oxide-emission-chart.component';
import { YearlyParticulateMatterEmissionChartComponent } from './modules/statistics/components/charts/yearly-particulate-matter-emission-chart/yearly-particulate-matter-emission-chart.component';
import { YearlySavingsPerFeatureChartComponent } from './modules/statistics/components/charts/yearly-savings-per-feature-chart/yearly-savings-per-feature-chart.component';
import { YearlyUsageCostsCraneChartComponent } from './modules/statistics/components/charts/yearly-usage-costs-crane-chart/yearly-usage-costs-crane-chart.component';
import { CostCalculationComponent } from './modules/statistics/components/cost-calculation/cost-calculation.component';
import { StatisticsSidebarComponent } from './modules/statistics/components/statistics/statistics-sidebar/statistics-sidebar.component';
import { StatisticsComponent } from './modules/statistics/components/statistics/statistics.component';
import { SidenavListComponent } from './navigation/sidenav-list/sidenav-list.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    data: {
      permissions: {
        roles: [APPS.BEST_INVEST.ROLES.USER, APPS.BEST_INVEST.ROLES.ADMIN],
      },
      sidebar: SidenavListComponent,
    },
    children: [
      { path: '', redirectTo: '/compact/result', pathMatch: 'full' },
      { path: 'user/oidc-callback', component: HomeComponent },
      {
        path: 'compact/result',
        component: ResultComponent,
        resolve: { data: StoredFeaturesResolver },
      },
      {
        path: 'compact/environmental',
        component: EnvironmentalComponent,
        resolve: { data: StoredFeaturesResolver },
      },
      {
        path: 'compact/feature-selection',
        component: CompactFeatureSelectionComponent,
        resolve: { data: AvailableFeaturesResolver },
      },
      {
        path: 'data-collection',
        pathMatch: 'full',
        redirectTo: 'data-collection/crane-select',
        resolve: { data: DataCollectionResolver },
      },
      {
        path: 'data-collection',
        component: DataCollectionComponent,
        resolve: {
          data: DataCollectionResolver,
        },
        data: {
          breadcrumb: 'Data Collection', // to-translate
          sidebar: DataCollectionSidebarComponent,
        },
        children: [
          {
            path: 'crane-select',
            component: CraneSelectComponent,
            data: {
              breadcrumb: 'Crane Selection', // to-translate
            },
          },
          {
            path: 'data-input',
            component: DataInputComponent,
            data: {
              breadcrumb: 'Data Input', // to-translate
            },
          },
          {
            path: 'extended-data-input',
            component: ExtendedInputComponent,
            data: {
              breadcrumb: 'Extended Data Input', // to-translate
            },
          },
        ],
      },
      {
        path: 'feature-selection',
        pathMatch: 'full',
        redirectTo: 'feature-selection/economics',
      },
      {
        path: 'feature-selection/:category',
        component: FeatureSelectionComponent,
        resolve: {
          data: FeaturesResolver,
        },
        data: {
          breadcrumb: 'Feature Selection', // to-translate
          sidebar: FeatureSelectionSidebarComponent,
        },
      },
      {
        path: 'statistics',
        pathMatch: 'full',
        redirectTo: 'statistics/cost-calculation',
      },
      {
        path: 'statistics',
        component: StatisticsComponent,
        resolve: {
          data: FeaturesResolver,
        },
        data: {
          breadcrumb: 'Statistics', // to-translate
          sidebar: StatisticsSidebarComponent,
        },
        children: [
          {
            path: 'cost-calculation',
            component: CostCalculationComponent,
            data: {
              breadcrumb: 'Cost Calculation', // to-translate
            },
          },
          {
            path: 'charts',
            component: ChartsComponent,
            data: {
              breadcrumb: 'Charts', // to-translate
            },
            children: [
              {
                path: 'costs-year',
                component: CostsPerYearChartComponent,
                data: {
                  breadcrumb: 'Yearly costs', // to-translate
                },
              },
              {
                path: 'costs-savings-dou',
                component: CostsSavingsDurationOfUseChartComponent,
                data: {
                  breadcrumb: 'Costs Savings', // to-translate
                },
              },
              {
                path: 'usage-costs-crane-year',
                component: YearlyUsageCostsCraneChartComponent,
                data: {
                  breadcrumb: 'Usage Costs', // to-translate
                },
              },
              {
                path: 'savings-feature-year',
                component: YearlySavingsPerFeatureChartComponent,
                data: {
                  breadcrumb: 'Savings Per Feature', // to-translate
                },
              },
              {
                path: 'diesel-year',
                component: DieselPerYearChartComponent,
                data: {
                  breadcrumb: 'Yearly Diesel', // to-translate
                },
              },
              {
                path: 'ghg-emission-year',
                component: YearlyGreenhouseGasEmissionChartComponent,
                data: {
                  breadcrumb: 'Yearly Greenhouse Gas Emissions', // to-translate
                },
              },
              {
                path: 'costs-year-ghg',
                component: CostsPerYearGreenhouseGasEmissionChartComponent,
                data: {
                  breadcrumb: 'Yearly Greenhouse Costs', // to-translate
                },
              },
              {
                path: 'no-emission-year',
                component: YearlyNitrogenOxideEmissionChartComponent,
                data: {
                  breadcrumb: 'Yearly Nitrogen Oxide Emissions', // to-translate
                },
              },
              {
                path: 'pm-emission-year',
                component: YearlyParticulateMatterEmissionChartComponent,
                data: {
                  breadcrumb: 'Yearly Particulate Matter Emissions', // to-translate
                },
              },
            ],
          },
        ],
      },
      {
        path: 'admin',
        component: AdminComponent,
        canActivate: [AuthGuard],
        data: {
          permissions: {
            roles: [APPS.BEST_INVEST.ROLES.ADMIN],
          } as AuthPermissions,
          breadcrumb: 'Administration', // to-translate
          sidebar: AdminSidebarComponent,
        },
        children: [
          {
            path: '',
            redirectTo: 'features',
            pathMatch: 'full',
          },
          {
            path: 'features',
            component: FeaturesComponent,
            data: {
              breadcrumb: 'Features', // to-translate
            },
          },
          {
            path: 'feature-groups',
            component: FeatureGroupsComponent,
            data: {
              breadcrumb: 'Features Groups', // to-translate
            },
          },
          {
            path: 'tooltips',
            component: AdminTooltipComponent,
            data: {
              breadcrumb: 'Tooltips', // to-translate
            },
          },
        ],
      },
    ],
  },
  {
    path: 'callback',
    children: [],
  },
  {
    path: 'special',
    loadChildren: () =>
      import('@design-system/feature/special-pages').then(
        (m) => m.DsSpecialPagesModule,
      ),
    data: {
      sidebar: SidenavListComponent,
    },
  },
  {
    path: '**',
    redirectTo: 'special',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    FeaturesResolver,
    DataCollectionResolver,
    AvailableFeaturesResolver,
    StoredFeaturesResolver,
  ],
})
export class AppRoutingModule {}
