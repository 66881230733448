<div fxLayout fxLayoutAlign="flex-end" [fxLayoutGap]="1 | dsSpacing" #container>
  <div #extra>
    <ng-content select="[slot='extra']"></ng-content>
  </div>
  <ng-container *ngFor="let item of listItems">
    <ng-template [ngTemplateOutlet]="item"></ng-template>
  </ng-container>
  <ng-container *ngIf="menuItems?.length && menuItems.length > 0">
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu
      #menu="matMenu"
      [xPosition]="'before'"
      class="ds-page-actions-menu"
    >
      <ng-container *ngFor="let item of menuItems">
        <ng-template [ngTemplateOutlet]="item"></ng-template>
      </ng-container>
    </mat-menu>
  </ng-container>
</div>
<div class="helper">
  <ng-content></ng-content>
</div>
