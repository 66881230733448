import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import {
  CraneSize,
  StandardValue,
  StandardValueAdapter,
  StandardValuesAdapter,
} from '../../classes';
import { BEST_INVEST_API_BASE_PATH } from '../../core/base-path-token';
import { AllResponse, StandardValuesResponse } from '../../core/jsonResponses';
import { LocalStorageService } from '../../services/local-storage.service';
import { DataCollectionService } from '../data-collection/data-collection.service';

@Injectable()
export class StoredFeaturesResolver implements Resolve<boolean> {
  standardValues: Map<string, StandardValue> = new Map();

  constructor(
    private dataCollectionService: DataCollectionService,
    private http: HttpClient,
    private standardValuesAdapter: StandardValuesAdapter,
    private standardValueAdapter: StandardValueAdapter,
    private router: Router,
    private localStorageService: LocalStorageService,
    @Inject(BEST_INVEST_API_BASE_PATH) private basePath: string,
  ) {}

  async resolve(): Promise<any> {
    const standardValues = this.dataCollectionService.standardValues;
    const craneSize = this.dataCollectionService.selectedCraneSize;
    const craneTechnology = this.dataCollectionService.selectedCraneTechnology;

    if (!craneSize || !craneTechnology || !standardValues) {
      this.router.navigate(['/', 'data-collection', 'crane-select']);
      return;
    }

    // Load stored selection
    const selectedCompactFeatures =
      <Array<number>>(
        this.localStorageService.deserialize('selectedCompactFeatures')
      ) || [];

    const params = {
      crane_size_id: craneSize.id,
      crane_technology_id: craneTechnology.id,
      standard_values: this.standardValuesAdapter.mapToObject(standardValues),
      selected_features: selectedCompactFeatures,
    };

    // get feature values
    const data = <AllResponse>(
      await this.http
        .post(this.basePath + '/compact/calculations/selected', params)
        .toPromise()
    );
    this.dataCollectionService.storeCalculatedFeatures(data.features, true);
    this.dataCollectionService.storeChartValues(data.charts, true);
    this.dataCollectionService.allFeaturesLoaded = false;

    const groupedFeatures = {};
    data.features.forEach((feature) => {
      groupedFeatures[feature.group] = groupedFeatures[feature.group] || [];
      groupedFeatures[feature.group].push(feature);
      groupedFeatures[feature.group].sort((a, b) => a.id - b.id);
    });

    return Promise.resolve({
      features: data.features,
      groupedFeatures,
    });
  }

  private deepClone(obj: any) {
    if (!obj || true === obj) {
      return obj;
    }
    const objType = typeof obj;
    if ('number' === objType || 'string' === objType) {
      return obj;
    }
    const result = Array.isArray(obj)
      ? []
      : !obj.constructor
      ? {}
      : new obj.constructor();
    if (obj instanceof Map) {
      obj.forEach((value: any, key: any) => {
        result.set(key, this.deepClone(obj.get(key)));
      });
    }
    for (const key2 in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key2)) {
        result[key2] = this.deepClone(obj[key2]);
      }
    }
    return result;
  }

  private async getStandardValues(
    craneSize: CraneSize,
  ): Promise<Map<string, StandardValue>> {
    const route = `/calculations/standard_values/${craneSize.id}`;
    const standardValues = new Map<string, StandardValue>();
    const res = <StandardValuesResponse>(
      await this.http.get(BEST_INVEST_API_BASE_PATH + route).toPromise()
    );
    res.standard_values.forEach((item) => {
      const standardValue = this.standardValueAdapter.adapt(item);
      standardValues.set(standardValue.key, standardValue);
    });
    return standardValues;
  }
}
