import { formatNumber } from '@angular/common';
import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'chart.js';
import { ChartDataObject } from '../../../../../classes';

// Stores shared data between chart and card.
export interface IChartSettings {
  label: string;
  value: number;
  color: string;
}

@Component({
  selector: 'bi-yearly-usage-costs-crane-chart',
  templateUrl: './yearly-usage-costs-crane-chart.component.html',
  styleUrls: ['./yearly-usage-costs-crane-chart.component.scss'],
})
export class YearlyUsageCostsCraneChartComponent implements AfterViewInit {
  @ViewChild('pieChart') private chartRef: any;
  @ViewChild('pieChartLegend') private chartLegendRef: any;

  @Input()
  set values(values: ChartDataObject) {
    this._values = values;
    this.setChartSettings();
  }
  get values(): ChartDataObject {
    return this._values;
  }

  public chartSettings: IChartSettings[];
  private _values: ChartDataObject;
  private pieChart: any;
  private chartOptions: any = {
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      mode: 'single',
      bodyFontSize: 15,
      xPadding: 20,
      yPadding: 10,
      custom: (tooltip: any) => {
        if (tooltip && tooltip.opacity === 0) {
          this.resetDefaultColors();
        }
      },
    },
  };

  constructor(public translate: TranslateService) {
    // Needs initialization in constructor for 'translate' to work.
    this.chartOptions.tooltips.callbacks = {
      label: function (tooltipItem: any, data: any) {
        // Format label
        const originalNumber: any = data.datasets[0].data[tooltipItem.index];
        const formattedNumber =
          formatNumber(originalNumber, translate.currentLang, '1.0-0') + ' €';

        return '  ' + data.labels[tooltipItem.index] + ': ' + formattedNumber;
      },
      title: function () {
        return '';
      }, // Hide title
    };

    this.chartOptions.legendCallback = function (chart: any) {
      const text: string[] = [];
      const data = chart.data;

      text.push('<ul>');
      for (let i = 0; i < data.datasets[0].data.length; i++) {
        const formattedNumber =
          formatNumber(
            data.datasets[0].data[i],
            translate.currentLang,
            '1.0-0',
          ) + ' €';

        text.push(
          '<li><span style="background-color:' +
            data.datasets[0].backgroundColor[i] +
            '"></span>',
        );
        text.push(data.labels[i] + ' <b>' + formattedNumber + '</b>');
        text.push('</li>');
      }
      text.push('</ul>');

      return text.join('');
    };
  }

  ngAfterViewInit() {
    this.pieChart = new Chart(this.chartRef.nativeElement, {
      type: 'pie',
      data: {
        labels: this.chartSettings.map((e) => e.label),
        datasets: [
          {
            backgroundColor: this.chartSettings.map((e) => e.color),
            data: this.chartSettings.map((e) => e.value),
          },
        ],
      },
      options: this.chartOptions,
    });

    this.chartLegendRef.nativeElement.innerHTML = this.pieChart.generateLegend();
  }

  // Prefills settings for usage in chart creation and for cards.
  setChartSettings() {
    // NOTE: we use translate.instant here since the charts library would cut off the labels on rendering.
    // This way we have the translations ready before they get cut off.
    this.chartSettings = [
      {
        label: this.translate.instant(
          'STATISTICS.CHARTS.YEARLY_USAGE_COSTS_CRANE.STAFF',
        ),
        value: this._values.staff,
        color: '#4472c4',
      },
      {
        label: this.translate.instant(
          'STATISTICS.CHARTS.YEARLY_USAGE_COSTS_CRANE.MAINTENANCE_INTERNAL',
        ),
        value: this._values.internal_maintenance,
        color: '#ed7d31',
      },
      {
        label: this.translate.instant(
          'STATISTICS.CHARTS.YEARLY_USAGE_COSTS_CRANE.MAINTENANCE_EXTERNAL',
        ),
        value: this._values.external_maintenance,
        color: '#a5a5a5',
      },
      {
        label: this.translate.instant(
          'STATISTICS.CHARTS.YEARLY_USAGE_COSTS_CRANE.LOSS_OF_EARNINGS',
        ),
        value: this._values.loss_of_earnings,
        color: '#ffc000',
      },
      {
        label: this.translate.instant(
          'STATISTICS.CHARTS.YEARLY_USAGE_COSTS_CRANE.DIESEL_ACTIVE',
        ),
        value: this._values.diesel_active,
        color: '#5b9bd5',
      },
      {
        label: this.translate.instant(
          'STATISTICS.CHARTS.YEARLY_USAGE_COSTS_CRANE.DIESEL_RIDE',
        ),
        value: this._values.diesel_ride,
        color: '#70ad47',
      },
      {
        label: this.translate.instant(
          'STATISTICS.CHARTS.YEARLY_USAGE_COSTS_CRANE.HYDRAULIC_OIL',
        ),
        value: this._values.resource_hydraulic,
        color: '#264477',
      },
      {
        label: this.translate.instant(
          'STATISTICS.CHARTS.YEARLY_USAGE_COSTS_CRANE.LUBRICANT',
        ),
        value: this._values.resource_lubricant,
        color: '#9e480e',
      },
    ];
  }

  // Shows a tooltip when clicking on a given element.
  showTooltip(index: number) {
    const segment = this.pieChart.getDatasetMeta(0).data[index];
    // Reset colors and assign hover color to active element.
    // @see Charts, core.helpers.js for color usage.
    this.resetDefaultColors();
    segment._model.backgroundColor = Chart.helpers
      .color(this.chartSettings[index].color)
      .saturate(0.5)
      .darken(0.1)
      .rgbString();
    // Show tooltip for active segment.
    this.pieChart.tooltip._active = [segment];
    this.pieChart.tooltip.update();
    this.pieChart.draw();
  }

  // Reset each segment to its original backgroundColor.
  resetDefaultColors() {
    for (let i = 0; i < this.pieChart.data.datasets.length; i++) {
      this.pieChart
        .getDatasetMeta(i)
        .data.forEach(function (ele: any, idx: number) {
          // eslint-disable-next-line no-self-assign
          ele._model.backgroundColor = ele._model['backgroundColor'];
        });
    }

    this.pieChart.update();
  }
}
