import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { Feature } from '../../classes';
import { BEST_INVEST_API_BASE_PATH } from '../../core/base-path-token';

@Injectable()
export class AvailableFeaturesResolver implements Resolve<boolean> {
  constructor(
    private http: HttpClient,
    @Inject(BEST_INVEST_API_BASE_PATH) private basePath: string,
  ) {}

  async resolve(): Promise<any> {
    const data = await firstValueFrom(
      this.http.get(this.basePath + '/compact/calculations/features'),
    );

    // Sort by group name ASC
    data['features'].sort((f1: Feature, f2: Feature) => {
      const group1 = f1.group.toLowerCase(),
        group2 = f2.group.toLowerCase();
      if (group1 < group2) {
        return -1;
      }
      if (group1 > group2) {
        return 1;
      }
      return 0;
    });
    return Promise.resolve(data['features']);
  }
}
