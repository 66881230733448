import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DeleteAccountPromptData {
  isEndCustomerAdmin: boolean;
  responsibilities: string[];
  comissions: string[];
  adminRoles: string[];
}

@Component({
  selector: 'ds-delete-account-dialog-prompt',
  templateUrl: './delete-account-prompt.component.html',
})
export class DeleteAccountPromptComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DeleteAccountPromptData) {}
}
