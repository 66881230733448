<mat-card *ngIf="data" class="no-padding" matSort matSortDisabled>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <ng-template
          *ngIf="headerColumns[0].cell"
          [ngTemplateOutlet]="headerColumns[0].cell.template"
          [ngTemplateOutletContext]="{ $implicit: data }"
        >
        </ng-template>
      </mat-panel-title>
      <mat-panel-description *ngIf="headerColumns.length > 1">
        <ng-container *ngFor="let columnDef of headerColumns; let i = index">
          <ng-template
            *ngIf="i > 0 && columnDef.cell"
            [ngTemplateOutlet]="columnDef.cell.template"
            [ngTemplateOutletContext]="{ $implicit: data }"
          >
          </ng-template>
        </ng-container>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <table list>
      <tr *ngFor="let columnDef of bodyColumns">
        <ng-template
          *ngIf="columnDef.headerCell"
          [ngTemplateOutlet]="columnDef.headerCell.template"
          [ngTemplateOutletContext]="{ $implicit: data }"
        >
        </ng-template>
        <ng-template
          *ngIf="columnDef.cell"
          [ngTemplateOutlet]="columnDef.cell.template"
          [ngTemplateOutletContext]="{ $implicit: data }"
        >
        </ng-template>
      </tr>
    </table>
  </mat-expansion-panel>
  <div class="footer-container">
    <div *ngFor="let columnDef of footerColumns" class="footer">
      <ng-template
        *ngIf="columnDef.cell"
        [ngTemplateOutlet]="columnDef.cell.template"
        [ngTemplateOutletContext]="{ $implicit: data }"
      >
      </ng-template>
    </div>
  </div>
</mat-card>
