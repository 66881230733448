<div fxLayout="column" [fxLayoutGap]="1 | dsSpacing" class="full-height">
  <h1 translate fxFlex="0 0 auto">ADMIN.SUB_NAV.FEATURE_GROUPS</h1>
  <ds-table fxFlex="0 0 auto">
    <table cdkDropList class="pd-table">
      <thead>
        <tr class="mat-header-row">
          <th class="mat-header-cell mat-table-sticky" translate>
            ADMIN.SUB_NAV.TOOLTIPS
          </th>
          <th
            class="mat-header-cell mat-action-cell mat-table-sticky"
            translate
          >
            ADMIN.OPTIONS
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="list-item" *ngFor="let tooltip of tooltips">
          <td>{{ tooltip.name || tooltip.description }}</td>
          <td class="mat-cell mat-action-cell mat-table-sticky">
            <button mat-button (click)="openDialog(tooltip)">
              {{ 'G.EDIT' | translate }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </ds-table>
</div>
