import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CraneSize } from '../../../../classes/crane-size.model';

@Component({
  selector: 'bi-crane-type',
  templateUrl: './crane-type.component.html',
  styleUrls: ['./crane-type.component.scss'],
})
export class CraneTypeComponent {
  @Input() cranes: Array<CraneSize>;
  @Input() selected: CraneSize;

  @Output() selectCraneSize: EventEmitter<CraneSize> = new EventEmitter();

  public onSelectChange(): void {
    this.selectCraneSize.emit(this.selected);
  }
}
