<ds-page-sidebar>
  <ds-page-sidebar-header>
    <mat-icon ds-page-sidebar-item-icon> timeline </mat-icon>
    <h4>Best Invest</h4>
  </ds-page-sidebar-header>

  <ds-page-sidebar-body>
    <!-- todo RouterLink for dashboard -->
    <!-- <ds-page-sidebar-item routerLinkActive="active">
      <mat-icon ds-page-sidebar-item-icon>dashboard</mat-icon>
      <span translate>NAV.DASHBOARD</span>
    </ds-page-sidebar-item> -->

    <!-- <ds-page-sidebar-item [routerLink]="['/']" routerLinkActive="active">
      <mat-icon ds-page-sidebar-item-icon>home</mat-icon>
      <span translate>NAV.HOME</span>
    </ds-page-sidebar-item> -->

    <!-- <ds-page-sidebar-item
      [routerLink]="['/data-collection']"
      routerLinkActive="active"
    >
      <mat-icon ds-page-sidebar-item-icon svgIcon="crane"></mat-icon>
      <span translate>NAV.DATA_COLLECTION</span>
    </ds-page-sidebar-item> -->

    <!-- <ds-page-sidebar-item
      [routerLink]="['/feature-selection']"
      routerLinkActive="active"
    >
      <mat-icon ds-page-sidebar-item-icon>library_add</mat-icon>
      <span translate>NAV.FEATURE_SELECTION</span>
    </ds-page-sidebar-item> -->

    <!-- <ds-page-sidebar-item
      [routerLink]="['/statistics']"
      routerLinkActive="active"
    >
      <mat-icon ds-page-sidebar-item-icon>graphic_eq</mat-icon>
      <span translate>NAV.STATISTICS</span>
    </ds-page-sidebar-item> -->

    <ds-page-sidebar-item
      [routerLink]="['/compact/result']"
      routerLinkActive="active"
    >
      <mat-icon ds-page-sidebar-item-icon>compare</mat-icon>
      <span translate>NAV.HOME</span>
    </ds-page-sidebar-item>

    <hr />

    <ds-page-sidebar-item
      [routerLink]="['/data-collection/crane-select']"
      routerLinkActive="active"
    >
      <mat-icon ds-page-sidebar-item-icon>compare</mat-icon>
      <span translate>NAV.DATA_COLLECTION</span>
    </ds-page-sidebar-item>

    <ds-page-sidebar-item
      [routerLink]="['/compact/feature-selection']"
      routerLinkActive="active"
    >
      <mat-icon ds-page-sidebar-item-icon>library_books</mat-icon>
      <span translate>NAV.FEATURE_SELECTION</span>
    </ds-page-sidebar-item>

    <ds-page-sidebar-item
      [routerLink]="['/compact/environmental']"
      routerLinkActive="active"
    >
      <mat-icon ds-page-sidebar-item-icon>cloud</mat-icon>
      <span translate>NAV.ENVIRONMENTAL_BENEFITS</span>
    </ds-page-sidebar-item>

    <ds-page-sidebar-item
      [routerLink]="['/admin']"
      routerLinkActive="active"
      *ngIf="isAdmin"
    >
      <mat-icon ds-page-sidebar-item-icon>language</mat-icon>
      <span translate>NAV.ADMIN</span>
    </ds-page-sidebar-item>
  </ds-page-sidebar-body>
</ds-page-sidebar>
