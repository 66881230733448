import { Component, Directive } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'ds-page-sidebar-body',
})
export class DsPageSidebarBodyDirective {}
@Component({
  selector: 'ds-page-sidebar',
  templateUrl: './page-sidebar.component.html',
  styleUrls: ['./page-sidebar.component.scss'],
})
export class DsPageSidebarComponent {}
