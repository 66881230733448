<h1 mat-dialog-title translate>
  <mat-icon>warning</mat-icon> MODALS.ALERT_RESETTING_DATA.TITLE
</h1>

<p translate>MODALS.ALERT_RESETTING_DATA.TEXT</p>

<mat-dialog-actions>
  <button mat-button type="button" mat-dialog-close="false">
    {{ 'G.NO' | translate }}
  </button>
  <button mat-button type="button" mat-dialog-close="true">
    {{ 'G.YES' | translate }}
  </button>
</mat-dialog-actions>
