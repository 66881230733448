<h1 translate>HOME.GREETING</h1>

<div fxLayout="row wrap" [fxLayoutGap]="(1 | dsSpacing) + ' grid'">
  <!-- Welcome text + pie chart -->
  <div>
    <mat-card>
      <mat-card-content>
        <div fxLayout="row">
          <div>
            <p [innerHTML]="'HOME.INTRO' | translate"></p>
            <p [innerHTML]="'HOME.INTRO_2' | translate"></p>
            <p>
              <small translate>HOME.CAPTION</small><br />
              <small translate>HOME.CAPTION_2</small>
            </p>

            <mat-card class="ribbon info-ribbon mt-5 crane-highlight">
              <div>
                <span translate>DATA_COLLECTION.CRANE_SELECT.SUBTITLE_SIZE</span
                >: {{ selectedCraneSize.name }}
                <bi-tooltip [id]="1"></bi-tooltip>
              </div>
              <div>
                <span translate
                  >DATA_COLLECTION.CRANE_SELECT.SUBTITLE_TECHNOLOGY</span
                >: {{ selectedCraneTechnology.definition }}
                <bi-tooltip [id]="2"></bi-tooltip>
              </div>
            </mat-card>
          </div>
          <div>
            <div class="row">
              <h2 class="lifecycle-title" translate>
                HOME.CHARTS.LIFECYCLE_COSTS.TITLE
              </h2>
              <div class="col-12 mb-3 col-md-6 mb-md-0">
                <canvas #pieChart id="pieChart" height="300"></canvas>
              </div>
              <div class="col-12 col-md-6 my-md-auto">
                <div
                  #pieChartLegend
                  id="pieChartLegend"
                  class="chart-legend d-flex justify-content-center justify-content-md-start"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <!-- Selected features -->
  <div fxFlex="100%">
    <mat-card class="col-12 rotate-padding">
      <table class="pd-table">
        <tr>
          <th class="text-right savings-title" colspan="2">
            <span translate>FEATURE_SELECTION.SAVINGS.YEARLY</span>
            <bi-tooltip [id]="3"></bi-tooltip>
          </th>
          <th class="text-center width-65">
            <div class="rotate">
              <bi-tooltip [id]="4"></bi-tooltip>
              <span translate>FEATURE_SELECTION.BENEFITS.SAFETY</span>
            </div>
            <mat-icon>verified_user</mat-icon>
          </th>
          <th class="text-center width-65">
            <div class="rotate">
              <bi-tooltip [id]="5"></bi-tooltip>
              <span translate>FEATURE_SELECTION.BENEFITS.RESALE_VALUE</span>
            </div>
            <mat-icon>euro</mat-icon>
          </th>
          <th class="text-center width-65">
            <div class="rotate">
              <bi-tooltip [id]="6"></bi-tooltip>
              <span translate>FEATURE_SELECTION.BENEFITS.AREA_OF_USE</span>
            </div>
            <mat-icon>extension</mat-icon>
          </th>
          <th class="text-center width-65">
            <div class="rotate">
              <bi-tooltip [id]="7"></bi-tooltip>
              <span translate
                >FEATURE_SELECTION.BENEFITS.OPTIMIZED_PERFORMANCE</span
              >
            </div>
            <mat-icon>speed</mat-icon>
          </th>
        </tr>
        <ng-container *ngFor="let featureGroup of groupedFeatures | keyvalue">
          <tr
            *ngFor="let feature of featureGroup.value"
            [ngClass]="{ 'not-available': feature.status === 'not_available' }"
          >
            <td>
              <span>{{ feature.name }}</span>
              <bi-tooltip
                [title]="feature.name"
                [description]="feature.description"
              ></bi-tooltip>
              <img
                src="/assets/logo.png"
                class="palfinger-icon"
                matTooltip="{{ 'FEATURE_SELECTION.PALFINGER_USP' | translate }}"
                matTooltipClass="tooltip-description"
                *ngIf="feature.is_palfinger"
              />
            </td>
            <td class="text-right">
              <span>€</span>
              {{
                feature.status === 'not_available'
                  ? '0'
                  : (feature.economics.annual_savings
                    | number: '1.0-0':this.translate.currentLang)
              }}
            </td>
            <td class="text-center">
              <mat-icon
                *ngIf="
                  feature.benefits.has_safety &&
                  feature.status !== 'not_available'
                "
                >check</mat-icon
              >
            </td>
            <td class="text-center">
              <mat-icon
                *ngIf="
                  feature.benefits.has_resale_value &&
                  feature.status !== 'not_available'
                "
                >check</mat-icon
              >
            </td>
            <td class="text-center">
              <mat-icon
                *ngIf="
                  feature.benefits.has_area_of_use &&
                  feature.status !== 'not_available'
                "
                >check</mat-icon
              >
            </td>
            <td class="text-center">
              <mat-icon
                *ngIf="
                  feature.benefits.has_optimized_performance &&
                  feature.status !== 'not_available'
                "
                >check</mat-icon
              >
            </td>
          </tr>
        </ng-container>
      </table>
    </mat-card>
  </div>

  <!-- Years slider -->
  <div fxFlex="100%">
    <mat-card class="text-center">
      <bi-cost-calculation [showCosts]="false"></bi-cost-calculation>
    </mat-card>
  </div>

  <!-- Savings chart -->
  <div fxFlex="100%">
    <mat-card>
      <mat-card-title
        ><span translate>STATISTICS.CHARTS.COSTS_PER_YEAR.TITLE</span>
        <bi-tooltip [id]="8"></bi-tooltip>
      </mat-card-title>
      <bi-costs-per-year-chart
        [values]="chartValues.costs"
        [noUSP]="true"
      ></bi-costs-per-year-chart>
    </mat-card>
  </div>

  <!-- Diesel chart -->
  <div fxFlex="100%">
    <mat-card *ngIf="chartValues.diesel.savings > 0">
      <mat-card-title
        ><span translate>STATISTICS.CHARTS.DIESEL_PER_YEAR.TITLE</span>
        <bi-tooltip [id]="9"></bi-tooltip>
      </mat-card-title>
      <bi-diesel-per-year-chart
        [values]="chartValues.diesel"
        [noUSP]="true"
      ></bi-diesel-per-year-chart>
    </mat-card>
  </div>

  <!-- Yearly usage chart -->
  <div fxFlex="100%">
    <mat-card>
      <mat-card-title translate
        >STATISTICS.CHARTS.YEARLY_USAGE_COSTS_CRANE.TITLE</mat-card-title
      >
      <bi-yearly-usage-costs-crane-chart
        [values]="chartValues.costs"
      ></bi-yearly-usage-costs-crane-chart>
    </mat-card>
  </div>
</div>
