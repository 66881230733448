import { Component, OnInit } from '@angular/core';
import { DataCollectionService } from '../../data-collection.service';

@Component({
  selector: 'bi-data-collection',
  templateUrl: './data-collection.component.html',
  styleUrls: ['./data-collection.component.scss'],
})
export class DataCollectionComponent implements OnInit {
  constructor(private dataCollectionService: DataCollectionService) {}

  ngOnInit() {
    this.dataCollectionService.storeCalculatedFeatures([]);
    this.dataCollectionService.storeChartValues(undefined);
    this.dataCollectionService.allFeaturesLoaded = true;
  }
}
