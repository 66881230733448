import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  Chart,
  CraneSize,
  CraneTechnology,
  Feature,
  StandardValue,
} from '../../classes';
import { LocalStorageService } from '../../services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class DataCollectionService {
  public allFeaturesLoaded = false;
  public calculatedFeatures: Observable<Feature[]>;
  public chartValues: Observable<Chart>;
  private _selectedCraneSize: CraneSize;
  private _selectedCraneTechnology: CraneTechnology;
  private _standardValues: Map<string, StandardValue> = new Map();
  private _calculatedFeatures = new BehaviorSubject<Feature[]>([]);
  private _chartValues = new BehaviorSubject<Chart>(new Chart());
  private _features: Feature[] = [];
  private _chart: Chart;
  private _selectedUsageTime = 1;

  constructor(private localStorageService: LocalStorageService) {
    this.calculatedFeatures = this._calculatedFeatures.asObservable();
    this.chartValues = this._chartValues.asObservable();

    const storedValues = <Map<string, StandardValue>>(
      this.localStorageService.deserialize('standardValues')
    );
    if (storedValues) {
      this._standardValues = storedValues;
    }
  }

  public storeSelectedUsageTime(val: number): void {
    this._selectedUsageTime = val;
  }
  public storeSelectedCraneSize(val: CraneSize): void {
    this._selectedCraneSize = val;
    this.localStorageService.serialize('craneSelect-size', val);
  }
  public storeSelectedCraneTechnology(val: CraneTechnology): void {
    this._selectedCraneTechnology = val;
    this.localStorageService.serialize('craneSelect-technology', val);
  }
  public storeStandardValues(val: Map<string, StandardValue>): void {
    this._standardValues = val;
    this.localStorageService.serialize('standardValues', val);
  }
  public storeCalculatedFeatures(
    val: Array<Feature>,
    onlySelected = false,
  ): void {
    this._features = val;
    this._calculatedFeatures.next(val);

    if (this._chart) {
      // update chart values when feature selection changes
      this.storeChartValues(this._chart, onlySelected);
    }
  }
  public storeChartValues(val: Chart | undefined, onlySelected = false): void {
    if (val === undefined) {
      return;
    }

    this._chart = val;
    val.costs.savings = this.sumOf(
      'economics',
      'annual_savings',
      false,
      onlySelected,
    );
    val.costs.palfingerSavings = this.sumOf(
      'economics',
      'annual_savings',
      true,
      onlySelected,
    );
    val.costs.techSavings = val.costs.savings - val.costs.palfingerSavings;

    val.diesel.savings = this.sumOf(
      'environment',
      'diesel_savings',
      false,
      onlySelected,
    );
    val.diesel.palfingerSavings = this.sumOf(
      'environment',
      'diesel_savings',
      true,
      onlySelected,
    );
    val.diesel.techSavings = val.diesel.savings - val.diesel.palfingerSavings;

    // Divide by 1000 to get tons
    val.thg.savings =
      this.sumOf('environment', 'thg_emission_savings', false, onlySelected) /
      1000;
    val.thg.palfingerSavings =
      this.sumOf('environment', 'thg_emission_savings', true, onlySelected) /
      1000;
    val.thg.techSavings = val.thg.savings - val.thg.palfingerSavings;

    // Multiply by tCO2cost to get €/ton of THG
    const tCO2cost = this._standardValues.get('cO2_per_ton')?.amount || 0;
    val.thg.savings_cost =
      (this.sumOf('environment', 'thg_emission_savings', false, onlySelected) /
        1000) *
      tCO2cost;
    val.thg.palfingerSavings_cost =
      (this.sumOf('environment', 'thg_emission_savings', true, onlySelected) /
        1000) *
      tCO2cost;
    val.thg.techSavings_cost =
      val.thg.savings_cost - val.thg.palfingerSavings_cost;

    val.nitrogen_oxide.savings = this.sumOf(
      'health',
      'nitrogen_oxide_savings',
      false,
      onlySelected,
    );
    val.nitrogen_oxide.palfingerSavings = this.sumOf(
      'health',
      'nitrogen_oxide_savings',
      true,
      onlySelected,
    );
    val.nitrogen_oxide.techSavings =
      val.nitrogen_oxide.savings - val.nitrogen_oxide.palfingerSavings;

    val.particulate_matter.savings = this.sumOf(
      'health',
      'particulate_matter_savings',
      false,
      onlySelected,
    );
    val.particulate_matter.palfingerSavings = this.sumOf(
      'health',
      'particulate_matter_savings',
      true,
    );
    val.particulate_matter.techSavings =
      val.particulate_matter.savings - val.particulate_matter.palfingerSavings;

    this._chartValues.next(val);
  }

  public get selectedChartValues(): BehaviorSubject<Chart> {
    return this._chartValues;
  }
  public get selectedUsageTime(): number {
    return this._selectedUsageTime;
  }
  public get selectedCraneSize(): CraneSize {
    const storedSize = <CraneSize>(
      this.localStorageService.deserialize('craneSelect-size')
    );
    if (!this._selectedCraneSize && storedSize) {
      this._selectedCraneSize = storedSize;
    }
    return this._selectedCraneSize;
  }
  public get selectedCraneTechnology(): CraneTechnology {
    const storedTechnology = <CraneTechnology>(
      this.localStorageService.deserialize('craneSelect-technology')
    );
    if (!this._selectedCraneTechnology && storedTechnology) {
      this._selectedCraneTechnology = storedTechnology;
    }
    return this._selectedCraneTechnology;
  }
  public get standardValues(): Map<string, StandardValue> {
    return this._standardValues;
  }

  /**
   * Sums up of value of given type inside category, depending if
   * - feature is available
   * - feature is add-on and selected
   * @param categoryValue     (e.g. 'annual_savings')
   * @param isPalfinger=false sum-up palfinger features only
   */
  public sumOf(
    category: string,
    categoryValue: string,
    isPalfinger = false,
    onlySelected = false,
  ): number {
    let storedSelectedFeatureIDs: Array<number> = [];
    if (onlySelected) {
      storedSelectedFeatureIDs =
        <Array<number>>(
          this.localStorageService.deserialize('selectedCompactFeatures')
        ) || [];
    }

    return this._features.reduce((accumulator, feature) => {
      const value = feature[category][categoryValue];

      // Don't add value when not available
      if (feature.status === 'not_available') {
        return accumulator;
      }

      if (onlySelected) {
        // Add value when selected
        return this.checkSelectedFeatures(
          feature,
          accumulator,
          isPalfinger,
          value,
          storedSelectedFeatureIDs,
        );
      } else {
        return this.checkAllFeatures(feature, accumulator, isPalfinger, value);
      }
    }, 0);
  }

  private checkAllFeatures(
    feature: Feature,
    accumulator: number,
    isPalfinger: boolean,
    value: number,
  ) {
    // Don't add value if add_on and not selected
    if (feature.status === 'add_on' && !feature.selected) {
      return accumulator;
    }

    // Don't add value if not palfinger feature
    if (isPalfinger && !feature.is_palfinger) {
      return accumulator;
    }

    return accumulator + value;
  }

  private checkSelectedFeatures(
    feature: Feature,
    accumulator: number,
    isPalfinger: boolean,
    value: number,
    storedSelectedFeatureIDs: Array<number>,
  ) {
    if (storedSelectedFeatureIDs.includes(feature.id)) {
      if (isPalfinger && !feature.is_palfinger) {
        return accumulator;
      } else {
        return accumulator + value;
      }
    }
    return accumulator;
  }
}
