import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { APPS } from '@config';
import { UserService } from '@features/auth';

@Component({
  selector: 'bi-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.scss'],
})
export class SidenavListComponent {
  isAdmin = false;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private userService: UserService,
  ) {
    this.isAdmin = this.userService.hasRole(APPS.BEST_INVEST.ROLES.ADMIN);
    this.matIconRegistry.addSvgIcon(
      'crane',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/icons/spr_icon_crane.svg',
      ),
    );
  }
}
