<ds-page-sidebar>
  <ds-page-sidebar-header>
    <mat-icon ds-page-sidebar-item-icon svgIcon="crane"></mat-icon>
    <h4 translate>NAV.DATA_COLLECTION</h4>
  </ds-page-sidebar-header>

  <ds-page-sidebar-body>
    <ds-page-sidebar-item
      [routerLink]="isDisabled ? undefined : ['/data-collection/crane-select']"
      routerLinkActive="active"
      [class.disabled]="isDisabled"
    >
      <mat-icon ds-page-sidebar-item-icon>list</mat-icon>
      <span translate>DATA_COLLECTION.CRANE_SELECT.TITLE</span>
    </ds-page-sidebar-item>

    <ds-page-sidebar-item
      [routerLink]="isDisabled ? undefined : ['/data-collection/data-input']"
      routerLinkActive="active"
      [class.disabled]="isDisabled"
    >
      <mat-icon ds-page-sidebar-item-icon>edit</mat-icon>
      <span translate>DATA_COLLECTION.DATA_INPUT.TITLE</span>
    </ds-page-sidebar-item>

    <ds-page-sidebar-item
      [routerLink]="
        isDisabled ? undefined : ['/data-collection/extended-data-input']
      "
      routerLinkActive="active"
      [class.disabled]="isDisabled"
    >
      <mat-icon ds-page-sidebar-item-icon>expand_more</mat-icon>
      <span translate>DATA_COLLECTION.EXTENDED_INPUT.TITLE</span>
    </ds-page-sidebar-item>
  </ds-page-sidebar-body>
</ds-page-sidebar>
