<div
  class="{{ noColor ? '' : type + '-text' }}"
  fxLayout
  fxLayoutAlign="start center"
  [fxLayoutGap]="0.25 | dsSpacing"
  data-test="wrapper"
>
  <mat-icon
    [ngStyle]="
      iconSize
        ? { width: iconSize, height: iconSize, 'font-size': iconSize }
        : {}
    "
    data-test="icon"
    >{{ type === 'success' ? 'check_circle' : type }}</mat-icon
  >
  <span><ng-content></ng-content></span>
</div>
