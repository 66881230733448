export class ChartDataObject {
  constructor(
    // Server calc
    public sum: number = 0,
    public diesel_active: number = 0,
    public diesel_ride: number = 0,
    public external_maintenance: number = 0,
    public internal_maintenance: number = 0,
    public loss_of_earnings: number = 0,
    public resource_hydraulic: number = 0,
    public resource_lubricant: number = 0,
    public staff: number = 0,

    public savings: number = 0, // calculated in frontend
    public techSavings: number = 0, // calculated in frontend
    public palfingerSavings: number = 0, // calculated in frontend

    // Used to calculate yearly costs per ton of THG
    public savings_cost: number = 0, // calculated in frontend
    public techSavings_cost: number = 0, // calculated in frontend
    public palfingerSavings_cost: number = 0, // calculated in frontend
  ) {}

  public getValues() {
    return {
      data: {
        'Diesel (Betrieb)': this.diesel_active,
        'Diesel (Fahrt)': this.diesel_ride,
        'Wartung (extern)': this.external_maintenance,
        'Wartung (intern)': this.internal_maintenance,
        'Verdienstentgang (Wartungszeit)': this.loss_of_earnings,
        Hydrauliköl: this.resource_hydraulic,
        Schmiermittel: this.resource_lubricant,
        'Personal (Betrieb)': this.staff,
      },
    };
  }
}

export class Chart {
  constructor(
    public costs: ChartDataObject = new ChartDataObject(),
    public diesel: ChartDataObject = new ChartDataObject(),
    public nitrogen_oxide: ChartDataObject = new ChartDataObject(),
    public particulate_matter: ChartDataObject = new ChartDataObject(),
    public thg: ChartDataObject = new ChartDataObject(),
  ) {}
}
