<div>
  <canvas #barChart id="barChart" height="500"></canvas>
</div>

<div class="card-container">
  <div *ngFor="let entry of chartSettings; let i = index" class="chart-card">
    <div class="title-card" translate>{{ entry.name }}</div>
    <div class="value-card">
      {{ entry.value | number: '1.0-0':this.translate.currentLang || 'en' }} €
    </div>
    <div class="title-card">
      {{ entry.percent | number: '1.0-0':this.translate.currentLang || 'en' }} %
    </div>
    <div
      class="color-tag-card"
      [ngStyle]="{ 'background-color': entry.color }"
    ></div>
  </div>
</div>
