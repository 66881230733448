import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsFilterModule } from '@design-system/components/filter';
import { DsLoadingModule } from '@design-system/components/loading';
import {
  DsTableLoadingModule,
  DsTableModule,
} from '@design-system/components/table';
import { DsPageModule } from '@design-system/feature/page';
import { TranslateModule } from '@ngx-translate/core';
import { GeneralModule } from '../general/general.module';
import { FeatureSelectionComponent } from './components/feature-selection/feature-selection.component';
import { FeatureSelectionSidebarComponent } from './feature-selection-sidebar/feature-selection-sidebar.component';

@NgModule({
  declarations: [FeatureSelectionComponent, FeatureSelectionSidebarComponent],
  imports: [
    GeneralModule,
    TranslateModule,
    FlexLayoutModule,
    // Design System
    DsPageModule,
    DsFilterModule,
    DsTableModule,
    DsTableLoadingModule,
    DsSpacingModule,
    DsLoadingModule,
  ],
  exports: [FeatureSelectionComponent],
})
export class FeatureSelectionModule {}
