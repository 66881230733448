import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'bi-alert-feature-selection-loss-modal',
  templateUrl: './alert-feature-selection-loss-modal.component.html',
  styleUrls: ['./alert-feature-selection-loss-modal.component.scss'],
})
export class AlertFeatureSelectionLossModalComponent {
  constructor(
    public dialogRef: MatDialogRef<AlertFeatureSelectionLossModalComponent>,
  ) {}
}
