import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

export class PaginatorI18n {
  constructor(private readonly translate: TranslateService) {}

  getIntl(): MatPaginatorIntl {
    const paginatorIntl = new MatPaginatorIntl();
    this.translate
      .get([
        'general.pageinator.items_per_page_label',
        'general.pageinator.next_page_label',
        'general.pageinator.previos_page_label',
        'general.pageinator.first_page_label',
        'general.pageinator.last_page_label',
      ])
      .subscribe((res) => {
        paginatorIntl.itemsPerPageLabel =
          res['general.pageinator.items_per_page_label'];
        paginatorIntl.nextPageLabel = res['general.pageinator.next_page_label'];
        paginatorIntl.previousPageLabel =
          res['general.pageinator.previos_page_label'];
        paginatorIntl.firstPageLabel =
          res['general.pageinator.first_page_label'];
        paginatorIntl.lastPageLabel = res['general.pageinator.last_page_label'];
      });

    paginatorIntl.getRangeLabel = this.getRangeLabel.bind(this);
    return paginatorIntl;
  }

  private getRangeLabel(
    page: number,
    pageSize: number,
    length: number,
  ): string {
    if (length === 0 || pageSize === 0) {
      return this.translate.instant('general.pageinator.range_page_label_one', {
        length,
      });
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
    return this.translate.instant('general.pageinator.range_page_label_two', {
      startIndex: startIndex + 1,
      endIndex,
      length,
    });
  }
}
