<h4 class="uppercase">
  <small>{{ 'header.search.result_equipments' | translate }}</small>
</h4>
<mat-action-list dense *ngIf="equipments">
  <a
    mat-list-item
    [href]="
      paldeskBasePath +
      '/equipment/' +
      equipment.equipment_number +
      '?serial_number=' +
      equipment.serial_number
    "
    (click)="
      itemClick.emit({
        name:
          equipment.equipment_number +
          ' ' +
          equipment.serial_number +
          equipment.product_name
            ? ' / ' + equipment.product_name
            : '',
        description: '',
        url:
          '/equipment/' +
          equipment.equipment_number +
          '?serial_number=' +
          equipment.serial_number
      })
    "
    target="_blank"
    *ngFor="let equipment of viewAll ? equipments : equipments.slice(0, 3)"
  >
    {{ equipment.equipment_number }} ({{ equipment.serial_number }})
    <span *ngIf="equipment.product_name"> / {{ equipment.product_name }}</span>
  </a>
  <ds-view-toggle
    *ngIf="equipments.length > 3"
    (click)="toggleViewAll()"
    [allVisible]="viewAll"
  >
  </ds-view-toggle>
</mat-action-list>
