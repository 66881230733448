import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Resolve, Router } from '@angular/router';
import { StandardValuesAdapter } from '../../classes';
import { BEST_INVEST_API_BASE_PATH } from '../../core/base-path-token';
import { AllResponse } from '../../core/jsonResponses';
import { AlertMissingDataSelectModalComponent } from '../../modals/alert-missing-data-select-modal/alert-missing-data-select-modal.component';
import { DataCollectionService } from '../data-collection/data-collection.service';

@Injectable()
export class FeaturesResolver implements Resolve<boolean> {
  constructor(
    private dataCollectionService: DataCollectionService,
    private router: Router,
    private dialog: MatDialog,
    private http: HttpClient,
    private standardValuesAdapter: StandardValuesAdapter,
    @Inject(BEST_INVEST_API_BASE_PATH) private basePath: string, // private tokenService: AngularTokenService
  ) {}

  resolve(): boolean | Promise<boolean> {
    if (
      !this.dataCollectionService.selectedCraneSize ||
      !this.dataCollectionService.selectedCraneTechnology
    ) {
      this.openDialog();
      this.router.navigate(['/data-collection']);
      return false;
    }
    // Only recalculate if values have been resetted
    if (!this.dataCollectionService.allFeaturesLoaded) {
      return new Promise((resolve) => {
        const standardValueCopy = this.deepClone(
          this.dataCollectionService.standardValues,
        );

        const params = {
          crane_size_id: this.dataCollectionService.selectedCraneSize.id,
          crane_technology_id: this.dataCollectionService
            .selectedCraneTechnology.id,
          standard_values: this.standardValuesAdapter.mapToObject(
            standardValueCopy,
          ),
        };

        // get feature values
        this.http
          .post<AllResponse>(this.basePath + '/calculations/all', params)
          .subscribe(
            (data: AllResponse) => {
              this.dataCollectionService.storeCalculatedFeatures(data.features);
              this.dataCollectionService.storeChartValues(data.charts);
              this.dataCollectionService.allFeaturesLoaded = true;
              resolve(true);
            },
            (err) => console.warn(err),
          );
      });
    } else {
      return true;
    }
  }

  public async openDialog() {
    this.dialog.open(AlertMissingDataSelectModalComponent, {
      width: '30%',
    });
  }

  private deepClone(obj: any) {
    if (!obj || true === obj) {
      return obj;
    }
    const objType = typeof obj;
    if ('number' === objType || 'string' === objType) {
      return obj;
    }
    const result = Array.isArray(obj)
      ? []
      : !obj.constructor
      ? {}
      : new obj.constructor();
    if (obj instanceof Map) {
      obj.forEach((value: any, key: any) => {
        result.set(key, this.deepClone(obj.get(key)));
      });
    }
    for (const key2 in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key2)) {
        result[key2] = this.deepClone(obj[key2]);
      }
    }
    return result;
  }
}
