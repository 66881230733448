<div fxLayout="row" fxLayoutAlign="center center" class="full-width">
  <div *ngIf="showCosts" fxFlex="0 0 auto">
    <h1 translate>STATISTICS.SUB_NAV.COSTS</h1>
    <table>
      <tr>
        <td translate>STATISTICS.COST_CALCULATION.ANNUAL_COSTS_OLD_CRANE</td>
        <td class="text-center min-width-cell">
          {{ initial_costs | number: '1.0-0':this.translate.currentLang }}
          €
        </td>
      </tr>
      <tr class="no-border-bottom">
        <td translate>
          STATISTICS.COST_CALCULATION.SAVINGS_NEW_CRANE
          <span>-</span>
        </td>
        <td class="text-center min-width-cell">
          {{ techSavings | number: '1.0-0':this.translate.currentLang }}
          €
        </td>
      </tr>
      <tr>
        <td translate>
          STATISTICS.COST_CALCULATION.SAVINGS_PALFINGER_USP
          <span>-</span>
        </td>
        <td class="text-center min-width-cell">
          {{ palfingerSavings | number: '1.0-0':this.translate.currentLang }}
          €
        </td>
      </tr>
      <tr class="no-border-bottom">
        <td translate>STATISTICS.COST_CALCULATION.ANNUAL_COSTS</td>
        <td class="min-width-cell">
          <div class="sum error">
            {{ sum | number: '1.0-0':this.translate.currentLang }} €
          </div>
        </td>
      </tr>
    </table>
  </div>

  <div fxFlex="0 0 auto">
    <h3 translate>STATISTICS.COST_CALCULATION.SAVINGS</h3>

    <table>
      <tr class="no-border-bottom">
        <td translate>STATISTICS.COST_CALCULATION.ANNUAL_TOTAL_SAVINGS</td>
        <td class="min-width-cell">
          <div class="sum success">
            {{ savings_complete | number: '1.0-0':this.translate.currentLang }}
            €
          </div>
        </td>
      </tr>
      <tr class="no-border-bottom">
        <td>
          <div fxLayout fxLayoutAlign="start center">
            <span translate>
              STATISTICS.COST_CALCULATION.SAVINGS_IN_X_YEARS
              <strong>({{ sliderVal }})</strong>
            </span>
            <mat-slider
              (change)="onSliderChange($event)"
              class="example-margin"
              [disabled]="disabled"
              [invert]="invert"
              [max]="max"
              [min]="min"
              [value]="value"
              [step]="step"
              [thumbLabel]="thumbLabel"
              [(ngModel)]="value"
              [vertical]="vertical"
            >
            </mat-slider>
          </div>
        </td>
        <td class="min-width-cell">
          <div class="sum success">
            {{
              savings_complete_by_years
                | number: '1.0-0':this.translate.currentLang
            }}
            €
          </div>
        </td>
      </tr>
    </table>
  </div>
</div>
