import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LocaleInterceptor implements HttpInterceptor {
  constructor(private translate: TranslateService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const newConfig = {
      url: req.url,
    };

    if (this.translate.currentLang) {
      newConfig['headers'] = req.headers.set(
        'locale',
        this.translate.currentLang,
      );
    }

    const newReq = req.clone(newConfig);
    return next.handle(newReq);
  }
}
