import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IFeatureEdit } from '../../admin/features/features.component';
import { TooltipService } from '../../services/tooltip.service';

@Component({
  selector: 'bi-edit-tooltip-modal',
  templateUrl: './edit-tooltip-modal.component.html',
  styleUrls: ['./edit-tooltip-modal.component.scss'],
})
export class EditToolTipModalComponent implements OnInit {
  public editData: IFeatureEdit;

  constructor(
    private tooltipService: TooltipService,
    public dialogRef: MatDialogRef<EditToolTipModalComponent>,
    @Inject(MAT_DIALOG_DATA) private id: number,
  ) {}

  async ngOnInit() {
    this.editData = await this.tooltipService.edit(this.id);
  }

  public async update(form: NgForm, id: number) {
    await this.tooltipService.update(form.value, id);

    this.dialogRef.close();
  }
}
