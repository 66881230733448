<div fxLayout="column" class="container full-height" fxLayout.xs="row">
  <div fxFlex="0 0 auto" fxLayout.xs="row">
    <a
      [href]="paldeskBasePath"
      class="active"
      mat-button
      fxHide.xs
      data-cy="home-button"
    >
      <mat-icon>home</mat-icon>
    </a>
    <button
      data-cy="menu-button"
      class="active"
      mat-button
      fxHide.gt-xs
      (click)="sidebarClick()"
      *ngIf="hasSidebar"
    >
      <mat-icon>menu</mat-icon>
    </button>
    <button
      *ngIf="
        userService?.isAuthorized === true &&
        userService?.userContext &&
        userService.userContext.partnertype <= 4
      "
      mat-button
      (click)="searchClick()"
    >
      <mat-icon>search</mat-icon>
    </button>
    <button
      data-cy="apps-button"
      *ngIf="userService?.isAuthorized === true && userService?.userContext"
      mat-button
      (click)="appsClick()"
    >
      <mat-icon>apps</mat-icon>
    </button>
  </div>
  <div
    fxFlex="1 1 auto"
    class="scroll-container-vertical hidden-scollbar"
  ></div>
  <div fxFlex="0 0 auto" fxLayout.xs="row">
    <div [fxHide.xs]="collapseMenu">
      <a
        data-cy="freshDesk-button"
        mat-button
        *ngIf="userService?.isAuthorized && userService?.userContext"
        (click)="showFreshdeskWidget()"
        title="{{ 'freshdesk_widget.title' | translate }}"
      >
        <mat-icon>forum</mat-icon>
      </a>
      <a
        data-cy="feedback-button"
        mat-button
        *ngIf="fastFeedbackAppId"
        (click)="openFeedbackDialog()"
        title="{{ 'header.feedback.submit_feedback' | translate }}"
      >
        <mat-icon>insert_emoticon</mat-icon>
      </a>
      <ng-container *ngIf="helpIconUrl">
        <a
          *ngIf="!helpOpenSameWindow; else internalLink"
          [href]="helpIconUrl"
          target="_blank"
          mat-button
        >
          <mat-icon>help</mat-icon>
        </a>
        <ng-template #internalLink>
          <a [routerLink]="helpIconUrl" mat-button data-cy="help-button">
            <mat-icon>help</mat-icon>
          </a>
        </ng-template>
      </ng-container>
      <button
        mat-button
        (click)="accountClick()"
        *ngIf="!hasNoLogIn"
        data-cy="profile-button"
      >
        <mat-icon>account_circle</mat-icon>
      </button>
    </div>
    <button
      mat-button
      [matMenuTriggerFor]="menu"
      [fxShow.xs]="collapseMenu"
      fxHide.gt-xs
    >
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu" [xPosition]="'before'">
      <a
        *ngIf="userService?.isAuthorized && userService?.userContext"
        data-cy="freshDesk-button"
        (click)="showFreshdeskWidget()"
        mat-menu-item
      >
        <mat-icon>forum</mat-icon>
        {{ 'freshdesk_widget.title' | translate }}
      </a>
      <a
        data-cy="feedback-button"
        mat-menu-item
        *ngIf="fastFeedbackAppId"
        (click)="openFeedbackDialog()"
      >
        <mat-icon>insert_emoticon</mat-icon>
        {{ 'header.feedback.submit_feedback' | translate }}
      </a>
      <ng-container *ngIf="helpIconUrl">
        <a
          *ngIf="!helpOpenSameWindow; else internalLink"
          [href]="helpIconUrl"
          target="_blank"
          mat-menu-item
          data-cy="help-button"
        >
          <mat-icon>help</mat-icon>
          {{ 'general.help' | translate }}
        </a>
        <ng-template #internalLink>
          <a [routerLink]="helpIconUrl" mat-menu-item data-cy="help-button">
            <mat-icon>help</mat-icon>
            {{ 'general.help' | translate }}
          </a>
        </ng-template>
      </ng-container>
      <button
        mat-menu-item
        (click)="accountClick()"
        *ngIf="!hasNoLogIn"
        data-cy="profile-button"
      >
        <mat-icon>account_circle</mat-icon>
        {{ 'general.profile' | translate }}
      </button>
    </mat-menu>
  </div>
</div>
