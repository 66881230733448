import { ComponentType } from '@angular/cdk/portal';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FeedbackFeatureDialogComponent } from '@design-system/feature/feedback';
import { DsPageSidebarConfigurationComponent } from '@design-system/feature/page';
import { UserService } from '@features/auth';
import { FreshdeskWidgetService } from '@paldesk/shared-lib/features/freshdesk-widget';
import { AppsComponent } from '../apps/apps.component';
import { PALDESK_BASEPATH } from '../environment';
import { SearchComponent } from '../search/search.component';
import { UserComponent } from '../user/user.component';

@Component({
  selector: 'ds-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  @Output() userClick: EventEmitter<ComponentType<any>> = new EventEmitter<
    ComponentType<any>
  >();

  @Input() fastFeedbackAppId: string;
  @Input() helpIconUrl: string;
  @Input() hasNoLogIn: boolean;
  @Input() helpOpenSameWindow: boolean;
  @Input() hasSidebar = true;

  paldeskBasePath = 'https://paldesk.palfinger.com';

  constructor(
    public userService: UserService,
    public dialog: MatDialog,
    @Optional() @Inject(PALDESK_BASEPATH) paldeskBasePath: string,
    @Optional() private freshdeskService: FreshdeskWidgetService,
  ) {
    this.freshdeskService.hideTicketWidgetIconLauncher();
    if (paldeskBasePath) {
      this.paldeskBasePath = paldeskBasePath;
    }
  }

  openFeedbackDialog() {
    this.dialog.open(FeedbackFeatureDialogComponent, {
      data: {
        appId: this.fastFeedbackAppId || window.location.hostname,
        screen: window.location.pathname.substring(1),
      },
    });
  }

  searchClick() {
    this.userClick.emit(SearchComponent);
  }

  appsClick() {
    this.userClick.emit(AppsComponent);
  }

  accountClick() {
    this.userClick.emit(UserComponent);
  }

  sidebarClick() {
    this.userClick.emit(DsPageSidebarConfigurationComponent);
  }

  showFreshdeskWidget() {
    this.freshdeskService.openTheWidget();
  }

  get collapseMenu(): boolean {
    let menuItemsCount = 0;
    if (this.fastFeedbackAppId) menuItemsCount++;
    if (this.helpIconUrl) menuItemsCount++;
    if (!this.hasNoLogIn) menuItemsCount++;
    return menuItemsCount > 1;
  }
}
