<h1 mat-dialog-title>{{ 'general.are_you_sure' | translate }}</h1>
<div mat-dialog-content>
  <p>{{ 'user_profile_delete_account_confirmation' | translate }}</p>
  <ds-text-indicator
    [type]="'warning'"
    *ngIf="
      data.isEndCustomerAdmin ||
      data.responsibilities.length ||
      data.comissions.length ||
      data.adminRoles.length
    "
    >{{ 'user_profile_delete_account_fix' | translate }}</ds-text-indicator
  >
  <div *ngIf="data.isEndCustomerAdmin">
    <p>
      {{ 'user_profile_customer_admin' | translate }}
    </p>
  </div>
  <div *ngIf="data.responsibilities.length">
    <p>
      {{ 'user_profile_responsible_person' | translate }}
    </p>
    <ul>
      <li *ngFor="let item of data.responsibilities">{{ item }}</li>
    </ul>
  </div>
  <div *ngIf="data.comissions.length">
    <p>
      {{ 'user_profile_education_manager' | translate }}
    </p>
    <ul>
      <li *ngFor="let item of data.comissions">{{ item }}</li>
    </ul>
  </div>
  <div *ngIf="data.adminRoles.length">
    <p>
      {{ 'user_profile_role_manager' | translate }}
    </p>
    <ul>
      <li *ngFor="let item of data.adminRoles">{{ item }}</li>
    </ul>
  </div>
</div>
<div mat-dialog-actions>
  <button
    color="accent"
    mat-button
    [mat-dialog-close]="false"
    cdkFocusInitial
    data-cy="prompt_cancel"
  >
    {{ 'general.no' | translate }}
  </button>
  <button
    mat-raised-button
    color="accent"
    mat-button
    [mat-dialog-close]="true"
    data-cy="prompt_ok"
    cdkFocusInitial
  >
    {{ 'general.yes' | translate }}
  </button>
</div>
