import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IFeature, IFeatureEdit } from '../admin/features/features.component';
import { BEST_INVEST_API_BASE_PATH } from '../core/base-path-token';

@Injectable({
  providedIn: 'root',
})
export class TooltipService {
  private _cachedTooltips: Array<IFeature>;
  private baseURL = '/tooltips/';

  constructor(
    private http: HttpClient,
    @Inject(BEST_INVEST_API_BASE_PATH) private basePath: string, // private tokenService: AngularTokenService
  ) {}

  public get cachedTooltips(): Array<IFeature> {
    return this._cachedTooltips;
  }

  public getById(id: number): IFeature | void {
    for (let i = 0; i < this._cachedTooltips.length; i++) {
      const tooltip = this._cachedTooltips[i];
      if (tooltip.id === id) {
        return tooltip;
      }
    }
  }

  // index

  public loadAll(): Promise<any> {
    const promise = new Promise((resolve) => {
      this.sendGet('/tooltips.json', resolve);
    });
    promise.then((data) => {
      const castData = <Array<IFeature>>data;
      this._cachedTooltips = castData['tooltips'];
    });
    return promise;
  }

  // show
  public load(id: number): Promise<IFeature> {
    return new Promise((resolve) => {
      this.sendGet(this.baseURL + id, resolve);
    });
  }

  // edit
  public edit(id: number): Promise<IFeatureEdit> {
    return new Promise((resolve) => {
      this.sendGet(this.baseURL + id + '/edit/', resolve);
    });
  }

  // update
  public update(form: any, id: number): Promise<IFeature> {
    return new Promise((resolve) => {
      this.sendPost(this.baseURL + id, resolve, { tooltip: form });
    });
  }

  // GET
  private sendGet(url: string, resolve: (value?: any) => void) {
    url = this.basePath + url;

    this.http.get(url).subscribe((data: any) => {
      resolve(data);
    });
  }

  // POST
  private sendPost(
    url: string,
    resolve: (value?: any) => void,
    params:
      | HttpParams
      | {
          [param: string]: string | string[];
        }
      | undefined,
  ) {
    url = this.basePath + url;

    this.http.post(url, params).subscribe((data: any) => {
      resolve(data);
    });
  }
}
