import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';

function isEmptyInputValue(value: any) {
  return (
    value === undefined ||
    value == null ||
    (typeof value === 'string' && value.length === 0)
  );
}
/**
 * This class provides custom validators which can be used accross model based angular forms
 */
export class PalfingerValidators {
  /**
   * validates if value is later than date
   * @param date
   */
  static minDate(date: Date) {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const controlDate = new Date(control.value);
      const compareDate = new Date(date);
      compareDate.setHours(0, 0, 0, 0);

      return controlDate >= compareDate ? null : { minDate: true };
    };
  }

  /**
   * Matches two form field if they are equal.
   * Return { 'notEquivalent': true } if values do not match, otherwise return null
   */
  public static matchingEqual(key1: string, key2: string) {
    return this.matchingEqualInternal(key1, key2, true);
  }

  /**
   * Validator that require a valid phone number.
   * Phone number can contain numbers, white spaces and special characters: '+', '.', '(', ')', '-'
   */
  static phoneNumberValidator(control: AbstractControl) {
    if (isEmptyInputValue(control.value)) {
      return null; // don't validate empty values to allow optional controls
    }
    if (!control.value.match(/^[\\+0-9().\s-]+$/)) {
      return { invalidPhoneNumber: true };
    } else {
      return null;
    }
  }

  /**
   * Matches two form field if they are equal.
   * Return { 'notEquivalent': true } if values do not match, otherwise return null
   * @param key1 - first key representing form value
   * @param key2 - second key representing form value
   * @param emptyValuesPermitted - if true, two empty values are a match, otherwise they are not
   */
  private static matchingEqualInternal(
    key1: string,
    key2: string,
    emptyValuesPermitted: boolean,
  ) {
    return (group: FormGroup) => {
      const field1 = group.controls[key1];
      const field2 = group.controls[key2];
      if (isEmptyInputValue(field1.value) && isEmptyInputValue(field2.value)) {
        if (emptyValuesPermitted) {
          return null; // don't validate empty values to allow optional controls
        } else {
          return { notEquivalent: true };
        }
      }
      if (field1.value !== field2.value) {
        return { notEquivalent: true };
      } else {
        return null;
      }
    };
  }
}
