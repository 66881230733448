import { Component, Input } from '@angular/core';
import { dsConfig } from '@design-system/cdk/config';

@Component({
  selector: 'ds-loading-placeholder',
  template:
    '<div [style.width]="width" [style.height]="height" class="full-width" [style.borderRadius]="isRound ? height : undefined "> </div>',
  styleUrls: ['./loading-placeholder.component.scss'],
})
export class LoadingPlaceholderComponent {
  @Input()
  width: string;
  @Input()
  height: string = String(dsConfig.spacing) + 'px';
  @Input()
  isRound: boolean;
}
