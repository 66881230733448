import { Injectable } from '@angular/core';
import { Adapter } from '../core/adapter';
import { CraneTechnology } from './crane-technology.model';

export class CraneSize {
  public crane_technologies: CraneTechnology[] = [];

  constructor(
    public id: number,
    public uid: string,
    public name: string,
    public definition: string,
  ) {}
}

@Injectable({
  providedIn: 'root',
})
export class CraneSizeAdapter implements Adapter<CraneSize> {
  adapt(item: any): CraneSize {
    return new CraneSize(item.id, item.uid, item.name, item.definition);
  }
}
