import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EditToolTipModalComponent } from '../../modals/edit-tooltip-translation-modal/edit-tooltip-modal.component';
import { TooltipService } from '../../services/tooltip.service';
import { AdminComponent } from '../admin.component';
import { IFeature } from '../features/features.component';

@Component({
  selector: 'bi-admin-tooltip',
  templateUrl: './admin-tooltip.component.html',
  styleUrls: ['./admin-tooltip.component.scss'],
})
export class AdminTooltipComponent extends AdminComponent implements OnInit {
  public tooltips: Array<IFeature>;

  constructor(
    public router: Router,
    public translate: TranslateService,
    private dialog: MatDialog,
    private tooltipService: TooltipService,
  ) {
    super();
  }

  ngOnInit() {
    this.getTooltips();
  }

  public async openDialog(getTooltips: any) {
    const dialogRef = this.dialog.open(EditToolTipModalComponent, {
      width: '50%',
      data: getTooltips.id,
    });

    dialogRef.afterClosed().subscribe(async () => {
      const updated = await this.tooltipService.load(getTooltips.id);

      for (let i = 0; i < this.tooltips.length; i++) {
        const element = this.tooltips[i];

        if (element.id === updated.id) {
          this.tooltips[i] = updated;
          return;
        }
      }
    });
  }

  private async getTooltips() {
    const data = await this.tooltipService.loadAll();
    this.tooltips = data['tooltips'];
  }
}
