import { DragDropModule } from '@angular/cdk/drag-drop';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BASE_PATH as BPM_BASE_PATH } from '@data-access/bpm-generated';
import { PRODUCT_SERVICE_BASE_PATH } from '@data-access/product-service';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsBreadcrumbsModule } from '@design-system/components/breadcrumbs';
import { DsFilterModule } from '@design-system/components/filter';
import { DsTableModule } from '@design-system/components/table';
import { DSAppWrapperModule } from '@design-system/feature/app-wrapper';
import { DsPageModule } from '@design-system/feature/page';
import { DsPrintModule } from '@design-system/feature/print';
import { AuthTokens, SharedFeatAuthModule } from '@features/auth';
import { RootEffects, rootReducer, RootState } from '@features/root-store';
import { SEARCH_SERVICE_BASE_PATH } from '@features/search';
import { SharedLibFeatTranslationModule } from '@features/translation';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { BASE_PATH as NOTIFICATIONS_BASE_PATH } from '@paldesk/shared-lib/data-access/notifications-generated';
import { SharedLibFeaturesFreshdeskWidgetModule } from '@paldesk/shared-lib/features/freshdesk-widget';
import {
  API_MANAGEMENT_CONFIG_TOKEN,
  HttpInterceptorModule,
  HttpInterceptorTokens,
} from '@utils/interceptors';
import { environment } from '../environments/environment';
import { AdminSidebarComponent } from './admin/admin-sidebar/admin-sidebar.component';
import { AdminTooltipComponent } from './admin/admin-tooltip/admin-tooltip.component';
import { AdminComponent } from './admin/admin.component';
import { FeatureGroupsComponent } from './admin/feature-groups/feature-groups.component';
import { FeaturesComponent } from './admin/features/features.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BEST_INVEST_API_BASE_PATH } from './core/base-path-token';
import { HomeComponent } from './home/home.component';
import { LocaleInterceptor } from './http-interceptors/locale-interceptor';
import { MaterialModule } from './material.module';
import { AlertFeatureSelectionLossModalComponent } from './modals/alert-feature-selection-loss-modal/alert-feature-selection-loss-modal.component';
import { AlertMissingDataSelectModalComponent } from './modals/alert-missing-data-select-modal/alert-missing-data-select-modal.component';
import { AlertTooltipModalComponent } from './modals/alert-tooltip-modal/alert-tooltip-modal.component';
import { EditFeatureGroupTranslationModalComponent } from './modals/edit-feature-group-translation-modal/edit-feature-group-translation-modal.component';
import { EditFeatureTranslationModalComponent } from './modals/edit-feature-translation-modal/edit-feature-translation-modal.component';
import { EditToolTipModalComponent } from './modals/edit-tooltip-translation-modal/edit-tooltip-modal.component';
import { CompactFeatureSelectionComponent } from './modules/compact/components/compact-feature-selection/compact-feature-selection.component';
import { EnvironmentalComponent } from './modules/compact/components/environmental/environmental.component';
import { ResultComponent } from './modules/compact/components/result/result.component';
import { DataCollectionModule } from './modules/data-collection/data-collection.module';
import { FeatureSelectionModule } from './modules/feature-selection/feature-selection.module';
import { GeneralModule } from './modules/general/general.module';
import { StatisticsModule } from './modules/statistics/statistics.module';
import { SidenavListComponent } from './navigation/sidenav-list/sidenav-list.component';
import { ServerstatusComponent } from './serverstatus/serverstatus.component';

interface State {
  root: RootState;
  // router: RouterReducerState;
}

const coreReducers: ActionReducerMap<State, any> = {
  root: rootReducer,
  // router: routerReducer,
};

@NgModule({
  declarations: [
    AppComponent,
    ServerstatusComponent,
    HomeComponent,
    SidenavListComponent,
    AdminComponent,
    AdminSidebarComponent,
    FeaturesComponent,
    FeatureGroupsComponent,
    EditFeatureTranslationModalComponent,
    AlertMissingDataSelectModalComponent,
    EditFeatureGroupTranslationModalComponent,
    AlertFeatureSelectionLossModalComponent,
    AdminTooltipComponent,
    ResultComponent,
    EnvironmentalComponent,
    CompactFeatureSelectionComponent,
    AlertTooltipModalComponent,
    EditFeatureTranslationModalComponent,
    AlertMissingDataSelectModalComponent,
    EditFeatureGroupTranslationModalComponent,
    AlertFeatureSelectionLossModalComponent,
    EditToolTipModalComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    StoreModule.forRoot(coreReducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([RootEffects]),
    SharedFeatAuthModule.forRoot(),
    SharedLibFeatTranslationModule,
    AppRoutingModule,
    DataCollectionModule,
    FeatureSelectionModule,
    BrowserAnimationsModule,
    MaterialModule,
    GeneralModule,
    StatisticsModule,
    MatDialogModule,
    DragDropModule,
    // Design System
    DSAppWrapperModule,
    DsPageModule,
    DsBreadcrumbsModule,
    DsSpacingModule,
    FlexLayoutModule,
    DsPrintModule,
    DsFilterModule,
    DsTableModule,
    SharedLibFeaturesFreshdeskWidgetModule.forRoot({
      widgetId: 15000000344,
    }),
    HttpInterceptorModule.forRoot({
      provide: API_MANAGEMENT_CONFIG_TOKEN,
      useFactory: () => ({
        subscriptionKey: environment.palfinger_api_management_subs_key,
        whitelistedDomains: environment.domainsForApiManagementSubsKey,
      }),
    }),
  ],
  providers: [
    {
      provide: BEST_INVEST_API_BASE_PATH,
      useFactory: () => environment.api_base,
    },
    { provide: HTTP_INTERCEPTORS, useClass: LocaleInterceptor, multi: true },
    {
      provide: HttpInterceptorTokens.identityServerAuthConf,
      useFactory: () => environment.identityServerAuthConf,
    },
    // SEARCH
    {
      provide: SEARCH_SERVICE_BASE_PATH,
      useFactory: () =>
        environment.palfinger_api_management + '/search/v1/search',
    },
    // HEADER
    {
      provide: BPM_BASE_PATH,
      useFactory: () =>
        environment.palfinger_api_management + '/business-partner-management',
    },
    {
      provide: NOTIFICATIONS_BASE_PATH,
      useFactory: () => environment.palfinger_api_management + '/notifications',
    },
    {
      provide: PRODUCT_SERVICE_BASE_PATH,
      useFactory: () => environment.palfinger_api_management,
    },
    // AUTH
    {
      provide: AuthTokens.oidcClientId,
      useFactory: () => environment.oidc_clientid,
    },
    {
      provide: AuthTokens.oidcScope,
      useFactory: () => environment.oidc_scope,
    },
    {
      provide: AuthTokens.securityTokenService,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    {
      provide: AuthTokens.postLogoutRedirectUri,
      useFactory: () => environment.LINKS.POST_LOGOUT_REDIRECT_URI,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
