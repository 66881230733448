import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { PalfingerQueryEncoder } from '@utils/palfinger-query-encoder';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  GraphicArchiveItems,
  ProductData,
  ProductTree,
  TreeDocument,
} from './models';

export const PRODUCT_SERVICE_BASE_PATH = new InjectionToken<string>(
  'PRODUCT_SERVICE_BASE_PATH',
);

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  private basePath: string;
  private basePricelistPath: string;

  constructor(
    private http: HttpClient,
    @Inject(PRODUCT_SERVICE_BASE_PATH) private apiBasePath: string,
  ) {
    this.basePath = apiBasePath + '/paldesk-hybris/v1';
    this.basePricelistPath = apiBasePath + '/pricelist/v1/download';
  }

  /**
   * Retrieves product tree
   * @param key (pk or code)
   * @param language, default is user context language
   * @param keyIsCode, defualt key is not code
   * @param sub, default get sub levels
   */
  public getProductTree(
    language: string,
    key?: string,
    keyIsCode = false,
    sub = true,
  ): Observable<ProductTree> {
    let path = this.basePath + '/categories';
    let params = new HttpParams({ encoder: new PalfingerQueryEncoder() })
      .set('language', language.toLowerCase())
      .set('sub', String(sub));

    if (key) {
      path += '/' + key;
      if (keyIsCode !== undefined && keyIsCode !== null) {
        params = params.set('code', String(keyIsCode));
      }
    }

    return this.http.get<ProductTree>(path, { params });
  }

  /**
   * Retrieves all documents for a tree entry
   * @param code
   * @param type
   * @param language, default is user context language
   * @param sort optional
   */
  public getProductTreeEntryDocuments(
    code: string,
    type: string,
    language: string,
    sort?: string,
  ): Observable<TreeDocument> {
    const path = this.basePath + '/documents/' + type;

    let params = new HttpParams({ encoder: new PalfingerQueryEncoder() })
      .set('language', language.toLowerCase())
      .set('code', code);

    if (sort) {
      params = params.set('sort', sort);
    }

    return this.http.get<TreeDocument>(path, { params });
  }

  /**
   * Retrieves graphic archives from palipedia backend
   * @param code
   * @param language, default is user context language
   */
  public getGraphicArchiveItems(
    code: string | null,
    language: string,
  ): Observable<GraphicArchiveItems> {
    const path = this.basePath + '/graphicarchiveitems/variant';
    const params = new HttpParams({ encoder: new PalfingerQueryEncoder() })
      .set('code', code ? code : '')
      .set('language', language.toLowerCase());

    return this.http
      .get<GraphicArchiveItems>(path, { params })
      .pipe(
        map((data) => this.processArchiveItemsData(code ? code : '', data)),
      );
  }

  /**
   * Retrieve price list
   * @param code
   */
  public getPricelist(code: string): Observable<Blob> {
    const path = this.basePricelistPath + '/' + code;
    return this.http
      .get(path, { responseType: 'arraybuffer' })
      .pipe(map((data) => new Blob([data], { type: 'application/pdf' })));
  }

  // GET /v1/products/{product_id_or_code}
  /**
   * get variants for product by id
   * @param productId
   */
  public getProduct(
    productId: string,
    language?: string,
  ): Observable<ProductData> {
    let params = new HttpParams({ encoder: new PalfingerQueryEncoder() });
    params = params.set('language', language ? language.toLowerCase() : 'en');

    const path = this.apiBasePath + '/productdata/v1/products/' + productId;
    return this.http.get<ProductData>(path, { params });
  }

  // check result and attribute keys
  private processArchiveItemsData(
    productCode: string,
    items: GraphicArchiveItems,
  ) {
    items.productKey = productCode;

    if (items.classes.length === 0) {
      items.noResults = true;
    }

    if (items && items.classes && items.classes.length > 0) {
      items.attKeys = Object.keys(items.classes[0].attributes_names);
    } else {
      items.attKeys = [] as string[];
    }

    return items;
  }
}
