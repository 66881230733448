import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import {
  CraneSize,
  StandardValue,
  StandardValueAdapter,
} from '../../../../classes';
import { BEST_INVEST_API_BASE_PATH } from '../../../../core/base-path-token';
import { StandardValuesResponse } from '../../../../core/jsonResponses';
import { LocalStorageService } from '../../../../services/local-storage.service';
import { DataCollectionService } from '../../data-collection.service';

@Component({
  selector: 'bi-extended-input',
  templateUrl: './extended-input.component.html',
  styleUrls: ['./extended-input.component.scss'],
})
export class ExtendedInputComponent implements OnInit {
  standardValues: Map<string, StandardValue>;
  baseStandardValueKeys: Array<string> = [
    'km_per_year',
    'working_days_per_year',
    'activity_setup',
    'activity_idle',
    'activity_lift',
    'working_hours_per_year',
  ];

  constructor(
    private router: Router,
    private http: HttpClient,
    private dataCollectionService: DataCollectionService,
    private standardValueAdapter: StandardValueAdapter,
    private localStorageService: LocalStorageService,
    @Inject(BEST_INVEST_API_BASE_PATH) private basePath: string,
  ) {
    // prevent access if standardValues are not set
    if (this.dataCollectionService.standardValues.size === 0) {
      this.router.navigate(['/data-collection']);
    }
  }

  ngOnInit() {
    this.standardValues = this.dataCollectionService.standardValues;
  }

  public standardValueChanged() {
    this.dataCollectionService.storeStandardValues(this.standardValues);
  }

  public resetExtendedValues() {
    const storedSize = <CraneSize>(
      this.localStorageService.deserialize('craneSelect-size')
    );

    this.getStandardValues(storedSize).subscribe(
      (res: StandardValuesResponse) => {
        res.standard_values.forEach((item) => {
          const standardValue = this.standardValueAdapter.adapt(item);
          if (this.baseStandardValueKeys.includes(standardValue.key)) return;
          this.standardValues.set(standardValue.key, standardValue);
        });

        this.dataCollectionService.storeStandardValues(this.standardValues);
      },
      (err) => console.warn(err),
    );
  }

  private getStandardValues(
    craneSize: CraneSize,
  ): Observable<StandardValuesResponse> {
    const route = `/calculations/standard_values/${craneSize.id}`;
    return this.http.get<StandardValuesResponse>(this.basePath + route);
  }
}
