<h1 mat-dialog-title>
  {{ 'user_profile_title' | translate }}&nbsp;
  <small> ({{ userName }}) </small>
</h1>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div mat-dialog-content>
    <div
      fxLayout="row wrap"
      fxLayoutAlign="start stretch"
      fxLayoutGap="1rem grid"
    >
      <div fxFlex="auto" fxFlex.xs="100%">
        <mat-form-field class="full-width">
          <mat-label>{{ 'general.first_name' | translate }}</mat-label>
          <input matInput formControlName="first_name" required />
        </mat-form-field>
      </div>
      <div fxFlex="auto" fxFlex.xs="100%">
        <mat-form-field class="full-width">
          <mat-label>{{ 'general.last_name' | translate }}</mat-label>
          <input matInput formControlName="last_name" required />
        </mat-form-field>
      </div>
      <div fxFlex="100px" fxFlex.xs="100%">
        <mat-form-field class="full-width">
          <mat-label>{{ 'general.select_language' | translate }}</mat-label>
          <mat-select name="language" formControlName="language" required>
            <mat-option
              [value]="lang.code | uppercase"
              *ngFor="let lang of userInterfaceLanguages"
              >{{ lang.title }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div
      fxLayout="row wrap"
      fxLayoutAlign="start stretch"
      fxLayoutGap="1rem grid"
    >
      <div fxFlex="auto" fxFlex.xs="100%">
        <mat-form-field class="full-width">
          <mat-label>{{ 'general.email' | translate }}</mat-label>
          <input matInput formControlName="email" />
        </mat-form-field>
      </div>
    </div>
    <div
      fxLayout="row wrap"
      fxLayoutAlign="start stretch"
      fxLayoutGap="1rem grid"
    >
      <div fxFlex="50%" fxFlex.xs="100%">
        <mat-form-field class="full-width">
          <mat-label>{{ 'general.mobile_number' | translate }}</mat-label>
          <input matInput formControlName="mobile_phone_number" />
        </mat-form-field>
      </div>
      <div fxFlex="50%" fxFlex.xs="100%">
        <mat-form-field class="full-width">
          <mat-label>{{ 'general.phone' | translate }}</mat-label>
          <input matInput formControlName="landline_phone_number" />
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start stretch">
      <ui-kit-confirm-password
        fxFlex="100%"
        formControlName="password"
      ></ui-kit-confirm-password>
    </div>
    <br />
    <div
      fxLayout="row wrap"
      fxLayoutAlign="start stretch"
      fxLayoutGap="1rem grid"
    >
      <div fxFlex="100%">
        <mat-form-field class="full-width" *ngIf="showCurrentPasswordField">
          <mat-label>{{
            'user_profile_current_password' | translate
          }}</mat-label>
          <input
            matInput
            type="password"
            formControlName="current_password"
            required
          />
          <mat-hint *ngIf="isEmailChanged()">{{
            'user_profile_change_email_current_password' | translate
          }}</mat-hint>
        </mat-form-field>
      </div>
    </div>
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="loadingBar"
    ></mat-progress-bar>
  </div>
  <div mat-dialog-actions>
    <button
      type="submit"
      mat-raised-button
      color="primary"
      [disabled]="!form.valid || loadingBar"
    >
      {{ 'general.submit' | translate }}
    </button>
    <button
      mat-raised-button
      *ngIf="isCompanyAdmin"
      type="button"
      (click)="showDPA()"
    >
      {{ 'show_dpa' | translate }}
    </button>
    <button
      mat-raised-button
      type="button"
      color="white"
      (click)="deleteAccountPrompt()"
      [disabled]="show_non_admin_error"
    >
      {{ 'user_profile_delete_account' | translate }}
    </button>
  </div>
</form>
