import { formatPercent } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import Chart, { ChartTooltipOptions } from 'chart.js';
import {
  Chart as ChartMdl,
  CraneSize,
  CraneTechnology,
} from '../../../../classes';
import { DataCollectionService } from '../../../data-collection/data-collection.service';
// Stores shared data between chart and card.
export interface IChartSettings {
  label: string;
  value: number;
  color: string;
}

@Component({
  selector: 'bi-environmental',
  templateUrl: './environmental.component.html',
  styleUrls: ['./environmental.component.scss'],
})
export class EnvironmentalComponent implements OnInit {
  @ViewChild('pieChart') private chartRef: any;
  @ViewChild('pieChartLegend') private chartLegendRef: any;

  public chartSettings: IChartSettings[];
  public chartValues: ChartMdl;

  public selectedCraneSize: CraneSize;
  public selectedCraneTechnology: CraneTechnology;
  public features: Array<any>;
  public groupedFeatures = {};
  private pieChart;
  private chartOptions: Chart.ChartOptions & {
    tooltips: ChartTooltipOptions;
  } = {
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      mode: 'single',
      bodyFontSize: 15,
      xPadding: 20,
      yPadding: 10,
      callbacks: {},
    },
    legend: {
      display: false,
    },
  };

  constructor(
    public translate: TranslateService,
    private dataCollectionService: DataCollectionService,
    private route: ActivatedRoute,
  ) {
    this.features = this.route.snapshot.data.data.features;
    this.groupedFeatures = this.route.snapshot.data.data.groupedFeatures;
    this.selectedCraneSize = this.dataCollectionService.selectedCraneSize;
    this.selectedCraneTechnology = this.dataCollectionService.selectedCraneTechnology;

    // Needs initialization in constructor for 'translate' to work.
    this.chartOptions.tooltips.callbacks = {
      label: function (tooltipItem: any, data: any) {
        // Format label
        const originalNumber: any = data.datasets[0].data[tooltipItem.index];
        const formattedNumber = formatPercent(
          originalNumber,
          translate.currentLang,
          '1.0-0',
        );

        return '  ' + data.labels[tooltipItem.index] + ': ' + formattedNumber;
      },
      title: function () {
        return '';
      }, // Hide title
    };

    this.chartOptions.legendCallback = function (chart: any) {
      const text: string[] = [];
      const data = chart.data;

      text.push('<ul>');
      for (let i = 0; i < data.datasets[0].data.length; i++) {
        text.push(
          '<li><span style="background-color:' +
            data.datasets[0].backgroundColor[i] +
            '"></span>',
        );
        text.push(
          data.labels[i] +
            ' ' +
            formatPercent(
              data.datasets[0].data[i],
              translate.currentLang,
              '1.0',
            ),
        );
        text.push('</li>');
      }
      text.push('</ul>');

      return text.join('');
    };

    this.dataCollectionService.chartValues.subscribe(
      (chartValues) => (this.chartValues = chartValues),
    );
  }

  ngOnInit() {
    // Needs timeout here since TranslateService is not fully available here.
    // Trying different angular lifecycle hooks were not successful.
    setTimeout(() => {
      this.chartSettings = this.setChartSettings();

      this.pieChart = new Chart(this.chartRef.nativeElement, {
        type: 'pie',
        data: {
          labels: this.chartSettings.map((e) => e.label),
          datasets: [
            {
              backgroundColor: this.chartSettings.map((e) => e.color),
              data: this.chartSettings.map((e) => e.value),
            },
          ],
        },
        options: this.chartOptions,
      });

      this.chartLegendRef.nativeElement.innerHTML = this.pieChart.generateLegend();
    }, 200);
  }

  // Prefills settings for usage in chart creation and for cards.
  setChartSettings() {
    // NOTE: we use translate.instant here since the charts library would cut off the labels on rendering.
    // This way we have the translations ready before they get cut off.
    return [
      {
        label: this.translate.instant(
          'HOME.CHARTS.LIFECYCLE_COSTS.ACQUISITION',
        ),
        value: 0.0995,
        color: '#4472c4',
      },
      {
        label: this.translate.instant('HOME.CHARTS.LIFECYCLE_COSTS.FUEL'),
        value: 0.25,
        color: '#ed7d31',
      },
      {
        label: this.translate.instant('HOME.CHARTS.LIFECYCLE_COSTS.STAFF'),
        value: 0.28,
        color: '#a5a5a5',
      },
      {
        label: this.translate.instant(
          'HOME.CHARTS.LIFECYCLE_COSTS.MAINTENANCE',
        ),
        value: 0.11,
        color: '#ffc000',
      },
      {
        label: this.translate.instant(
          'HOME.CHARTS.LIFECYCLE_COSTS.ADMINISTRATION',
        ),
        value: 0.11,
        color: '#5b9bd5',
      },
      {
        label: this.translate.instant('HOME.CHARTS.LIFECYCLE_COSTS.TAXES'),
        value: 0.05,
        color: '#70ad47',
      },
      {
        label: this.translate.instant('HOME.CHARTS.LIFECYCLE_COSTS.TOLL'),
        value: 0.068,
        color: '#264477',
      },
      {
        label: this.translate.instant('HOME.CHARTS.LIFECYCLE_COSTS.OTHER'),
        value: 0.03,
        color: '#9e480e',
      },
    ];
  }
}
