import { Component, OnInit } from '@angular/core';
import { matExpansionAnimations } from '@angular/material/expansion';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Feature } from '../../../../classes';
import { DataCollectionService } from '../../../data-collection/data-collection.service';

const AVAILABLE_CATEGORIES = [
  'economics',
  'benefits',
  'environment',
  'health',
  'summary',
];
const CATEGORY_VALUES = {
  economics: ['annual_savings'],
  benefits: [
    'has_safety',
    'has_resale_value',
    'has_area_of_use',
    'has_optimized_performance',
  ],
  environment: ['diesel_savings', 'thg_emission_savings'],
  health: ['nitrogen_oxide_savings', 'particulate_matter_savings'],
};
const SUCCESS_THRESHOLD = 2; // for green color of big icons in overview.

@Component({
  selector: 'bi-feature-selection',
  templateUrl: './feature-selection.component.html',
  styleUrls: ['./feature-selection.component.scss'],
  animations: [matExpansionAnimations.indicatorRotate],
})
export class FeatureSelectionComponent implements OnInit {
  category: string;
  features: Feature[] = [];
  categoryValues: string[];
  iconMap = {
    // material icon names
    has_safety: 'verified_user',
    has_resale_value: 'attach_money',
    has_area_of_use: 'directions_car',
    has_optimized_performance: 'speed',
    diesel_savings: 'local_gas_station',
    thg_emission_savings: 'cloud',
    nitrogen_oxide_savings: 'cloud',
    particulate_matter_savings: 'cloud',
  };
  textMap = {
    annual_savings: 'FEATURE_SELECTION.SAVINGS.YEARLY',
    thg_emission_savings: 'FEATURE_SELECTION.SAVINGS.GHG',
    nitrogen_oxide_savings: 'FEATURE_SELECTION.SAVINGS.NO',
    particulate_matter_savings: 'FEATURE_SELECTION.SAVINGS.PM',
  };
  searchQ = '';
  groupIsOpen = {}; // to toggle a group

  constructor(
    public translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private dataCollectionService: DataCollectionService,
  ) {}

  ngOnInit() {
    this.dataCollectionService.calculatedFeatures.subscribe((features) => {
      for (let i = 0; i < features.length; i++) {
        features[i].isInsideGroup =
          i > 0 && features[i - 1].group === features[i].group;
      }
      this.features = features;
    });

    this.route.params.subscribe((params: Params) => {
      this.category = params.category;
      this.categoryValues = CATEGORY_VALUES[this.category];

      if (!AVAILABLE_CATEGORIES.includes(this.category)) {
        this.router.navigate(['/feature-selection', AVAILABLE_CATEGORIES[0]]);
      }
    });
  }

  /**
   * De/Select all features in the same group if overall concept is de/selected.
   * @param feature Selected or deselected feature
   */
  changeOthersIfOverall(feature: Feature) {
    if (feature.is_palfinger_overall_concept) {
      for (const f of this.features) {
        if (f.group === feature.group) {
          f.selected = feature.selected;
        }
      }
    }
  }

  /**
   * @see DataCollectionService#sumOf
   */
  sumOf(category: string, categoryValue: string, isPalfinger = false): number {
    this.dataCollectionService.storeCalculatedFeatures(this.features);

    return this.dataCollectionService.sumOf(
      category,
      categoryValue,
      isPalfinger,
    );
  }

  /**
   * Returns true if count of all features with the given type is over a threshold.
   * Only for benefits category.
   * @param categoryValue (e.g. 'has_safety')
   * @return returns a boolean
   */
  isSuccess(categoryValue: string): boolean {
    const count = this.features.reduce((accumulator, feature) => {
      let bool = feature.benefits[categoryValue];

      if (
        feature.status === 'not_available' ||
        (feature.status === 'add_on' && !feature.selected)
      ) {
        bool = false;
      }
      return accumulator + (bool ? 1 : 0);
    }, 0);

    return count >= SUCCESS_THRESHOLD;
  }

  filterFeatures(): Feature[] {
    if (!this.searchQ.length) {
      return this.features;
    }
    const searchQ = this.searchQ.toLowerCase();
    return this.features.filter(
      (f) =>
        f.name.toLowerCase().includes(searchQ) ||
        f.group.toLowerCase().includes(searchQ),
    );
  }

  /**
   * Sums up all savings in if feature is a overall concept, or returns the savings of the feature itself.
   * @param feature The current feature to calculate
   * @param category (e.g. 'economics')
   * @param categoryValue (e.g. 'annual_savings')
   */
  public calculateGroupSavings(
    feature: Feature,
    category: string,
    categoryValue: string,
  ): number {
    // Return single value of fature if it's not an overall concept
    if (!feature.is_palfinger_overall_concept) {
      return feature[category][categoryValue];
    }

    let savings = 0;
    // Sum all features
    this.filterFeatures().forEach((f) => {
      if (
        // If it's not part of the group...
        f.group !== feature.group ||
        // If it's an addon and not selected...
        (f.status === 'add_on' && f.selected !== true) ||
        // If it's not available...
        f.status === 'not_available'
      ) {
        // ... skip item
        return;
      }
      savings += f[category][categoryValue];
    });

    return savings;
  }
}
