import { Component } from '@angular/core';
import { DataCollectionService } from '../../data-collection.service';

@Component({
  selector: 'bi-data-collection-sidebar',
  templateUrl: './data-collection-sidebar.component.html',
  styleUrls: ['./data-collection-sidebar.component.scss'],
})
export class DataCollectionSidebarComponent {
  constructor(private dataCollectionService: DataCollectionService) {}

  get isDisabled(): boolean {
    return this.dataCollectionService.standardValues.size === 0;
  }
}
