<mat-radio-group
  fxLayout="column"
  [fxLayoutGap]="0.5 | dsSpacing"
  [(ngModel)]="selected"
  (change)="onSelectChange()"
>
  <mat-radio-button *ngFor="let crane of cranes" [value]="crane">
    {{ crane.name }} ({{ crane.definition }})
  </mat-radio-button>
</mat-radio-group>
