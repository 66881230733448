<ds-page-sidebar>
  <ds-page-sidebar-header>
    <mat-icon ds-page-sidebar-item-icon> library_add </mat-icon>
    <h4 translate>NAV.FEATURE_SELECTION</h4>
  </ds-page-sidebar-header>

  <ds-page-sidebar-body>
    <ds-page-sidebar-item
      [routerLink]="['./feature-selection/economics']"
      routerLinkActive="active"
    >
      <mat-icon ds-page-sidebar-item-icon>work</mat-icon>
      <span translate>FEATURE_SELECTION.SUB_NAV.ECONOMICS</span>
    </ds-page-sidebar-item>

    <ds-page-sidebar-item
      [routerLink]="['./feature-selection/benefits']"
      routerLinkActive="active"
    >
      <mat-icon ds-page-sidebar-item-icon>stars</mat-icon>
      <span translate>FEATURE_SELECTION.SUB_NAV.BENEFITS</span>
    </ds-page-sidebar-item>

    <ds-page-sidebar-item
      [routerLink]="['./feature-selection/environment']"
      routerLinkActive="active"
    >
      <mat-icon ds-page-sidebar-item-icon>eco</mat-icon>
      <span translate>FEATURE_SELECTION.SUB_NAV.ENVIRONMENT</span>
    </ds-page-sidebar-item>

    <ds-page-sidebar-item
      [routerLink]="['./feature-selection/health']"
      routerLinkActive="active"
    >
      <mat-icon ds-page-sidebar-item-icon>local_hospital</mat-icon>
      <span translate>FEATURE_SELECTION.SUB_NAV.HEALTH</span>
    </ds-page-sidebar-item>

    <ds-page-sidebar-item
      [routerLink]="['./feature-selection/summary']"
      routerLinkActive="active"
    >
      <mat-icon ds-page-sidebar-item-icon>functions</mat-icon>
      <span translate>FEATURE_SELECTION.SUB_NAV.SUMMARY</span>
    </ds-page-sidebar-item>
  </ds-page-sidebar-body>
</ds-page-sidebar>
