import { Component } from '@angular/core';
import { Chart, Feature } from '../../../../classes';
import { DataCollectionService } from '../../../data-collection/data-collection.service';

@Component({
  selector: 'bi-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss'],
})
export class ChartsComponent {
  chartValues: Chart;
  features: Feature[] = [];

  constructor(private dataCollectionService: DataCollectionService) {
    this.dataCollectionService.chartValues.subscribe(
      (chartValues) => (this.chartValues = chartValues),
    );
  }
}
