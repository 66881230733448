<div fxLayout="column" [fxLayoutGap]="1 | dsSpacing" class="full-height">
  <div fxFlex="0 0 auto">
    <div fxLayout fxLayoutAlign="space-between baseline">
      <ds-table class="full-height full-width">
        <table class="pd-table">
          <thead>
            <tr>
              <th translate>ADMIN.FEATURE_GROUPS.FEATURE_GROUP</th>
              <th></th>
              <th translate>ADMIN.FEATURES.FEATURE</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let feature of filterFeatures()">
              <td class="strong">
                <span *ngIf="displayGroupName(feature)">{{
                  feature.group
                }}</span>
              </td>
              <td>
                <mat-checkbox
                  (change)="updateSelection(feature.id)"
                  [checked]="selectedCompactFeatures.includes(feature.id)"
                ></mat-checkbox>
              </td>
              <td>
                {{ feature.name }}
              </td>
              <td>
                <bi-tooltip
                  [title]="feature.name"
                  [description]="feature.description"
                ></bi-tooltip>
              </td>
            </tr>
          </tbody>
        </table>
      </ds-table>
    </div>
  </div>
</div>
