import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import {
  Inject,
  InjectionToken,
  ModuleWithProviders,
  NgModule,
  Optional,
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import {
  MatFormFieldModule,
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterModule, Scroll } from '@angular/router';
import { PdLetDirectiveModule } from '@cdk/pd-let';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsLoadingModule } from '@design-system/components/loading';
import { DsTextIndicatorModule } from '@design-system/components/text-indicator';
import { DsFeedbackFeatureModule } from '@design-system/feature/feedback';
import { DsPageModule } from '@design-system/feature/page';
import { SharedLibFeatDpaModule } from '@features/dpa';
import { HcStatusBannerModule } from '@features/hc-status-banner';
import { SearchModule as OldSearchModule } from '@features/paldesk-header';
import { TranslateModule } from '@ngx-translate/core';
import { UserService } from '@paldesk/shared-lib/data-access/identity-service-generated';
import { ConfirmPasswordModule } from '@ui-kit/form-control';
import { AppShortNamePipeModule } from '@utils/app-short-name-pipe';
import { filter } from 'rxjs';
import { AppWrapperComponent } from './app-wrapper.component';
import { AppsComponent } from './apps/apps.component';
import {
  DsDropdownDirective,
  MenuDropdownComponent,
} from './menu/dropdown.component';
import { MenuComponent } from './menu/menu.component';
import { SearchModule } from './search/search.module';
import { SidebarComponent } from './sidebar/sidebar.component';
import { DeleteAccountPromptComponent } from './user/user-profile/delete-account-prompt-component/delete-account-prompt.component';
import { UserProfileComponent } from './user/user-profile/user-profile.component';
import { UserComponent } from './user/user.component';

export interface DsWrapperConfig {
  disableScrollToTopOnNavigation: boolean;
}

export const DS_WRAPPER_CONFIG = new InjectionToken<DsWrapperConfig>(
  'DS_WRAPPER_CONFIG',
);
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    PortalModule,
    PdLetDirectiveModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatFormFieldModule,
    MatListModule,
    MatInputModule,
    MatProgressBarModule,
    MatSelectModule,
    MatSidenavModule,
    DsSpacingModule,
    OldSearchModule,
    SearchModule,
    TranslateModule,
    ConfirmPasswordModule,
    SharedLibFeatDpaModule,
    AppShortNamePipeModule,
    DsLoadingModule,
    DsPageModule,
    DsFeedbackFeatureModule,
    DsTextIndicatorModule,
    HcStatusBannerModule,
    MatMenuModule,
    MatTooltipModule,
    MatBadgeModule,
    MatRippleModule,
  ],
  declarations: [
    SidebarComponent,
    AppsComponent,
    AppWrapperComponent,
    UserComponent,
    UserProfileComponent,
    DeleteAccountPromptComponent,
    MenuComponent,
    MenuDropdownComponent,
    DsDropdownDirective,
  ],
  exports: [AppWrapperComponent],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    MatDrawer,
    UserService,
  ],
})
export class DSAppWrapperModule {
  constructor(
    private router: Router,
    @Optional() @Inject(DS_WRAPPER_CONFIG) private config: DsWrapperConfig,
  ) {
    if (!config?.disableScrollToTopOnNavigation) {
      this.router.events
        .pipe(filter((e): e is Scroll => e instanceof Scroll))
        .subscribe((e) => {
          if (!e.position) {
            document.getElementById('scrollToTopRestoration')?.scroll(0, 0);
          }
        });
    }
  }

  static forRoot(
    config: DsWrapperConfig,
  ): ModuleWithProviders<DSAppWrapperModule> {
    return {
      ngModule: DSAppWrapperModule,
      providers: [{ provide: DS_WRAPPER_CONFIG, useValue: config }],
    };
  }
}
