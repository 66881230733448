<div
  class="alternative-template"
  fxLayout="column"
  [fxLayoutGap]="1 | dsSpacing"
  *ngIf="dataStream$ | async as dataStream"
>
  <ds-alternative-table-renderer-item
    *ngFor="let data of dataStream"
    [data]="data"
    [sort]="sort"
    [headerColumns]="headerColumns"
    [bodyColumns]="bodyColumns"
    [footerColumns]="footerColumns"
  >
  </ds-alternative-table-renderer-item>
</div>
