import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { Router } from '@angular/router';

export interface Menu {
  title: string;
  titleIcon: string;
  navigation: MenuItem[];
}

export interface MenuItem {
  title: string;
  icon?: string;
  externalLink?: string;
  routerLink?: string;
  children?: MenuItem[];
  badge?: string | number;
}
@Component({
  selector: 'ds-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
    trigger('slideUpDown', [
      transition(':enter', [
        style({ height: 0 }),
        animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
      ]),
      transition(':leave', [
        animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ height: 0 })),
      ]),
    ]),
  ],
})
export class MenuComponent {
  @Input() isExpanded: boolean;
  @Input() menu: Menu;

  opened?: MenuItem;

  clickEvent = new Event('selectSiderbarNavigation');

  constructor(public media: MediaObserver, public router: Router) {}

  toggleOpened(item: MenuItem): void {
    this.opened = this.opened === item ? undefined : item;
  }

  isOpened(item: MenuItem): boolean {
    return (
      this.opened === item ||
      !!item.children?.some((child) => this.opened === child)
    );
  }

  dispatchSelect() {
    document.dispatchEvent(this.clickEvent);
  }
}
