<mat-card
  fxLayout="column"
  class="no-padding print-hidden"
  [class.advanced-filter]="filterItems.childNodes.length > 3"
>
  <div
    fxFlex="1 1 auto"
    fxLayout="row"
    fxLayoutAlign="space-between"
    class="content"
    [@dsCollapseFilter]="
      isExpanded || filterItems.childNodes.length < 4 ? 'expanded' : 'collapsed'
    "
    [fxLayoutGap]="1 | dsSpacing"
  >
    <div
      class="flex-container"
      [class.first-field-bigger]="!IsInputSizeEqual"
      fxFlex="1 1 auto"
      fxLayout="row wrap"
      fxLayoutAlign="space-between"
      [fxLayoutGap]="(0.5 | dsSpacing) + ' grid'"
      [fxLayoutGap.lt-md]="(0 | dsSpacing) + ' grid'"
      #filterItems
    >
      <ng-content></ng-content>
    </div>

    <button
      data-spec="reset-button"
      mat-icon-button
      fxFlex="0 0 auto"
      (click)="resetInternal()"
      *ngIf="!hideReset"
      [matTooltip]="'general.clear' | translate"
      type="button"
      data-test="filter-reset-button"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <button
    [fxHide.gt-sm]="disableExpand"
    *ngIf="filterItems.childNodes.length > 3"
    mat-button
    (click)="setExpanded(!isExpanded)"
    fxFlex="0 0 auto"
    class="indicator-button"
    type="button"
  >
    <mat-icon [@indicatorRotate]="isExpanded ? 'expanded' : 'collapsed'">
      expand_more
    </mat-icon>
  </button>
</mat-card>
