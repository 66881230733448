<ds-page-sidebar>
  <ds-page-sidebar-header>
    <mat-icon ds-page-sidebar-item-icon>language</mat-icon>
    <h4 translate>NAV.ADMIN</h4>
  </ds-page-sidebar-header>

  <ds-page-sidebar-body>
    <ds-page-sidebar-item
      [routerLink]="['./admin/features']"
      routerLinkActive="active"
    >
      <mat-icon ds-page-sidebar-item-icon>bookmark</mat-icon>
      <span translate>ADMIN.SUB_NAV.FEATURES</span>
    </ds-page-sidebar-item>

    <ds-page-sidebar-item
      [routerLink]="['./admin/feature-groups']"
      routerLinkActive="active"
    >
      <mat-icon ds-page-sidebar-item-icon>bookmarks</mat-icon>
      <span translate>ADMIN.SUB_NAV.FEATURE_GROUPS</span>
    </ds-page-sidebar-item>

    <ds-page-sidebar-item
      [routerLink]="['./admin/tooltips']"
      routerLinkActive="active"
    >
      <mat-icon ds-page-sidebar-item-icon>bookmarks</mat-icon>
      <span translate>ADMIN.SUB_NAV.TOOLTIPS</span>
    </ds-page-sidebar-item>
  </ds-page-sidebar-body>
</ds-page-sidebar>
