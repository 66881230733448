<h3>
  {{
    (this.selectedUsageTime === 1
      ? 'STATISTICS.CHARTS.COSTS_SAVINGS_DURATION_OF_USE.DURATION.ONE'
      : 'STATISTICS.CHARTS.COSTS_SAVINGS_DURATION_OF_USE.DURATION.MANY'
    ) | translate: { years: this.selectedUsageTime }
  }}
</h3>

<div>
  <canvas #barChart id="barChart" height="200"></canvas>
</div>

<div class="card-container">
  <div
    *ngFor="let entry of chartSettings; let i = index"
    class="chart-card"
    (click)="showTooltip(i)"
  >
    <div class="value-card">
      {{ entry.value | number: '1.0-0':this.translate.currentLang || 'en' }} €
    </div>
    <div class="title-card" translate>{{ entry.label }}</div>
    <div
      class="color-tag-card"
      [ngStyle]="{ 'background-color': entry.color }"
    ></div>
  </div>
</div>
