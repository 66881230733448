import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { PdBootstrapFile } from './app-bootstrap.models';

export async function bootstrapPaldeskApp(
  appModule: any,
  isProduction: boolean,
  filesToLoadBeforeBootstraping: PdBootstrapFile[],
) {
  try {
    const files = await Promise.all(
      filesToLoadBeforeBootstraping.map(async (x) =>
        (await fetch(`${x.path}?timestamp=${Date.now()}`)).json(),
      ),
    );

    files.forEach((file, index) => {
      Object.assign(filesToLoadBeforeBootstraping[index].target, file);
    });

    if (isProduction) {
      enableProdMode();
    }

    platformBrowserDynamic()
      .bootstrapModule(appModule)
      .catch((err) => console.error(err));
  } catch (err) {
    console.error(err);
  }
}
