<!-- <div *ngFor="let chart of chartValues | keyvalue">{{chart | json}}</div> -->
<h1 translate>STATISTICS.SUB_NAV.CHARTS</h1>
<div class="container-fluid" *ngIf="chartValues; else loading">
  <mat-accordion>
    <!-- Kosten pro Jahr [€/Jahr] -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title translate
          >STATISTICS.CHARTS.COSTS_PER_YEAR.TITLE</mat-panel-title
        >
      </mat-expansion-panel-header>

      <!-- <div class="item-container">
        <div>
          <mat-icon>print</mat-icon>
          <mat-icon>get_app</mat-icon>
        </div>
      </div> -->

      <!-- <div class="spacer"></div> -->

      <bi-costs-per-year-chart
        [values]="chartValues.costs"
      ></bi-costs-per-year-chart>
    </mat-expansion-panel>

    <!-- Nutzungskosten und Einsparungen über die Einsatzdauer -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title translate
          >STATISTICS.CHARTS.COSTS_SAVINGS_DURATION_OF_USE.TITLE</mat-panel-title
        >
      </mat-expansion-panel-header>

      <bi-costs-savings-duration-of-use-chart
        [values]="chartValues.costs"
      ></bi-costs-savings-duration-of-use-chart>
    </mat-expansion-panel>

    <!-- Jahresnutzungskosten Kran (exkl. Add-On Features) -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title translate
          >STATISTICS.CHARTS.YEARLY_USAGE_COSTS_CRANE.TITLE</mat-panel-title
        >
      </mat-expansion-panel-header>

      <bi-yearly-usage-costs-crane-chart
        [values]="chartValues.costs"
      ></bi-yearly-usage-costs-crane-chart>
    </mat-expansion-panel>

    <!-- Jährliche Einsparungen je Feature -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title translate
          >STATISTICS.CHARTS.YEARLY_SAVINGS_PER_FEATURE.TITLE</mat-panel-title
        >
      </mat-expansion-panel-header>

      <bi-yearly-savings-per-feature-chart></bi-yearly-savings-per-feature-chart>
    </mat-expansion-panel>

    <!-- Diesel pro Jahr [Liter/Jahr] -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title translate
          >STATISTICS.CHARTS.DIESEL_PER_YEAR.TITLE</mat-panel-title
        >
      </mat-expansion-panel-header>

      <bi-diesel-per-year-chart
        [values]="chartValues.diesel"
      ></bi-diesel-per-year-chart>
    </mat-expansion-panel>

    <!-- Treibhausgas Emissionen pro Jahr [tCO2eq/Jahr] -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title translate
          >STATISTICS.CHARTS.YEARLY_GHG_EMISSIONS.TITLE</mat-panel-title
        >
      </mat-expansion-panel-header>

      <bi-yearly-greenhouse-gas-emission-chart
        [values]="chartValues.thg"
      ></bi-yearly-greenhouse-gas-emission-chart>
    </mat-expansion-panel>

    <!-- Jährliche Kosten der THG Emission [€/Jahr] -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title translate
          >STATISTICS.CHARTS.COSTS_PER_YEAR_FOR_GHG_EMISSIONS.TITLE</mat-panel-title
        >
      </mat-expansion-panel-header>

      <bi-costs-per-year-greenhouse-gas-emission-chart
        [values]="chartValues.thg"
      ></bi-costs-per-year-greenhouse-gas-emission-chart>
    </mat-expansion-panel>

    <!-- Stickoxid Emission pro Jahr [kgNOx/Jahr] -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title translate
          >STATISTICS.CHARTS.YEARLY_NITROGEN_OXIDE_EMISSIONS.TITLE</mat-panel-title
        >
      </mat-expansion-panel-header>

      <bi-yearly-nitrogen-oxide-emission-chart
        [values]="chartValues.nitrogen_oxide"
      ></bi-yearly-nitrogen-oxide-emission-chart>
    </mat-expansion-panel>

    <!-- Feinstaub Emissionen pro Jahr [kgPM&lt;2,5μ/Jahr] -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title translate
          >STATISTICS.CHARTS.YEARLY_PARTICULATE_MATTER_EMISSIONS.TITLE</mat-panel-title
        >
      </mat-expansion-panel-header>

      <bi-yearly-particulate-matter-emission-chart
        [values]="chartValues.particulate_matter"
      ></bi-yearly-particulate-matter-emission-chart>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<router-outlet></router-outlet>

<ng-template #loading>
  <ds-table-loading [numberOfHeaders]="1" [numberOfRows]="9"></ds-table-loading>
</ng-template>
