import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingPlaceholderComponent } from './loading-placeholder/loading-placeholder.component';
import { PendingButtonDirective } from './pending-button/pending-button.directive';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { PendingInputDirective } from './pending-input/pending-input.directive';
@NgModule({
  imports: [CommonModule, MatProgressSpinnerModule, MatIconModule],
  declarations: [
    LoadingPlaceholderComponent,
    PendingButtonDirective,
    PendingInputDirective,
  ],
  exports: [
    LoadingPlaceholderComponent,
    PendingButtonDirective,
    PendingInputDirective,
  ],
})
export class DsLoadingModule {}
