import { formatNumber } from '@angular/common';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'chart.js';
import { Feature } from '../../../../../classes';
import { DataCollectionService } from '../../../../data-collection/data-collection.service';
// TODO: add labels to horizontal bars
// Break long label texts

export interface IChartSettings {
  name: string;
  value: number;
  sortvalue: number;
  percent: number;
  color: string;
}

@Component({
  selector: 'bi-yearly-savings-per-feature-chart',
  templateUrl: './yearly-savings-per-feature-chart.component.html',
  styleUrls: ['./yearly-savings-per-feature-chart.component.scss'],
})
export class YearlySavingsPerFeatureChartComponent implements AfterViewInit {
  @ViewChild('barChart') private chartRef: any;

  public chartSettings: IChartSettings[] = [];
  private features: Feature[] = [];
  private colors = [
    '#00B251',
    '#96312e',
    '#962E40',
    '#330136',
    '#4CBF88',
    '#F2B134',
    '#6F4A70',
    '#FF6275',
    '#00B5C4',
  ];
  private barChart: any;
  private totalValues = 0;
  private colorIndex = 0;
  private chartOptions: any = {
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          display: true,
          scaleLabel: {
            display: true,
            fontStyle: 'bold',
            labelString: this.translate.instant(
              'STATISTICS.CHARTS.YEARLY_SAVINGS_PER_FEATURE.X_AXIS_LABEL',
            ),
          },
          ticks: {},
        },
      ],
      yAxes: [
        {
          display: true,
          categoryPercentage: 1,
          barPercentage: 0.9,
          scaleLabel: {
            display: true,
            fontStyle: 'bold',
            labelString: this.translate.instant(
              'STATISTICS.CHARTS.YEARLY_SAVINGS_PER_FEATURE.Y_AXIS_LABEL',
            ),
          },
        },
      ],
    },
    tooltips: {
      mode: 'single',
      bodyFontSize: 15,
      xPadding: 20,
      yPadding: 10,
    },
  };

  constructor(
    public translate: TranslateService,
    private dataCollectionService: DataCollectionService,
  ) {
    // Needs initialization in constructor for 'translate' to work.
    this.chartOptions.tooltips.callbacks = {
      label: function (tooltipItem: any, data: any) {
        // Format label
        const originalNumber: any =
          data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
        const formattedNumber =
          formatNumber(originalNumber, translate.currentLang || 'en', '1.0-0') +
          ' €';

        return (
          '  ' + data.labels[tooltipItem.datasetIndex] + ': ' + formattedNumber
        );
      },
      title: function () {
        /* Hide title */
      },
    };
    // Needs initialization in constructor for 'translate' to work.
    this.chartOptions.scales.xAxes[0].ticks.callback = (
      label: any,
      index: any,
      labels: any,
    ) =>
      formatNumber(label, this.translate.currentLang || 'en', '1.0-0') + ' €';
  }

  ngAfterViewInit() {
    this.dataCollectionService.calculatedFeatures.subscribe((features) => {
      this.features = features.filter(
        (f: Feature) =>
          (f.status !== 'not_available' || f.selected) &&
          f.economics['annual_savings'] > 0,
      );
    });

    // Determine total values first in order to calculate percentage.
    this.features.forEach((element) => {
      this.totalValues += element.economics['annual_savings'];
    });

    // Calculate percentage and build data object.
    for (let i = 0; i < this.features.length; i++) {
      const percent =
        (this.features[i].economics['annual_savings'] / this.totalValues) * 100;
      const obj = {
        name: this.features[i].name,
        value: this.features[i].economics['annual_savings'],
        sortvalue: this.features[i].economics['annual_savings'],
        percent: percent,
        color: '',
      };

      this.chartSettings.push(obj);
    }

    // Sort values.
    this.chartSettings.sort(function (a, b) {
      return b['sortvalue'] - a['sortvalue'];
    });

    // Apply colors to the determined values.
    for (let i = 0; i < this.chartSettings.length; i++) {
      if (this.colorIndex >= this.colors.length) {
        this.colorIndex = 0;
      }
      this.chartSettings[i]['color'] = this.colors[this.colorIndex];
      this.colorIndex++;
    }

    this.barChart = new Chart(this.chartRef.nativeElement, {
      type: 'horizontalBar',
      data: {
        labels: this.chartSettings.map((e) => e.name),
        datasets: [
          {
            backgroundColor: this.chartSettings.map((e) => e.color),
            data: this.chartSettings.map((e) => e.value),
          },
        ],
      },
      options: this.chartOptions,
    });
  }
}
