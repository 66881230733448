<strong fxLayout fxLayoutAlign="start center">
  <ng-container *ngFor="let breadcrumb of breadcrumbs; let first = first">
    <span *ngIf="!first">&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;</span>
    <a [routerLink]="['./' + breadcrumb.url]">
      <ng-container
        *ngIf="breadcrumb.label$ | async as label; else labelLoading"
      >
        {{ label | translate }}
      </ng-container>
      <ng-template #labelLoading>...</ng-template>
    </a>
  </ng-container>
</strong>
