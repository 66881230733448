<h1 translate>DATA_COLLECTION.DATA_INPUT.TITLE</h1>
<div fxLayout="row wrap" [fxLayoutGap]="(1 | dsSpacing) + ' grid'">
  <div fxFlex="100%" fxFlex.gt-xs="50%" fxFlex.gt-md="33.3%" fxFlex.gt-lg="25%">
    <mat-card class="h-100 full-height">
      <h4 translate>DATA_COLLECTION.DATA_INPUT.WORKLOAD</h4>

      <mat-form-field
        class="full-width"
        *ngIf="standardValues.get('km_per_year') as kmPerYear"
      >
        <mat-label>{{
          'DATA_COLLECTION.DATA_INPUT.KM_PER_YEAR' | translate
        }}</mat-label>
        <input
          matInput
          type="number"
          [(ngModel)]="kmPerYear.amount"
          (ngModelChange)="standardValueChanged()"
        />
        <span matSuffix>{{ kmPerYear.unit }}</span>
      </mat-form-field>

      <div fxLayout="row wrap" [fxLayoutGap]="(0.5 | dsSpacing) + ' grid'">
        <span translate>DATA_COLLECTION.DATA_INPUT.WORKING_HOURS_INPUT_IN</span>
        <mat-radio-group
          class="d-flex flex-column"
          name="workHoursIsYearly"
          [(ngModel)]="workHoursIsYearly"
          fxLayout="row wrap"
          [fxLayoutGap]="(0.5 | dsSpacing) + ' grid'"
        >
          <mat-radio-button [value]="true">{{
            'DATA_COLLECTION.DATA_INPUT.YEARLY' | translate
          }}</mat-radio-button>
          <mat-radio-button [value]="false">{{
            'DATA_COLLECTION.DATA_INPUT.DAILY' | translate
          }}</mat-radio-button>
        </mat-radio-group>
      </div>
      <br />

      <div *ngIf="workHoursIsYearly">
        <mat-form-field
          class="full-width"
          *ngIf="
            standardValues.get('working_hours_per_year') as workingHoursPerYear
          "
        >
          <mat-label>{{
            'DATA_COLLECTION.DATA_INPUT.ANNUAL_WORKING_HOURS' | translate
          }}</mat-label>
          <input
            matInput
            type="number"
            [(ngModel)]="workingHoursPerYear.amount"
            (ngModelChange)="standardValueChanged()"
          />
          <span matSuffix>{{ workingHoursPerYear.unit }}</span>
        </mat-form-field>
      </div>
      <div *ngIf="!workHoursIsYearly">
        <mat-form-field
          class="full-width"
          *ngIf="
            standardValues.get('working_hours_per_day') as workingHoursPerDay
          "
        >
          <mat-label>{{
            'DATA_COLLECTION.DATA_INPUT.WORKING_HOURS_PER_DAY' | translate
          }}</mat-label>
          <input
            matInput
            type="number"
            (change)="workChange()"
            [(ngModel)]="workingHoursPerDay.amount"
            (ngModelChange)="standardValueChanged()"
          />
          <span matSuffix>{{ workingHoursPerDay.unit }}</span>
        </mat-form-field>

        <ng-container
          *ngIf="
            standardValues.get('working_days_per_year') as workingDaysPerYear
          "
        >
          <mat-form-field class="full-width">
            <mat-label>{{
              'DATA_COLLECTION.DATA_INPUT.WORKING_DAYS_PER_YEAR' | translate
            }}</mat-label>
            <input
              matInput
              type="number"
              (change)="workChange()"
              [(ngModel)]="workingDaysPerYear.amount"
              (ngModelChange)="standardValueChanged()"
            />
            <span matSuffix>{{ workingDaysPerYear.unit }}</span>
          </mat-form-field>
          <ng-container
            *ngIf="
              standardValues.get(
                'working_hours_per_year'
              ) as workingHoursPerYear
            "
          >
            <span translate
              >DATA_COLLECTION.DATA_INPUT.ANNUAL_WORKING_HOURS</span
            >: {{ workingHoursPerYear.amount }}
            {{ workingHoursPerYear.unit }}
          </ng-container>
        </ng-container>
      </div>
    </mat-card>
  </div>

  <div fxFlex="100%" fxFlex.gt-xs="50%" fxFlex.gt-md="33.3%" fxFlex.gt-lg="25%">
    <mat-card class="h-100 full-height">
      <h4 translate>DATA_COLLECTION.DATA_INPUT.ACTIVITY_DISTRIBUTION</h4>

      <mat-form-field
        class="full-width"
        *ngIf="standardValues.get('activity_idle') as activityIdle"
      >
        <mat-label>{{
          'DATA_COLLECTION.DATA_INPUT.ACTIVITY_IDLE' | translate
        }}</mat-label>
        <input
          matInput
          type="number"
          placeholder=""
          [(ngModel)]="activityIdle.amount"
          (ngModelChange)="standardValueChanged()"
        />
        <span matSuffix>%</span>
      </mat-form-field>

      <mat-form-field
        class="full-width"
        *ngIf="standardValues.get('activity_setup') as activitySetup"
      >
        <mat-label>{{
          'DATA_COLLECTION.DATA_INPUT.ACTIVITY_SETUP' | translate
        }}</mat-label>
        <input matInput type="number" [(ngModel)]="activitySetup.amount" />
        <span matSuffix>%</span>
      </mat-form-field>

      <mat-form-field
        class="full-width"
        *ngIf="standardValues.get('activity_lift') as activityLift"
      >
        <mat-label>{{
          'DATA_COLLECTION.DATA_INPUT.ACTIVITY_LIFT' | translate
        }}</mat-label>
        <input
          matInput
          type="number"
          [(ngModel)]="activityLift.amount"
          (ngModelChange)="standardValueChanged()"
        />
        <span matSuffix>%</span>
      </mat-form-field>

      <span translate>DATA_COLLECTION.DATA_INPUT.CONTROL</span>
      <span class="ml-1" [class.warning-text]="getTotal() > 100">{{
        getTotal()
      }}</span>
      %
    </mat-card>
  </div>
</div>
