<h1 translate>DATA_COLLECTION.CRANE_SELECT.TITLE</h1>

<h4 translate>DATA_COLLECTION.CRANE_SELECT.SUBTITLE_SIZE</h4>
<bi-crane-type
  [cranes]="cranes"
  [selected]="selectedCraneSize"
  (selectCraneSize)="onSelectCraneSize($event)"
></bi-crane-type>

<h4 translate>DATA_COLLECTION.CRANE_SELECT.SUBTITLE_TECHNOLOGY</h4>
<bi-crane-technology
  [crane]="selectedCraneSize"
  [selected]="selectedCraneTechnology"
  (selectCraneTechnology)="onSelectCraneTechnology($event)"
></bi-crane-technology>
