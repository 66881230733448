import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EditFeatureTranslationModalComponent } from '../../modals/edit-feature-translation-modal/edit-feature-translation-modal.component';
import { FeatureService } from '../../services/feature.service';
import { AdminComponent } from '../admin.component';

export interface IFeature {
  id: number;
  name: string;
  description: string;
}

export interface IFeatureEdit {
  id: number;
  name_de: string;
  name_en: string;
  description_de: string;
  description_en: string;
}

@Component({
  selector: 'bi-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss'],
})
export class FeaturesComponent extends AdminComponent implements OnInit {
  features: IFeature[];
  searchQ = '';

  constructor(
    public router: Router,
    public translate: TranslateService,
    private featureService: FeatureService,
    private dialog: MatDialog,
  ) {
    super();
  }

  async ngOnInit() {
    this.getFeatures();
  }

  public async openDialog(feature: IFeature) {
    const dialogRef = this.dialog.open(EditFeatureTranslationModalComponent, {
      width: '50%',
      data: feature.id,
    });

    dialogRef.afterClosed().subscribe(async () => {
      const updated = await this.featureService.loadFeature(feature.id);

      for (let i = 0; i < this.features.length; i++) {
        const element = this.features[i];

        if (element.id === updated.id) {
          this.features[i] = updated;
          return;
        }
      }
    });
  }

  filterFeatures(): IFeature[] {
    if (!this.searchQ.length) {
      return this.features;
    }

    const searchQ = this.searchQ.toLowerCase();
    return this.features.filter((f) => f.name.toLowerCase().includes(searchQ));
  }

  private async getFeatures() {
    const data = await this.featureService.loadAllFeatures();

    this.features = data['features'];
  }
}
