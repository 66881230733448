import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, NgZone, Optional } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DsSnackbar, DsSnackbarType } from '@design-system/feature/snackbar';
import { AcceptedDpa, DpaComponent, DpaData } from '@features/dpa';
import { TranslateService } from '@ngx-translate/core';
import { UserService as IdentityServerUserService } from '@paldesk/shared-lib/data-access/identity-service-generated';
import {
  MessageSeverityType,
  MessageTargetType,
  MessengerService,
} from '@shared-lib/messenger';
import { OAuthErrorEvent, OAuthService, UserInfo } from 'angular-oauth2-oidc';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, filter, first, map, switchMap } from 'rxjs/operators';
import { AuthTokens } from '../shared-feat-auth.tokens';
import { OidcConfig } from '../utils/oidcConfig';
import { UserContext } from './user-context';
import { UserServiceConfig } from './user.service.config';
import { UserContextService } from './usercontext.service';

/**
 * Provides all user relevant authentication logic
 */
@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _isAuthorized = false;
  private _isAuthorized$ = new ReplaySubject<boolean>(1);
  private stateQueryParam: string | null;
  private readonly signOutRoute = '/special/signout';
  private readonly callbackRoute = '/callback';

  constructor(
    private oauthService: OAuthService,
    public router: Router,
    public usercontextService: UserContextService,
    public messageService: MessengerService,
    private dialog: MatDialog,
    private identityServerUserService: IdentityServerUserService,
    private translateService: TranslateService,
    private ngZone: NgZone,
    private http: HttpClient,
    @Optional() private snackbar: DsSnackbar,
    @Inject(AuthTokens.securityTokenService)
    private securityTokenService: string,
    @Inject(AuthTokens.oidcClientId)
    private oidcClientId: string,
    @Optional()
    @Inject(AuthTokens.checkDpa)
    private checkDpa: boolean,
    @Inject(AuthTokens.postLogoutRedirectUri)
    private postLogoutRedirectUri: string,
    @Inject(AuthTokens.oidcScope) private oidcScope: string,
    @Optional() private config: UserServiceConfig,
  ) {
    this.oauthService.configure(
      OidcConfig.Configuration(
        this.securityTokenService,
        this.oidcClientId,
        this.oidcScope,
        this.postLogoutRedirectUri,
      ),
    );

    // get userinfo after a token is received
    this.oauthService.events
      .pipe(filter((e) => e.type === 'token_received'))
      .subscribe((_) => {
        this.logDebug('Token received.');
        this.getUserInfo(!this.isAuthorized);
      });

    // set up single sign out
    this.oauthService.events
      .pipe(filter((e) => e.type === 'session_terminated'))
      .subscribe((_) => {
        this.logDebug('Session terminated.');
        this.navigateToSignOutPage();
      });

    // handle token expiration
    this.oauthService.events
      .pipe(filter((e) => e.type === 'token_expires'))
      .subscribe((_) => {
        this.logDebug('Token expiration.');
        this.navigateToSignOutPage();
      });

    this.stateQueryParam = this.getStateQueryParam();

    // get tokens if this is redirect back from identity server,
    // initiate silent refresh otherwise
    this.oauthService
      .tryLogin()
      .then((_) => {
        if (window.location.pathname === this.callbackRoute) {
          this.redirectToRequestedUrl();
        } else {
          this.loginWithSilentRenew();
        }
      })
      .catch((error) => this.handleTryLoginError(error));
  }

  private getStateQueryParam(): string | null {
    const params = new HttpParams({ fromString: window.location.search });
    return params.get('state');
  }

  private handleTryLoginError(error: OAuthErrorEvent | string): void {
    const errorMessage = 'Error from oauthService.tryLogin().';

    if (error === 'Token has expired') {
      this.http
        .get<any>('http://worldclockapi.com/api/json/utc/now')
        .subscribe((response) => {
          const currentUtcTime = response.currentDateTime;
          let detailText = `Browser time: ${new Date().toString()}. Real utc time: ${currentUtcTime}.`;

          const accessToken = this.oauthService.getAccessToken();
          if (accessToken) {
            const decodedBody = jwtDecode<JwtPayload>(accessToken, {});
            detailText += ` UserId: ${decodedBody.sub}.`;
          }
          this.logError(errorMessage, error, detailText);
          this.displayGeneralError('general.error_code.login_wrong_time');
        });
    } else if (
      error instanceof OAuthErrorEvent &&
      error.type === 'invalid_nonce_in_state'
    ) {
      const nonceInStorage = sessionStorage.getItem('nonce');
      const detailText = `State query param invalid. Value from request: ${this.stateQueryParam}. Value in storage: ${nonceInStorage}`;
      this.logError(errorMessage, error, detailText);
      // try to login again
      // see https://dev.azure.com/palfinger-swdev/Palfinger.Paldesk/_git/Palfinger.Paldesk.Dashboard/pullrequest/17267
      // and https://palfinger-swdev.visualstudio.com/DefaultCollection/Palfinger.Paldesk/_git/Palfinger.Paldesk.Dashboard/pullrequest/29714
      this.loginWithSilentRenew().then((success) => {
        if (success) {
          this.redirectToRequestedUrl();
        } else {
          this.displayGeneralError();
        }
      });
    } else {
      this.logError(errorMessage, error);
      this.displayGeneralError();
    }
  }

  get isAuthorized(): boolean {
    return this._isAuthorized;
  }

  get isAuthorized$(): Observable<boolean> {
    return this._isAuthorized$.asObservable();
  }
  get userContext(): UserContext {
    return this.usercontextService.userContext;
  }

  get currentUser(): Observable<UserContext | undefined> {
    return this.usercontextService.currentUser;
  }

  login() {
    this.logDebug('Start login.');
    this.oauthService.initCodeFlow();
  }

  /**
   * Tries to refresh access token and user info.
   */
  tryRefreshSession(): void {
    this.logDebug('Start refresh login session.');
    this.oauthService.silentRefresh();
  }

  /**
   * Removes tokens and user data from browser storage without sending anything to Identity Server.
   *
   * It can be used when a user is not logged-in in Identity Server,
   * but user data is found in the storage.
   */
  logoutLocally(): void {
    this.oauthService.logOut(true);
    this.setAuthenticated(false);
  }

  logout(): void {
    this.logDebug('Start logoff.');
    sessionStorage.removeItem(this.redirectPathStorageKey);
    this.oauthService.logOut();
  }

  /**
   * Gets time of Identity Server login expiration
   */
  public getLoginValidUntil(): Observable<Date | undefined> {
    return this.isAuthorized$.pipe(
      first((isAuthorized) => !!isAuthorized),
      switchMap((_) =>
        this.http.get<number>(
          `${this.securityTokenService}/user/sessionexpiration`,
          {
            withCredentials: true,
          },
        ),
      ),
      map((sessionExpiration) => {
        const expireDate = new Date(0);
        expireDate.setUTCSeconds(sessionExpiration);
        return expireDate;
      }),
      catchError((_) => of(undefined)),
    );
  }

  /**
   * Checks if current loggedIn User has one of the role
   * @param roles
   */
  public hasOneRole(roles: string[] | undefined): boolean {
    if (
      this.usercontextService.userContext &&
      this.usercontextService.userContext.roles &&
      this.usercontextService.userContext.roles.length > 0 &&
      roles &&
      roles.length > 0
    ) {
      for (const reqRole of roles) {
        if (this.usercontextService.userContext.roles.indexOf(reqRole) > -1) {
          return true;
        }
      }
    }
    return false;
  }
  /**
   * Check if current loggedIn User has a specific role
   * @param role
   */
  public hasRole(role: string): boolean {
    const roles: string[] = [role];
    return this.hasOneRole(roles);
  }
  /**
   * Check if current loggedIn User has all Roles
   * @param roles
   */
  public hasAllRoles(roles: string[]): boolean {
    if (
      this.usercontextService.userContext &&
      this.usercontextService.userContext.roles &&
      this.usercontextService.userContext.roles.length > 0 &&
      roles &&
      roles.length > 0
    ) {
      for (const reqRole of roles) {
        if (this.usercontextService.userContext.roles.indexOf(reqRole) === -1) {
          return false;
        }
      }
      return true;
    }
    return false;
  }
  /**
   * Check if user has a role with provided appId
   * @param appId
   */
  public hasApplication(appId: string): boolean {
    if (
      this.usercontextService.userContext &&
      this.usercontextService.userContext.roles &&
      appId
    ) {
      return !!this.usercontextService.userContext.roles.find((x) =>
        x.toLowerCase().startsWith(appId.toLowerCase()),
      );
    }
    return false;
  }

  /**
   * Check if a user has an accesscode
   * @param code
   */
  public hasAccessCode(code: string) {
    if (
      this.usercontextService.userContext &&
      this.usercontextService.userContext.products &&
      code
    ) {
      return !!this.usercontextService.userContext.products.find(
        (x) => x.toLowerCase() === code,
      );
    }
    return false;
  }
  /**
   * Check if user is one of partner types
   * @param partnerTypes
   */
  public isOneOfPartnerTypes(partnerTypes: number[]) {
    if (
      this.usercontextService.userContext &&
      this.usercontextService.userContext.partnertype &&
      partnerTypes &&
      partnerTypes.length > 0 &&
      partnerTypes.indexOf(this.usercontextService.userContext.partnertype) > -1
    ) {
      return true;
    }
    return false;
  }
  /**
   * Check if a user is partner type
   * @param partnerType
   */
  public isPartnerType(partnerType: number) {
    const partnerTypes: number[] = [partnerType];
    return this.isOneOfPartnerTypes(partnerTypes);
  }

  /**
   * Check if user's company area is PALFINGER GmbH
   */
  public isGermany() {
    if (this.config?.palf_germany_sap_number) {
      return (
        this.userContext.area_sapid_nr === this.config.palf_germany_sap_number
      );
    }

    return this.userContext.area_sapid_nr === '0000000101';
  }

  /**
   * Internal method to set authentication status
   * @param authenticated
   */
  public setAuthenticated(authenticated: boolean): void {
    this._isAuthorized = authenticated;
    this._isAuthorized$.next(this._isAuthorized);
  }

  public navigateToSignOutPage() {
    const location = window.location.pathname + window.location.search;
    if (location !== this.signOutRoute) {
      this.setRedirectPath(location);
      this.ngZone.run(() => this.router.navigate([this.signOutRoute]));
    }
  }

  public setRedirectPath(path: string): void {
    if (path !== this.callbackRoute) {
      sessionStorage.setItem(this.redirectPathStorageKey, path);
    }
  }

  private loginWithSilentRenew(): Promise<boolean> {
    this.logDebug('Initiate silent refresh.');
    return this.oauthService
      .silentRefresh()
      .then((event) => {
        if (event instanceof OAuthErrorEvent) {
          this.logWarning('Silent refresh error.', event);
          this.setAuthenticated(false);
          return false;
        }
        return true;
      })
      .catch((error) => {
        this.logWarning('Silent refresh error.', error);
        this.setAuthenticated(false);
        return false;
      });
  }

  private setUserContext(userInfo: UserInfo): void {
    const userContext = userInfo as UserContext;
    const language = userInfo.lang?.toLowerCase();

    if (language && language !== this.translateService.currentLang) {
      this.translateService.resetLang(language); // we need this as a workaround to a ngx-translate bug from 2007 :/ https://github.com/ngx-translate/core/issues/749
      this.translateService.use(language);
    }

    if (this.checkDpa && userContext.dpa_acceptance_required) {
      this.requireDpaAcceptence(userContext);
    } else {
      this.usercontextService.setUser(userContext);
      this.setAuthenticated(true);
    }
  }

  private redirectToRequestedUrl(): void {
    const redirectPath = sessionStorage.getItem(this.redirectPathStorageKey);
    if (redirectPath) {
      this.logDebug(`Redirecting user to path:${redirectPath}.`);
      sessionStorage.removeItem(this.redirectPathStorageKey);
      this.router.navigateByUrl(redirectPath);
    } else {
      this.logDebug('Redirecting user to root');
      this.router.navigate(['']);
    }
  }

  private requireDpaAcceptence(userContext: UserContext) {
    const dpaData: DpaData = {
      accept_needed: true,
      first_name: userContext.firstname,
      last_name: userContext.lastname,
      company_name: userContext.company_name,
    };
    const dialogRef = this.dialog.open(DpaComponent, {
      data: dpaData,
      width: '1000px',
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(
        switchMap((result: AcceptedDpa) =>
          result
            ? this.identityServerUserService
                .updateUserProfile({
                  firstName: userContext.firstname,
                  lastName: userContext.lastname,
                  language: userContext.lang,
                  dpaInfo: {
                    version: result.version,
                    responsiblePerson: result.responsiblePerson,
                  },
                  emailAddress: userContext.email,
                  mobilePhoneNumber: userContext.mobilephonenumber,
                  landlinePhoneNumber: userContext.phonenumber,
                })
                .pipe(map((_) => true))
            : of(false),
        ),
      )
      .subscribe(
        (wasProfileUpdated: boolean) => {
          if (wasProfileUpdated) {
            this.getUserInfo();
          } else {
            this.logout();
          }
        },
        (_error) => {
          this.displayGeneralError();
        },
      );
  }

  private getUserInfo(displayErrors = true): void {
    const headers = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + this.oauthService.getAccessToken(),
    );

    this.http
      .get<UserInfo>(this.oauthService.userinfoEndpoint || '', {
        headers,
      })
      .subscribe(
        (userInfo) => {
          this.logDebug('User info loaded.');

          // validation according to https://openid.net/specs/openid-connect-core-1_0.html#UserInfoResponse
          const idTokenSub = (this.oauthService.getIdentityClaims() as any)[
            'sub'
          ];
          if (idTokenSub && userInfo.sub === idTokenSub) {
            this.setUserContext(userInfo);
          } else {
            if (displayErrors) {
              this.displayGeneralError();
            }
          }
        },
        (error) => {
          this.logError('Error loading user info', error);
          if (displayErrors) {
            this.displayGeneralError();
          }
        },
      );
  }

  /**
   * Stores url path which user want's to visit before he is redirected to IdentityServer login page.
   * After he is succesfully logged in, then we will use this value and redirect user there
   */
  private get redirectPathStorageKey() {
    return this.oidcClientId + '_redirect';
  }

  private displayGeneralError(
    messageTranslationKey = 'general.error_code.error',
  ): void {
    const message = this.translateService.instant(messageTranslationKey);
    if (this.snackbar) {
      this.snackbar.queue(message, {
        type: DsSnackbarType.Error,
        duration: 20000,
      });
    } else {
      this.messageService.sendDetailMessage({
        severity: MessageSeverityType.error,
        message,
        targets: MessageTargetType.toast,
      });
    }
  }

  private logDebug(message: string) {
    this.messageService.sendDetailMessage({
      severity: MessageSeverityType.debug,
      message,
      source: 'UserService',
      targets: [MessageTargetType.console, MessageTargetType.log],
    });
  }

  private logWarning(message: string, detailObject?: any, detailText?: string) {
    this.messageService.sendDetailMessage({
      severity: MessageSeverityType.warning,
      message,
      detailObject,
      detailText,
      source: 'UserService',
      targets: [MessageTargetType.console, MessageTargetType.log],
    });
  }

  private logError(message: string, detailObject?: any, detailText?: string) {
    this.messageService.sendDetailMessage({
      severity: MessageSeverityType.error,
      message,
      detailObject,
      detailText,
      source: 'UserService',
      targets: [MessageTargetType.console, MessageTargetType.log],
    });
  }
}
