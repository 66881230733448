import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CraneSize, CraneTechnology } from '../../../../classes';

@Component({
  selector: 'bi-crane-technology',
  templateUrl: './crane-technology.component.html',
  styleUrls: ['./crane-technology.component.scss'],
})
export class CraneTechnologyComponent {
  @Input() crane: CraneSize;
  @Input() selected: CraneTechnology;

  @Output()
  selectCraneTechnology: EventEmitter<CraneTechnology> = new EventEmitter();

  public compareItems(i1: any, i2: any) {
    return i1 && i2 && i1.id === i2.id;
  }

  public onSelectChange(): void {
    this.selectCraneTechnology.emit(this.selected);
  }
}
